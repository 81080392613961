import Cookie from 'js-cookie';
import { useState, useCallback } from 'react';

export function useOnceByCookie(cookieKey: string, expires: number|undefined = 365) {
  const [used, setUsed] = useState<boolean>(Cookie.get(cookieKey) !== undefined);

  const use = useCallback(() => {
    Cookie.set(cookieKey, '1', { expires });
    setUsed(true);
  }, [cookieKey, expires, setUsed]);

  return {
    used,
    use,
  }
}
