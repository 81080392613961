import { api } from "@/services";
import { IFlat, FlatManager } from "@features/flats/services/flatManager";
import { appConf } from "@/config";
import { Locale } from "@libs/ML";

export function getFlatsData(locale: Locale | null): Promise<FlatManager> {
  return api.get('/flats/', {}, {
    headers: {
      'Accept-Language': locale,
    },
    transformResponse: (raw) => {
      let data: any[] = [
          ...JSON.parse(raw),
        ],
        flats: IFlat[] = data.map(l => {
          let planCode = l.plan_code.toLowerCase().replace('а', 'a');
          return {
            id: l.id,
            number: l.number,
            planCode,
            isAvailable: !!l.is_for_sale,
            isHidePrice: !!l.is_price_unknown,
            price: l.price,
            priceString: l.price_display,
            floorNumber: l.floor.number,
            floorID: l.floor.id,
            urlPlanSVG: `${appConf.assetsURL}/flats/plans/png/${planCode}.png`,
            urlPlanPDF: `${appConf.assetsURL}/flats/plans/pdf/${planCode}.pdf`,
            urlTour: `${appConf.assetsURL}/panorams/${l.floor.number}/index.html`,
            area: l.area ? parseFloat(l.area) : 0,
            bedrooms: l.bedrooms,
            isLiving: l.purpose === 0,
          }
        });

      return new FlatManager(flats.filter(f => f.floorNumber !== 32), (f) => {
        return f.isLiving;
      });
    },
  });
}
