/**
 * @module @libs/ML/lib/locale.duck
 * @description The locale.duck.
 */
import { produce } from 'immer';
import { Action as ReduxAction, StoreState, ReducerState } from "@libs/Ducks/lib/core/reduxStack";
import { Locale } from "@libs/ML";
import { appConf } from "@/config";
import { SimpleDuck } from "@libs/Ducks/lib/core/duckStack";

/** Duck namespace */
export const NAMESPACE = 'locale';

/**
 * State
 */
export interface State extends ReducerState {
  current: Locale | null;
  prev: Locale | null;
}

const initialState: State = {
  current: appConf.ml.defaultLocale,
  prev: null,
};

/**
 * Action types
 */
export enum ActionType {
  Change = "locale/change",
}

/**
 * Actions
 */
interface ChangeAction extends ReduxAction {
  type: ActionType.Change;
  next: Locale | null;
}

export type Action = ChangeAction;

/**
 * Reducer
 */
function reducer(s = initialState, a: ChangeAction): State {
  switch (a.type) {
    case ActionType.Change:
      return s.current !== a.next ? produce(s, nextState => {
        nextState.prev = nextState.current;
        nextState.current = a.next;
      }) : s;

    default:
      return s;
  }
}

/**
 * Action creators
 */
export function change(next: Locale | null): ChangeAction {
  return {
    type: ActionType.Change,
    next,
  };
}

/**
 * Selectors
 */
export const selectAll = (s: StoreState) => s[NAMESPACE] as State;
export const selectCurrent = (s: StoreState) => selectAll(s).current;
export const selectPrev = (s: StoreState) => selectAll(s).prev;

/**
 * Object to connect
 */
const connectable: SimpleDuck = {
  namespace: NAMESPACE,
  reducer,
  sagas: [],
};
export default connectable;
