import { useMemo } from "react";
import { useLocale } from "@libs/ML/lib/hooks/useLocale";
import { useStringStorage } from "./useStringStorage";

export function useLocaleString(path: string, settings: {[p: string]: any} = {}): string {
  const locale = useLocale();
  const storage = useStringStorage();
  return useMemo(() => {
    return storage.get(path, {
      locale,
      ...settings,
    })
  }, [path, settings, storage, locale]);
}
