/**
 * @description The FloorPlan component.
 */
import React, { useMemo } from "react";
import { FlatManager, IFlat } from "@features/flats/services/flatManager";
import { SharedProps } from "./shared";
import { floorsConf, IFlatConf } from "@features/flats/plans/conf";
import { FloorPlanFlat } from "./FloorPlanFlat";
import "./FloorPlan.scss";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { mergeMods } from "@libs/YandexBEM";

type Props = SharedProps & {
  floorNumber: number;
  flatsManager: FlatManager;
  selected?: number | null;
  hovered?: number | null;
  full?: boolean;
};

export const FloorPlan: React.FC<Props> = function (props) {
  const {
    floorNumber,
    flatsManager,
    interactive = true,
    onClick = () => {},
    onHover = () => {},
    selected = null,
    hovered = null,
    full = false,
  } = props;

  // get floor conf
  const conf = floorsConf[floorNumber];

  // get floor flats data
  const flats = useMemo(() => {
    return flatsManager.getFloorFlats(floorNumber);
  }, [flatsManager, floorNumber]);

  // merge flats conf and data
  const flatsData = useMemo(() => {
    let out: {
      id: number,
      conf: IFlatConf,
      data: IFlat,
    }[] = [];

    conf.flats.forEach(fc => {
      let type = fc.type;
      let flat = flats.find(el => el.planCode === type);
      if (flat) out.push({
        id: flat.id,
        conf: fc,
        data: flat,
      });
    });

    return out;
  }, [flats, conf]);

  return (
    <div className={makeMods('floor-plan', mergeMods({
      full
    }, conf.mods !== undefined ? conf.mods : []))}>
      <div className="floor-plan__cont">
        <svg
          className="floor-plan__svg"
          width={conf.svg.width}
          height={conf.svg.height}
          viewBox={`0 0 ${conf.svg.width} ${conf.svg.height}`}
        >
          <image
            width={conf.bg.width}
            height={conf.bg.height}
            transform={conf.bg.transform}
            xlinkHref={conf.bg.url as string}
          />
          <g className="floor-plan__flats">
            {flatsData.map(f => (
              <FloorPlanFlat
                key={f.id}
                conf={f.conf}
                flat={f.data}
                onClick={onClick}
                onHover={onHover}
                selected={f.id === selected}
                hovered={f.id === hovered}
                interactive={interactive}
              />
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
};
