/**
 * @description The BookingForm component.
 */
import React from "react";
import { BookingForm as Form } from "@features/flats/components/BookingForm";
import "./BookingForm.scss";

type Props = {
  flatID: number | string;
};

export const BookingForm: React.FC<Props> = function (props) {
  return (
    <div className="floor-flat-form">
      <div className="floor-flat-form__cont">

        <div className="floor-flat-form__form">
          <Form flatID={props.flatID} />
        </div>

      </div>
    </div>
  );
};
