/**
 * @description The TextPageLayout component.
 */
import React from "react";
import { Mods } from "@shared/props";
import { PostContent } from "@shared/components/PostContent";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import "./TextPageLayout.scss";
import { SomeSFAnimation } from "@shared/components/SomeSFAnimation";

type Props = {
  mods?: Mods;
};

export const TextPageLayout: React.FC<Props> = function (props) {
  const { children, mods = [] } = props;

  return (
    <div className={makeMods('text-page-layout', mods)}>
      <div className="text-page-layout__cont">
        <div className="text-page-layout__content">
          <SomeSFAnimation gap={50} delay={0.3}>
            <PostContent content={children} />
          </SomeSFAnimation>
        </div>
      </div>
    </div>
  );
};
