/**
 * @description The PageLoadingError component.
 */
import React, {ReactNode } from "react";

import { T } from "@/libs/ML/lib/components/T";
import { ss } from "@shared/stringSource";

type Props = {
  children?: ReactNode;
}

export const PageLoadingError: React.FC<Props> = function (props) {
  let error = props.children || <T p={ss.shared.error.default} />;

  return (
    <div className="page-error">{error}</div>
  );
};
