import { IStringSource, makeMap } from "@libs/ML/lib/StringSource";
import { pmSRC } from "@features/singles/placementMarkersSRC";

const strings = {
  singles: { // WARNING! Do not change the scope name!
    ok: "Ok",
    home: {
      section1: {
        title: `MAYAK<i> — лучшая перспектива Днепра!</i>`,
        row1: `ПРЕМИАЛЬНЫЙ ЖИЛОЙ КОМПЛЕКС С ПАНОРАМНЫМИ ОКНАМИ`,
        row2: `Вид на реку, Центральный мост, Монастырский остров, парк Шевченко и весь город на ладони — Ваше будущее в доме на Сичеславской Набережной будет по-настоящему красивым.`,
      },
      section2: {
        title: `ДОМ В ЦЕНТРЕ СОБЫТИЙ`,
        row1: `Проект ультрасовременной башни с ярким фасадом высотой 33 этаж. Жилые площади начинаются со второго, а фактически — с уровня шестого этажа. В МAYAK видовые характеристики — не опция для избранных, а обязательная составляющая каждой квартиры.`,
      },
      section3: {
        title: `Расположение`,
        row1: `Вы в центре города. <br/>Деловой центр и парк Шевченко, самая длинная набережная в Европе, панорамы церквей и фонтаны, и, конечно, культурные локации, художественная галерея и краеведческий музей`,
      },
      section4: {
        title: `КЛУБНАЯ ИНФРАСТРУКТУРА`,
        row1: `ВСЯ необходимая инфраструктура для семей с детьми, молодых пар и одиночного проживания.`,
        row2: `Только для жителей комплекса будет доступна инфраструктура пятизвездочного отеля:`,
        photos: {
          photo1: `МАССАЖНЫЙ КАБИНЕТ`,
          photo2: `БИБЛИОТЕКА`,
          photo3: `ЧАСТНЫЙ КИНОЗАЛ`,
          photo4: `CОБСТВЕННЫЙ ПАРК`,
          photo5: `CПОРТ ЗАЛ`,
        }
      },
      section5: {
        title: `АРХИТЕКТУРА`,
        row1: `СОВРЕМЕННАЯ И МОДНАЯ АРХИТЕКТУРА, ИННОВАЦИОННЫЕ РЕШЕНИЯ И БЕЗУПРЕЧНЫЙ КОМФОРТ`,
        row2: `Концепцию проекта разработало днепропетровское бюро Daytona. Созданный ими ансамбль с панорамными окнами станет новой архитектурной достопримечательностью и значительно изменит акценты района. Новый жилой комплекс отразил динамично меняющийся облик мегаполиса в своем инновационном фасаде`,
      },
    },
    chars: {
      section1: {
        title: `БАШНЯ`,
        row1: `<span class="alone">В <b>32</b> этажа на <b>4</b>-этажном стилобате</span><span class="alone">Стилобат и 1 этаж здания - коммерческие площади</span>`,
        row2: `<span class="alone"><b>2-32 —</b> жилые этажи</span><span class="alone"><b>31-32 —</b> двухуровневые</span>`,
      },
      section2: {
        title: `ТЕХНОЛОГИЯ СТРОИТЕЛЬСТВА`,
        row1: `монолитно каркасная`,
      },
      section3: {
        title: `ВЫСОТА ПОТОЛКОВ`,
        row1: `<b>3 - 3,15 - 3,3</b> м`,
      },
      section4: {
        title: `СТЕНЫ`,
        row1: `Высокопрочные керамические поризованные блоки из натурального, экологически чистого сырья - глины.`,
      },
      section5: {
        title: `ФАСАД`,
        row1: `вентилируемый, с облицовкой алюминиевыми композитными панелями в сочетании с клинкерной плиткой. Утеплитель — базальтовая вата`,
      },
      section6: {
        title: `Остекление`,
        row1: `тёплая алюминиевая профильная система с вертикальной вентиляционной створкой, двухкамерным энергосберегающим стеклопакетом с использованием безопасного (закаленного) стекла и шумоизоляционного триплекса.`,
      },
      section7: {
        title: `Отопление`,
        row1: `крышная котельная, индивидуальный теплопункт в каждой квартире, встраиваемые (внутрипольные) конвекторы перед окном и настенные радиаторы.`,
      },
      section8: {
        title: `Водоснабжение`,
        row1: `централизованное, с улучшенной <b>4</b>-этапной системой водоподготовки.`,
      },
      section9: {
        title: `Лифты`,
        row1: `<b>4</b> скоростных пассажирских лифта и <b>1</b> строительный.`,
      },
      section10: {
        title: `Кондиционирование`,
        row1: `скрытые ниши на незадымляемой лестничной клетке.`,
      },
      section11: {
        title: `Система контроля доступа`,
        row1: `с применением лицевой биометрии и RFID-парковки.`,
      },
      section12: {
        title: `Паркинг`,
        row1: `<b>4</b> уровня на <b>289</b> машиномест (из них <b>40</b> оборудованы под обслуживание электрокаров).`,
      },
    },
    placement: {
      markers: {
        ...(() => pmSRC.reduce((acc, el, i) => {
          return {
            ...acc,
            [`m${i + 1}`]: el.label,
          }
        }, {}))(),
      },
      types: {
        all: `Все`,
        entertainment: `Развлечения`,
        shops: `Магазины`,
        cafe: `Кафе`,
        sport: `Спорт`,
        health: `Здоровье`,
        school: `Образование`,
        child: `Детские сады`,
      },
      sections: {
        map: {
          textRow1: `Берег Днепра – одна из активно развивающихся территорий города`,
        },
        s1: {
          title: `ОКРУЖЕНИЕ`,
          textRow1: `На берегу реки, в окружении природы — но рядом с центром города. Уникальное расположение MAYAK приводит в баланс жизнь и работу, поездки по делам — и домашний отдых.`,
        },
        s2: {
          textRow1: `Являясь частью территории перспективного развития, MAYAK будет пользоваться самой современной, только что построенной инфраструктурой: новыми школами и детскими садами, спортивными кластерами и модными парками, торговыми и развлекательными центрами.`,
        },
        s3: {
          title: `ВИДЫ`,
          textRow1: `Великолепие Днепра в новой – вашей перспективе.`,
          textRow2: `Рассвет или закат. Ливень или солнце. Парки, что любуются своим отражением в спокойной воде, — или ветреная река в чешуе волн. MAYAK преподносит десятки свежих поводов посмотреть в окно. И делает всё, чтобы каждое утро вы с удовольствием распахивали шторы.`,
        },
        s4: {
          textRow1: `Окна в пол и окна с низкими подоконниками подтвердят афоризм о том, что текущей водой можно любоваться бесконечно.`,
        },
      },
      infra: {
        btnCats: `КАТЕГОРИИ`,
      }
    },
    info: {
      content: `<p>Размещенные на сайте планировки квартир и иных помещений не являются точными копиями проектной документации или документов технической инвентаризации, и выполнены в рекламных целях для наглядного представления характеристик квартир и помещений.</p><p>Элементы благоустройства, материалы и цвета отделки фасада строящегося дома, мест общего пользования, квартир и иных помещений в строящемся доме могут отличаться от приведенных на сайте.</p><p>Для получения актуальной информации об условиях продаж, а также о характеристиках квартир, иных помещений, мест общего пользования Вы можете обратиться в отдел продаж Застройщика.</p>`,
    },
    how: {
      s1: {
        title: `варианты покупки`,
      },
      s2: {
        title: `порядок приобретения`,
      },
      step: `шаг`
    },
    contact: {
      feedback: {
        title: 'Feedback',
      },
      callMe: {
        title: 'Call Me Back'
      }
    },
  }
};

export const stringSource: IStringSource & typeof strings = strings;

export const ss: typeof strings = makeMap(strings) as typeof strings;
