import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { put, takeEvery } from 'redux-saga/effects';

import { Locale } from '@libs/ML';
import { Service as RRHService } from '@libs/RRH';
import { Saga } from '@libs/Ducks/lib/core/reduxStack';
import { Detector } from './Detector';
import { Conf } from '../Conf';
import * as localeDuck from '../locale.duck';

export class ReactRouterDetector implements Detector {
  sagas: Saga[];

  locale: Locale | null;

  constructor(public getCurrent: (p: string) =>  Locale | null) {
    const self = this;
    this.locale = null;

    this.sagas = [
      function* () {
        yield takeEvery(LOCATION_CHANGE, function* (a: LocationChangeAction) {
          const locale = self.current(a.payload.location.pathname);
          self.locale = locale;
          yield put(localeDuck.change(locale));
        });
      },
    ];
  }

  current(pathname: string): Locale | null {
    return this.getCurrent(pathname);
  }
}
