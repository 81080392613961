/**
 * @description The ImagePopup component.
 */
import React, { useMemo } from "react";
import "./ImagePopup.scss";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as CloseIcon } from "./i/close.svg";

type Props = {
  onClose: () => any;
  isOpened?: boolean;
  src: string | null;
  alt?: string;
};

export const ImagePopup: React.FC<Props> = function (props) {
  let { onClose, src, alt = '', isOpened = false } = props;

  const sceneAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
        scale: 0.9
      },
      show: {
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut'
        }
      },
      hide: {
        opacity: 0,
        scale: 0.9,
        transition: {
          duration: 0.3,
          ease: 'easeInOut'
        }
      }
    };
  }, []);

  return (
    <AnimatePresence>
      {src && isOpened && (
        <motion.div
          variants={sceneAnimation}
          initial="initial"
          animate={!isOpened ? 'hide' : 'show'}
          exit="hide"
          className="image-popup"
        >
          <div className="image-popup__cont">

            <div className="image-popup__backdrop" onClick={() => onClose()} />

            <div className="image-popup__body">
              <div className="image-popup__image">
                <img src={src} alt={alt} />
              </div>
              <button className="image-popup__close" onClick={() => onClose()}>
                <CloseIcon />
              </button>
            </div>

          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
