/**
 * @description The BookingForm component.
 */
import React, { useEffect } from "react";
import { ss as sharedSS } from "@shared/stringSource";
import * as yup from "yup";
import { useFormState } from "@shared/hooks/useFormState";
import { useMutation } from "react-query";
import { postBookingOrder } from "@features/singles/api/FeedbackAPI";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";
import { T } from "@libs/ML/lib/components/T";
import { ss } from "@features/flats/stringSource";
import { FormInput } from "@shared/components/FormInput";
import { Submit } from "@shared/components/Submit";
import { Hidden } from "@shared/components/Hidden";
import "./BookingForm.scss";

type Props = {
  flatID: number | string;
};

const validationSchema = yup.object().shape({
  name: yup.string().required(sharedSS.shared.form.validation.required),
  phone: yup.string()
    .required(sharedSS.shared.form.validation.required)
    .min(19, sharedSS.shared.form.validation.phone)
    .max(19, sharedSS.shared.form.validation.phone),
  message: yup.string().required(sharedSS.shared.form.validation.required),
});

interface IFields {
  name: string;
  phone: string;
  message: string;
  flatID: number | string;
}

export const BookingForm: React.FC<Props> = function (props) {
  const { flatID } = props;
  const { sent, setSent } = useFormState();
  const { isError, isSuccess, isLoading, mutate } = useMutation(async (values: IFields) => {
    return await postBookingOrder(values);
  });
  const { handleSubmit, formState: { errors }, register, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      flatID,
      name: '',
      message: '',
      phone: '',
    }
  });

  const animateVars = useListAnimationVars({
    itemDelay: 0.4,
    itemDuration: 0.3,
    itemStagger: 0.1
  });

  useEffect(() => {
    if (isLoading) {
      setSent(false);
    } else if (isSuccess || isError) {
      setSent(true);
      if (!isError) reset();
    }
  }, [isSuccess, isError, isLoading]);

  const submit = (values: IFields) => {
    mutate(values);
  };

  return (
    <div className="booking-form">
      <div className="booking-form__cont">
        <motion.form
          animate="visible"
          initial="hidden"
          variants={animateVars.list}
          onSubmit={handleSubmit(submit)}
          className="booking-form__form"
        >
          <motion.h2 variants={animateVars.item} className="booking-form__title">
            <T p={ss.flats.booking.title} />
          </motion.h2>

          <div className="booking-form__rows">
            <div className="booking-form__row">
              <motion.div variants={animateVars.item} className="booking-form__input">
                <FormInput field="name" disabled={isLoading} error={errors.name?.message} register={register} />
              </motion.div>
            </div>
            <div className="booking-form__row">
              <motion.div variants={animateVars.item} className="booking-form__input">
                <FormInput field="phone" disabled={isLoading} error={errors.phone?.message} register={register} />
              </motion.div>
            </div>
            <div className="booking-form__row">
              <motion.div variants={animateVars.item} className="booking-form__input">
                <FormInput field="message" disabled={isLoading} error={errors.message?.message} register={register} mods={['short']} />
              </motion.div>
            </div>
          </div>

          <motion.div variants={animateVars.item} className="booking-form__submit">
            <Submit disabled={isLoading} submitting={isLoading} />
          </motion.div>

          <Hidden isShow={sent}>
            <div className="booking-form__sent">
              <T p={isError ? sharedSS.shared.form.result.error : sharedSS.shared.form.result.ok} />
            </div>
          </Hidden>
        </motion.form>
      </div>
    </div>
  );
};
