import { useState, useEffect } from 'react';
import { ObjectLoader, Object3D } from 'three';

export function useLoadThreeJSModel(url: string) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<ErrorEvent|Error|null>(null);
  const [model, setModel] = useState<Object3D|null>(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setLoaded(false);
    setModel(null);
    setProgress(0);

    const loader = new ObjectLoader();
    loader.load(url, (obj) => {
      setModel(obj);
      setLoaded(true);
    }, (xhr) => {
      setProgress(xhr.loaded / xhr.total * 100);
    }, (err) => {
      setError(err);
    });
  }, [url]);

  return {
    isLoaded: loaded,
    isLoading: !loaded,
    isError: !!error,
    error,
    progress,
    model,
  }
}
