import { makeFlatPathToValueMap } from "@libs/ML/lib/utils/pathGenerator";

export interface IStringStorageLoadableItems {
  [path: string]: IStringStorageLoadableItems | string;
}

export interface IStringStorageOptions {
  transformItem?(p: string, value: string, settings: {[p: string]: any}): string;
}

export interface IStringStorage {
  options: IStringStorageOptions;

  items: {
    [path: string]: string,
  };

  addItems(items: IStringStorageLoadableItems): void;

  get(path: string): string;
}

export class StringStorage implements IStringStorage {
  public options: IStringStorageOptions = {};

  public items: {[path: string]: string} = {};

  constructor(options: IStringStorageOptions = {}) {
    this.options = options;
  }

  addItems(items: IStringStorageLoadableItems): void {
    const transformed = makeFlatPathToValueMap(items);
    this.items = {
      ...this.items,
      ...transformed,
    };
  }

  get(path: string, settings: {[p: string]: any} = {}): string {
    let found = !!this.items[path];
    return found ?
      this.options.transformItem ? this.options.transformItem(path, this.items[path], settings) : this.items[path]
      : path;
  }
}
