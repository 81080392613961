import { createBrowserHistory, createHashHistory, History } from "history";
import { appConf } from "@/config";
import { RouterType } from "@libs/RRH";

declare let module: {
  hot: {
    data?: {
      history?: History;
    };
    dispose: (fn: (data: any) => any) => any;
  };
};

const history =
  module.hot?.data?.history || appConf.router.routerType === RouterType.Hash
    ? createHashHistory()
    : createBrowserHistory();

if (module.hot) {
  module.hot.dispose((data) => {
    /* eslint no-param-reassign: 0 */
    data.history = history;
  });
}

export default history;
