/**
 * @description The NewsListEl component.
 */
import React from "react";

import { INewsListItem } from "@features/posts/api/NewsAPI";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { Link } from "@libs/ML/lib/components/Link";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from "@shared/stringSource";
import { DateView } from "@shared/components/DateView";
import { makeMods } from "@libs/YandexBEM/lib/mm";

type Props = {} & INewsListItem;

export const NewsListEl: React.FC<Props> = function (props) {
  const { background, backgroundMobile, slug, title, date } = props;
  const isMobile = useIsMobile();

  const bgToUse = isMobile ? backgroundMobile || background : background;

  return (
    <Link to={`/news/${slug}`} className={makeMods('news-list-el', {
      'no-photo': !(bgToUse && bgToUse.src),
    })}>
      <div className="news-list-el__cont">

        <div className="news-list-el__bg">
          <div className="news-list-el__bg-cont">
            {bgToUse && bgToUse.src && (
              <img src={bgToUse.src} alt={bgToUse.alt} className="news-list-el__bg-i"/>
            )}
          </div>
        </div>

        <div className="news-list-el__fg">
          <div className="news-list-el__fg-cont">

            <div className="news-list-el__date"><DateView date={date} /></div>
            <h2 className="news-list-el__title">{title}</h2>
            <div className="news-list-el__more">
              <T p={ss.shared.more} />
            </div>

          </div>
        </div>

      </div>
    </Link>
  );
};
