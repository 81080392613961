import { ss } from "@features/singles/stringSource";
import { pmSRC } from "@features/singles/placementMarkersSRC";
import { ReactComponent as IconTypeEntertainment } from '@shared/images/placement/markers/entertainment.svg';
import { ReactComponent as IconTypeShops } from '@shared/images/placement/markers/shops.svg';
import { ReactComponent as IconTypeCafe } from '@shared/images/placement/markers/cafe.svg';
import { ReactComponent as IconTypeSport } from '@shared/images/placement/markers/sport.svg';
import { ReactComponent as IconTypeHealth } from '@shared/images/placement/markers/health.svg';
import { ReactComponent as IconTypeSchool } from '@shared/images/placement/markers/school.svg';
import { ReactComponent as IconTypeChild } from '@shared/images/placement/markers/children.svg';
import { ReactComponent as IconTypeAll } from '@shared/images/placement/markers/dummy.svg';
import { ReactComponent as IconMain } from '@shared/images/placement/markers/main.svg';

export type PlacementMarkerType = 'all' | 'entertainment' | 'shops' | 'cafe' | 'sport' | 'health' | 'school' | 'child';

interface ITypeConf {
  type: PlacementMarkerType;
  icon: typeof IconTypeEntertainment | undefined;
  label: string;
}

export const typesConf: ITypeConf[] = [
  {
    type: 'all',
    icon: IconTypeAll,
    label: ss.singles.placement.types.all,
  },
  {
    type: 'entertainment',
    icon: IconTypeEntertainment,
    label: ss.singles.placement.types.entertainment,
  },
  {
    type: 'shops',
    icon: IconTypeShops,
    label: ss.singles.placement.types.shops,
  },
  {
    type: 'cafe',
    icon: IconTypeCafe,
    label: ss.singles.placement.types.cafe,
  },
  {
    type: 'sport',
    icon: IconTypeSport,
    label: ss.singles.placement.types.sport,
  },
  {
    type: 'health',
    icon: IconTypeHealth,
    label: ss.singles.placement.types.health,
  },
  {
    type: 'school',
    icon: IconTypeSchool,
    label: ss.singles.placement.types.school,
  },
  {
    type: 'child',
    icon: IconTypeChild,
    label: ss.singles.placement.types.child,
  },
];

export interface IPlacementMarker {
  id: string;
  label: string;
  type: PlacementMarkerType;
  point: {
    lat: number;
    lng: number;
  };
  icon: typeof IconTypeEntertainment | undefined;
  isMain?: boolean;
}

export const placementMarkers: IPlacementMarker[] = [
  ...pmSRC.map((el, i) => ({
    id: el.id,
    type: el.type as PlacementMarkerType,
    point: {
      lat: el.coords[0],
      lng: el.coords[1],
    },
    label: (ss.singles.placement.markers as {[p: string]: string})[`m${i+1}`],
    icon: (typesConf.find(tc => el.type === tc.type) as ITypeConf).icon,
  })),
  {
    id: 'main',
    type: 'all',
    point: {
      lat: 48.46541189686506,
      lng: 35.06522922584944,
    },
    label: `MAYAK`,
    icon: IconMain,
    isMain: true,
  }
];
