/**
 * @description The Table component.
 */
import React from "react";
import { FlatTableSortColumn, IFlat } from "@features/flats/services/flatManager";
import { SearchState } from "@features/flats/hooks/useSearchState";
import { FlatAttr } from "@features/flats/components/Search/FlatAttr";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/features/flats/stringSource';
import { ss as sharedSS } from '@shared/stringSource';
import "./Table.scss";
import { ReactComponent as ArrowIcon } from "./i/arrow.svg";

type Props = {
  flats: IFlat[],
  orderBy: SearchState['orderBy'],
  orderDirection: SearchState['orderDirection'],
  setOrder: SearchState['setOrder'],
  isMobile: boolean,
  onHover?: (flat: number|null) => any,
  onGoToFlat: (flat: IFlat) => any,
};

export const Table: React.FC<Props> = function (props) {
  const { flats, orderBy, orderDirection, setOrder, isMobile, onHover = () => {}, onGoToFlat } = props;

  const makePropsForTH = (column: FlatTableSortColumn, classes: string[] = []) => {
    return {
      className: `${orderBy === column ? 'order' : ''} ${orderDirection === -1 ? 'reverse' : ''} ${classes.join(' ')}`.trim(),
      onClick: () => setOrder(column),
    }
  };

  return (
    <div className="search-table">
      <div className="search-table__cont">
        <table className="search-table__table">
          <thead className="search-table__thead">
            {!isMobile ? (
              <tr>
                <th {...makePropsForTH('planCode')}><T p={ss.flats.search.table.type} /><ArrowIcon /></th>
                <th {...makePropsForTH('floor')}><T p={ss.flats.search.table.floor} /><ArrowIcon /></th>
                <th {...makePropsForTH('number')}><T p={ss.flats.search.table.number} /><ArrowIcon /></th>
                <th {...makePropsForTH('bedrooms')}><T p={ss.flats.search.table.bedrooms} /><ArrowIcon /></th>
                <th {...makePropsForTH('area')}><T p={ss.flats.search.table.area} />, <T className="smaller" p={sharedSS.shared.area} /><ArrowIcon /></th>
                <th {...makePropsForTH('price')}><T p={ss.flats.search.table.price} />, <T className="smaller" p={sharedSS.shared.currency.uah} /><ArrowIcon /></th>
              </tr>
            ) : (
              <tr>
                <th {...makePropsForTH('bedrooms')}><T p={ss.flats.search.table.bedroomsShort} /></th>
                <th {...makePropsForTH('planCode')}><T p={ss.flats.search.table.type} /></th>
                <th {...makePropsForTH('floor')}><T p={ss.flats.search.table.floor} /></th>
                <th {...makePropsForTH('area', ['ta-right'])}><T p={ss.flats.search.table.areaShort} />, <T className="smaller" p={sharedSS.shared.area} /></th>
                <th {...makePropsForTH('price', ['ta-right'])}><T p={ss.flats.search.table.price} />, <T className="smaller" p={sharedSS.shared.currency.uah} /></th>
              </tr>
            )}
          </thead>
          <tbody className="search-table__tbody">
            {flats.map(f => !isMobile ? (
              <tr
                onMouseOver={() => onHover(f.id)}
                onMouseOut={() => onHover(null)}
                onClick={() => onGoToFlat(f)}
                key={f.id}>
                <td><FlatAttr flat={f} type="planCode" /></td>
                <td><FlatAttr flat={f} type="floorNumber" /></td>
                <td><FlatAttr flat={f} type="number" /></td>
                <td><FlatAttr flat={f} type="bedrooms" /></td>
                <td><FlatAttr flat={f} type="area" /></td>
                <td><FlatAttr flat={f} type="price" /></td>
              </tr>
            ) : (
              <tr
                onClick={() => onGoToFlat(f)}
                key={f.id}>
                <td><FlatAttr flat={f} type="bedrooms" /></td>
                <td><FlatAttr flat={f} type="planCode" /></td>
                <td><FlatAttr flat={f} type="floorNumber" /></td>
                <td className="ta-right"><FlatAttr flat={f} type="area" /></td>
                <td className="ta-right"><FlatAttr flat={f} type="price" /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
