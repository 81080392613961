/**
 * @description The DummyPage component.
 */
import React, { ReactElement } from "react";
import { useQueryML } from "@shared/hooks/useQueryML";
import { getItems, ITeamItem } from "@features/posts/api/TeamAPI";
import { T } from "@libs/ML/lib/components/T";
import { ABOUT_SECTION, TEAM } from "@/menuConfig";
import { PageLayout } from "@shared/components/PageLayout";
import { TeamList } from "@features/posts/components/TeamList";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgTeam } from "@shared/images/bg/exports";

type Props = {}

export const Page = function (props: Props): ReactElement {
  const { isLoading, error, data } = useQueryML<ITeamItem[]>("teamList", getItems);

  const plProps = {
    title: <T p={TEAM.labelPath} />,
    isLoading,
    error: error?.message,
    breadcrumbs: ABOUT_SECTION,
    bg: <BackgroundSet conf={bgTeam} />
  };

  return (
    <PageLayout {...plProps}>
      <TeamList
        items={data || []}
      />
    </PageLayout>
  );
};
