import { ModsObject } from './modsFromObject';
import { ModsArray } from './modsFromArray';

/**
 * Merge mods
 * @param a
 * @param b
 */
export function mergeMods(a: ModsArray | ModsObject, b: ModsArray | ModsObject): ModsArray | ModsObject {
  if (a instanceof Array) {
    if (b instanceof Array) return [...a, ...b];
    return [...a, ...Object.keys((b as unknown) as ModsObject).filter((m) => b[m])];
  }
  if (b instanceof Array) {
    const out = { ...a };
    b.forEach((m) => (out[m] = true));
    return out;
  }
  return { ...a, ...b };
}

export default mergeMods;
