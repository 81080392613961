/**
 * @description The ConstructionListSection component.
 */
import React from "react";
import { IConstructionSectionDate } from "@features/posts/hooks/useConstructionList";
import { IConstructionListItem } from "@features/posts/api/ConstructionAPI";
import { ConstructionListEl } from "./ConstructionListEl";

type Props = {
  date: IConstructionSectionDate,
  items: IConstructionListItem[]
};

export const ConstructionListSection: React.FC<Props> = function ({ date, items }) {
  return (
    <div className="construction-list-section">
      <div className="construction-list-section__cont">

        <div className="construction-list-section__date">
          <div className="construction-list-section__date-row">{date.month.name}</div>
          <div className="construction-list-section__date-row">{date.year}</div>
          <div className="construction-list-section__date-line"/>
        </div>

        <div className="construction-list-section__items">
          {items.map(item => (
            <div key={item.id} className="construction-list-section__item">
              <ConstructionListEl {...item} />
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};
