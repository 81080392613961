/**
 * Feature ducks
 */

import { SimpleDuck } from "@libs/Ducks/lib/core/duckStack";

/**
 * Add ducks to array here
 */
export const ducks: SimpleDuck[] = [];
