import _store from "@/services/store";
import _history from "@/services/history";
import _stringStorage from "@/services/stringStorage";
import _api from "@/services/api";
import _queryClient from "@/services/queryClient";

export const store = _store;
export const stringStorage = _stringStorage;
export const history = _history;
export const api = _api;
export const queryClient = _queryClient;

const services = {
  history,
  store,
  stringStorage,
  api,
  queryClient,
};

export default services;
