export interface IFlat {
  id: number;
  number: string;
  planCode: string;
  isAvailable: boolean;
  isHidePrice: boolean;
  price: number;
  priceString: string;
  floorNumber: number;
  floorID: number;
  urlPlanSVG?: string;
  urlPlanPDF?: string;
  urlTour?: string;
  area: number;
  bedrooms: number;
  isLiving: boolean;
  [key: string]: any;
}

export interface IFloor {
  id: number;
  number: number;
}

export interface IExistMap {
  [floorN: number]: {
    [flatID: number]: number,
  }
}

export type FlatTableSortColumn = 'planCode' | 'floor' | 'number' | 'bedrooms' | 'area' | 'price';

export class FlatTable {
  constructor(public flats: IFlat[]) {}

  filterByBedrooms(bedrooms: number) {
    return new FlatTable(this.flats.filter(f => f.bedrooms === bedrooms));
  }

  filterByFloor(from: number, to: number) {
    return new FlatTable(this.flats.filter(f => f.floorNumber >= from && f.floorNumber <= to));
  }

  filterByArea(from: number, to: number) {
    return new FlatTable(this.flats.filter(f => f.area >= from && f.area <= to));
  }

  compareForSort(a: IFlat, b: IFlat, by?: FlatTableSortColumn) {
    switch (by) {
      case "area":
        return a.area - b.area;
      case "bedrooms":
        return a.bedrooms - b.bedrooms;
      case "number":
        return (a.number as any) - (b.number as any);
      case "floor":
        return a.floorNumber - b.floorNumber;
      case "price":
        return (a.isHidePrice ? 0 : a.price) - (b.isHidePrice ? 0 : b.price);
      case "planCode":
        return (a.planCode as any) - (b.planCode as any);
      default:
        return a.id - b.id;
    }
  }

  sort(by?: FlatTableSortColumn, reverse?: boolean) {
    let flats = this.flats.sort((a, b) => {
      return this.compareForSort(a, b, by);
    });
    if (reverse) flats = flats.reverse();
    return new FlatTable(flats);
  }

  sortByIn(toSortBy: FlatTableSortColumn, toSortIn: FlatTableSortColumn, reverse?: boolean) {
    let flats = this.flats.sort((a, b) => {
      let v: string = toSortIn;
      if (v === 'floor') v = 'floorNumber';
      if (a[v] === b[v]) {
        return this.compareForSort(a, b, toSortBy) * (reverse ? -1 : 1);
      }
      return 0;
    });
    return new FlatTable(flats);
  }

  result() {
    return this.flats;
  }
}

export class FlatManager {
  public flats: IFlat[];
  public floors: IFloor[];
  public existMap: IExistMap;
  public searchFilter: (f: IFlat) => boolean;

  constructor(flats: IFlat[], searchFilter: (f: IFlat) => boolean = () => true) {
    this.flats = flats;
    this.floors = [];
    this.existMap = {};
    this.searchFilter = searchFilter;
    this.reload(this.flats);
  }

  reload(flats: IFlat[]) {
    this.flats = flats;
    this.floors = this.getFloors(flats);
    this.existMap = this.getExistMap(flats);
  }

  getAvailableFlats() {
    return this.flats.filter(f => f.isAvailable);
  }

  getAvailableFloors() {
    return this.getFloors(this.getAvailableFlats());
  }

  getFloors(flats: IFlat[]): IFloor[] {
    let floors: IFloor[] = [],
      alreadyAddedFloors: {[key: number]: number} = {};

    flats.forEach(flat => {
      if (alreadyAddedFloors[flat.floorID] === undefined) {
        floors.push({
          id: flat.floorID,
          number: flat.floorNumber,
        });
        alreadyAddedFloors[flat.floorID] = floors.length - 1;
      }
    });

    floors.push({ id: 1, number: 1 });
    floors.push({ id: -1, number: -1 });
    floors.push({ id: -2, number: -2 });
    floors.push({ id: -3, number: -3 });
    floors.push({ id: -4, number: -4 });

    return floors.sort((a, b) => a.number - b.number);
  }

  getExistMap(flats: IFlat[]): IExistMap {
    let out: IExistMap = {};
    flats.forEach((flat, flatIndex) => {
      if (out[flat.floorNumber] === undefined) {
        out[flat.floorNumber] = {};
      }
      out[flat.floorNumber][flat.id] = flatIndex;
    });
    return out;
  }

  getFloor(floorNumber: number): IFloor | null {
    return this.floors.find(f => f.number === floorNumber) || null;
  }

  getFloorFlats(floorNumber: number): IFlat[] {
    let out: IFlat[] = [];
    if (this.existMap[floorNumber] !== undefined) {
      let flatMap = this.existMap[floorNumber];
      Object.keys(flatMap).forEach((k: string) => out.push(this.flats[flatMap[parseInt(k)]]));
    }
    return out;
  }

  getFlat(floorNumber: number, flatID: number): IFlat | null {
    if (this.existMap[floorNumber] !== undefined) {
      let index = this.existMap[floorNumber][flatID];
      if (index !== undefined) {
        return this.flats[index];
      }
    }
    return null;
  }

  getFlatByPlanCode(floorNumber: number, planCode: string): IFlat | null {
    if (this.existMap[floorNumber] !== undefined) {
      return this.getFloorFlats(floorNumber).find(flat => {
        return flat.planCode.toLowerCase().trim() === planCode.toLowerCase().trim()
      }) || null;
    }
    return null;
  }

  getBedroomsOptions(flats = this.getAvailableFlats().filter(this.searchFilter)): number[] {
    let bedrooms: number[] = [];
    flats.forEach(f => {
      if (bedrooms.indexOf(f.bedrooms) < 0) {
        bedrooms.push(f.bedrooms);
      }
    });
    return bedrooms.sort((a, b) => a - b);
  }

  getFloorsBorders(flats = this.getAvailableFlats().filter(this.searchFilter)): [number, number] {
    let floors = Object.keys(this.getExistMap(flats)).map(k => parseInt(k)).sort((a, b) => a - b);
    return [floors[0], floors[floors.length - 1]];
  }

  getAreaBorders(flats = this.getAvailableFlats().filter(this.searchFilter)): [number, number] {
    let min: number | null = null,
      max = 0;
    flats.forEach(flat => {
      if (min === null) {
        min = flat.area;
      } else {
        min = Math.min(min, flat.area);
      }
      max = Math.max(max, flat.area);
    });
    return [Math.floor(min|| 0), Math.ceil(max)];
  }

  table(flats = this.getAvailableFlats().filter(this.searchFilter)) {
    return new FlatTable(flats);
  }

  isFloorAvailable(floorNumber: number) {
    const ignoreAvailableFloors: {[k: string]: boolean} = {
      1: true,
      '-1': true,
      '-2': true,
      '-3': true,
      '-4': true,
    };
    if (ignoreAvailableFloors[floorNumber]) return true;
    if (!this.existMap[floorNumber]) return false;
    return this.getAvailableFlats().filter(f => f.floorNumber === floorNumber).length > 0;
  }

  getFlatByID(flatID: number): IFlat | undefined {
    return this.flats.find(f => f.id === flatID);
  }
}
