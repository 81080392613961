/**
 * @description The Home component.
 */
import React, { useEffect, useState } from "react";
import { HomeHero } from "@features/singles/components/HomeHero";
import "./Home.scss";
import { bgHome } from "@shared/images/bg/exports";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { TextSlideInView } from "@shared/components/TextSlideInView";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@features/singles/stringSource';
import { HomeSlider } from "@features/singles/components/HomeSlider";
import { sectionsImages } from "@shared/images/home/sections/exports";
import { ParallaxedImage } from "@shared/components/ParallaxedImage";
import { HomeImage } from "@features/singles/components/Home/HomeImage";
import { SomeSFInView } from "@shared/components/SomeSFInView";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { emitRecheckScrollState } from "@shared/hooks/useBodyScroll";
import { emitLoadingDone } from "@shared/events/loading";
import { useIsSafari } from "@shared/hooks/useIsSafari";
import { HomeHeroSVG } from "@features/singles/components/HomeHero/HomeHeroSVG";

type Props = {};

export const Home: React.FC<Props> = function (props) {
  const [loaded, setLoaded] = useState(false);
  const isMobile = useIsMobile();
  const isSafari = useIsSafari();

  useEffect(() => {
    emitLoadingDone();
  }, []);

  const onAnimationDone = () => {
    setLoaded(true);
    emitRecheckScrollState();
  };

  return (
    <div className="home">
      <div style={isSafari ? {position: "absolute"} : {}} className="home__hero">
        {/*<HomeHero done={onAnimationDone}/>*/}
        <HomeHeroSVG done={onAnimationDone} />
      </div>

      <div className="home__cont">
        <div className={makeMods('home__wrap', { loaded })}>
          <div className="home__bg">
            <BackgroundSet
              conf={bgHome}
              mods={['home']}
            />
          </div>
          <div className="home__fg">

            <div className="home__section home__section--1">
              <div className="home__section-text text">
                <h2 className="home__title title">
                  <TextSlideInView><T html p={ss.singles.home.section1.title}/></TextSlideInView>
                </h2>
                <div className="home__text-row row">
                  <TextSlideInView dir="right"><T html p={ss.singles.home.section1.row1}/></TextSlideInView>
                </div>
                {/*<div className="home__text-row row">*/}
                {/*  <TextSlideInView dir="right"><T html p={ss.singles.home.section1.row2}/></TextSlideInView>*/}
                {/*</div>*/}
              </div>
              <div className="home__section-media media">
                <HomeSlider randomGapFrom={0} randomGapTo={0} slides={sectionsImages.section1.map(({d, m}) => ({ desktop: d, mobile: m }))} />
              </div>
            </div>

            <div className="home__section home__section--2">
              <div className="home__section-text text">
                <h2 className="home__title title">
                  <TextSlideInView><T html p={ss.singles.home.section2.title}/></TextSlideInView>
                </h2>
                <div className="home__text-row row">
                  <TextSlideInView dir="right"><T html p={ss.singles.home.section2.row1}/></TextSlideInView>
                </div>
              </div>
              <div className="home__section-media media">
                <SomeSFInView>
                  <ParallaxedImage
                    src={sectionsImages.section2[0].d}
                    mobileSrc={sectionsImages.section2[0].d}
                    propWidth={isMobile ? 320 : 688}
                    propHeight={isMobile ? 445 : 995}
                    gap={isMobile ? 120 : 180}
                  />
                </SomeSFInView>
              </div>
            </div>

            <div className="home__section home__section--3">
              <div className="home__section-text text">
                <h2 className="home__title title">
                  <TextSlideInView><T html p={ss.singles.home.section3.title}/></TextSlideInView>
                </h2>
                <div className="home__text-row row">
                  <TextSlideInView dir="right"><T html p={ss.singles.home.section3.row1}/></TextSlideInView>
                </div>
              </div>
              <div className="home__section-media media">
                <HomeSlider randomGapFrom={0} randomGapTo={0} slides={sectionsImages.section3.map(({d, m}) => ({ desktop: d, mobile: m }))} />
              </div>
            </div>

            <div className="home__section home__section--4">
              <div className="home__section-text text">
                <h2 className="home__title title">
                  <TextSlideInView><T html p={ss.singles.home.section4.title}/></TextSlideInView>
                </h2>
                <div className="home__text-row row">
                  <TextSlideInView dir="right"><T html p={ss.singles.home.section4.row1}/></TextSlideInView>
                </div>
                <div className="home__text-row row">
                  <TextSlideInView dir="right"><T html p={ss.singles.home.section4.row2}/></TextSlideInView>
                </div>
              </div>
              <div className="home__section-media media">
                <div className="home__masonry">
                  <div className="home__mr-row home__mr-row--with-big">
                    <div className="home__mr-cell home__mr-cell--big">
                      <SomeSFInView full>
                        <HomeImage gap={isMobile ? 180 : 230} titleSrc={ss.singles.home.section4.photos.photo1} desktop={sectionsImages.section4[0].d} mobile={sectionsImages.section4[0].m} />
                      </SomeSFInView>
                    </div>
                    <div className="home__mr-cell">
                      <SomeSFInView full>
                        <HomeImage gap={isMobile ? 180 : 70} titleSrc={ss.singles.home.section4.photos.photo2} desktop={sectionsImages.section4[1].d} mobile={sectionsImages.section4[1].m} />
                      </SomeSFInView>
                    </div>
                    <div className="home__mr-cell">
                      <SomeSFInView full>
                        <HomeImage gap={isMobile ? 180 : 130} titleSrc={ss.singles.home.section4.photos.photo3} desktop={sectionsImages.section4[2].d} mobile={sectionsImages.section4[2].m} />
                      </SomeSFInView>
                    </div>
                  </div>
                  <div className="home__mr-row home__mr-row--last">
                    <div className="home__mr-cell">
                      <SomeSFInView full>
                        <HomeImage gap={isMobile ? 180 : 130} titleSrc={ss.singles.home.section4.photos.photo4} desktop={sectionsImages.section4[3].d} mobile={sectionsImages.section4[3].m} />
                      </SomeSFInView>
                    </div>
                    <div className="home__mr-cell">
                      <SomeSFInView full delay={(isMobile ? 0 : 0.2) + 0.7}>
                        <HomeImage gap={0} titleSrc={ss.singles.home.section4.photos.photo5} desktop={sectionsImages.section4[4].d} mobile={sectionsImages.section4[4].m} />
                      </SomeSFInView>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="home__section home__section--5">
              <div className="home__section-text text">
                <h2 className="home__title title">
                  <TextSlideInView><T html p={ss.singles.home.section5.title}/></TextSlideInView>
                </h2>
                <div className="home__text-row row">
                  <TextSlideInView dir="right"><T html p={ss.singles.home.section5.row1}/></TextSlideInView>
                </div>
                <div className="home__text-row row">
                  <TextSlideInView dir="right"><T html p={ss.singles.home.section5.row2}/></TextSlideInView>
                </div>
              </div>
              <div className="home__section-media media">
                <HomeSlider randomGapFrom={0} randomGapTo={0} slides={sectionsImages.section5.map(({d, m}) => ({ desktop: d, mobile: m }))} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
