/**
 * @description The How component.
 */
import React from "react";
import { IHTBResponse } from "@features/singles/api/HowToBuyAPI";
import { T } from "@/libs/ML/lib/components/T";
import { motion } from "framer-motion";
import { SomeSFInView } from "@shared/components/SomeSFInView";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";
import { AnimateInView } from "@shared/components/AnimateInView";
import { ss } from '@/features/singles/stringSource';
import { ReactComponent as DownloadIcon } from "./i/download.svg";
import "./How.scss";

type Props = {
  data: IHTBResponse;
};

export const How: React.FC<Props> = function (props) {
  const { data: { options, steps } } = props;
  const animationVariants = useListAnimationVars({
    itemDelay: 0.4,
    itemStagger: 0.15,
  });
  const animationVariantsSteps = useListAnimationVars({
    itemDelay: 0.7,
    itemStagger: 0.15,
  });

  return (
    <div className="how">
      <div className="how__cont">

        <AnimateInView>
          {({ controls, ref }) => (
            <motion.div initial="hidden" animate={controls} variants={animationVariants.list} ref={ref} className="how__section how__section--options">
              <motion.h2 variants={animationVariants.item} className="how__title">
                <T p={ss.singles.how.s1.title} />
              </motion.h2>
              <div className="how__content">
                <div className="how__options">
                  {options.map((el, i) => (
                    <motion.div variants={animationVariants.item} key={i} className="how-option">
                      <div className="how-option__cont">
                        <h3 className="how-option__title">{el.title} {el.subtitle && <span className="sub">{el.subtitle}</span>}</h3>
                        <div className="how-option__content" dangerouslySetInnerHTML={{__html: el.content}} />
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimateInView>

        <AnimateInView>
          {({ controls, ref }) => (
            <motion.div initial="hidden" animate={controls} variants={animationVariantsSteps.list} ref={ref} className="how__section how__section--steps">
              <motion.h2 variants={animationVariantsSteps.item} className="how__title">
                <T p={ss.singles.how.s2.title} />
              </motion.h2>
              <div className="how__content">
                <div className="how__steps">
                  {steps.map((step, i) => (
                    <motion.div variants={animationVariantsSteps.item} key={i} className="how-step">
                      <div className="how-step__cont">
                        <div className="how-step__text">
                          <div className="how-step__step"><T p={ss.singles.how.step} /><span className="big">{i + 1}</span></div>
                          <h3 className="how-step__title">{step.title}</h3>
                          {step.attachment && (
                            <a href={step.attachment} target="_blank" className="how-step__link">
                              <div className="how-step__load-i">
                                <DownloadIcon />
                              </div>
                              <div className="how-step__load-text">{step.subtitle}</div>
                            </a>
                          )}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimateInView>

      </div>
    </div>
  );
};
