/**
 * @description The TeamList component.
 */
import React, { useMemo } from "react";

import { ITeamItem } from "@features/posts/api/TeamAPI";
import { TeamListEl } from "@features/posts/components/TeamList/TeamListEl";
import "./TeamList.scss";
import { motion } from "framer-motion";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";

type Props = {
  items: ITeamItem[],
};

export const TeamList: React.FC<Props> = function (props) {
  const variants = useListAnimationVars();

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants.list}
      className="team-list">
      <div className="team-list__cont">

        <div className="team-list__items">
          {props.items.map(item => (
            <motion.div variants={variants.item} key={item.id} className="team-list__el">
              <TeamListEl {...item} />
            </motion.div>
          ))}
        </div>

      </div>
    </motion.div>
  );
};
