/**
 * @description The GoogleMapMarker component.
 */
import React, { FunctionComponent, useMemo, useState } from "react";
import { InfoWindow } from "@react-google-maps/api";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { T } from "@/libs/ML/lib/components/T";

type Props = {
  icon?: FunctionComponent;
  label: string;
  lat: number;
  lng: number;
  isMain?: boolean;
};

export const GoogleMapMarker: React.FC<Props> = function (props) {
  const { lat, lng, icon: Icon, label, isMain } = props;
  const [isHover, setIsHover] = useState(false);
  const iwProps = useMemo(() => {
    return {
      position: {
        lat,
        lng,
      },
    }
  }, [lat, lng]);

  return (
    <InfoWindow {...iwProps} zIndex={isHover ? 1000 : undefined}>
      <div className={makeMods('gm-marker', {
        hover: isHover,
        main: !!isMain,
      })} onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)}>
        <div className="gm-marker__cont">
          <div className="gm-marker__text">
            <T p={label}/>
          </div>
          <div className="gm-marker__icon">
            {Icon && <Icon />}
          </div>
        </div>
      </div>
    </InfoWindow>
  );
};
