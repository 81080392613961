/**
 * @description The MobileFlat component.
 */
import React, { useMemo, useState } from "react";
import { FlatManager, IFlat } from "@features/flats/services/flatManager";
import { Compass } from "@features/flats/components/Compass";
import { ReactComponent as WaterIcon } from "@features/flats/components/FloorFlat/i/water.svg";
import { ReactComponent as TreesIcon } from "@features/flats/components/FloorFlat/i/trees.svg";
import { FloorPlan } from "@features/flats/components/FloorPlan";
import { motion, AnimatePresence } from "framer-motion";
import { BookingForm } from "@features/flats/components/BookingForm";
import { makeFloorFlatLink } from "@features/flats/utils/makeFloorFlatLink";
import { useLocation } from "react-router";
import { T } from "@libs/ML/lib/components/T";
import { ss } from "@features/flats/stringSource";
import { FlatAttr } from "@features/flats/components/Search/FlatAttr";
import "./MobileFlat.scss";
import { Close } from "@shared/components/Close";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";

type Props = {
  floorNumber: number;
  flatID: number;
  flatsManager: FlatManager;
};

export const MobileFlat: React.FC<Props> = function (props) {
  const { floorNumber, flatID, flatsManager } = props;
  const location = useLocation();
  const [formOpened, setFormOpened] = useState(false);
  const contentAnimation = useListAnimationVars({
    itemStagger: 0.1,
    itemDelay: 0.3,
  });

  // get flat data
  const flat = useMemo(() => {
    return flatsManager.getFlatByID(flatID) as IFlat;
  }, [flatID, flatsManager]);

  // get back button props
  const backProps = useMemo(() => {
    if (floorNumber !== null && flatID !== null) {
      if ((location.state as any)?.fromSearch) {
        return  {
          to: '/apartments/search'
        }
      }
      return {
        to: makeFloorFlatLink(floorNumber),
      }
    }
    return {
      to: makeFloorFlatLink(),
    }
  }, [floorNumber, flatID, location.state]);

  const controlsAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
        transition: {
          duration: 0.3,
          delay: 1.1,
          ease: 'easeInOut',
        }
      }
    };
  }, []);

  const formAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
        transition: {
          duration: 0.3,
          ease: 'easeInOut',
        }
      },
      exit: {
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: 'easeInOut',
        }
      }
    };
  }, []);

  const renderDataRow = (label: string, value: any) => {
    return (
      <motion.div variants={contentAnimation.item} className="flat-mobile-view__data-row">
        <div className="flat-mobile-view__data-label">
          <T p={label} />
        </div>
        <div className="flat-mobile-view__data-value">
          {value}
        </div>
      </motion.div>
    );
  };

  return (
    <div className="flat-mobile-view">
      <div className="flat-mobile-view__cont">

        <motion.div
          variants={contentAnimation.list}
          initial="hidden"
          animate="visible"
          className="flat-mobile-view__content"
        >
          <motion.div variants={contentAnimation.item} className="flat-mobile-view__plan">
            <img src={flat?.urlPlanSVG} alt="" className="flat-mobile-view__plan-img"/>
          </motion.div>
          <div className="flat-mobile-view__data">
            {renderDataRow(ss.flats.flat.infoPanel.floor, <FlatAttr flat={flat} type="floorNumber" />)}
            {renderDataRow(ss.flats.flat.infoPanel.number, <FlatAttr flat={flat} type="number" />)}
            {renderDataRow(ss.flats.flat.infoPanel.type, <FlatAttr flat={flat} type="planCode" />)}
            {renderDataRow(ss.flats.flat.infoPanel.bedrooms, <FlatAttr flat={flat} type="bedrooms" />)}
            {renderDataRow(ss.flats.flat.infoPanel.area, <FlatAttr flat={flat} type="area" />)}
            {!flat.isHidePrice && renderDataRow(ss.flats.flat.infoPanel.price, <FlatAttr flat={flat} type="price" />)}
          </div>
          <motion.div variants={contentAnimation.item} className="flat-mobile-view__floor">
            <div className="flat-mobile-view__i-water">
              <WaterIcon />
            </div>

            <div className="flat-mobile-view__floor-plan">
              <FloorPlan
                interactive={false}
                floorNumber={floorNumber}
                flatsManager={flatsManager}
                selected={flatID}
              />

              <div className="flat-mobile-view__compass">
                <Compass />
              </div>
            </div>

            <div className="flat-mobile-view__i-trees">
              <TreesIcon />
            </div>
          </motion.div>
          <motion.div variants={contentAnimation.item} className="flat-mobile-view__notice">
            <div className="flat-mobile-view__notice-cont">
              <T html={true} p={ss.flats.flat.priceCurrencyExchangeNotice} />
            </div>
          </motion.div>
        </motion.div>

        <motion.div
          variants={controlsAnimation}
          initial="initial"
          animate="visible"
          className="flat-mobile-view__back"
        >
          <Close {...backProps} />
        </motion.div>

        <motion.div
          variants={controlsAnimation}
          initial="initial"
          animate="visible"
          className="flat-mobile-view__panel"
        >
          <a href={flat?.urlTour} target="_blank" className="flat-mobile-view__panel-btn">
            <T p={ss.flats.flat.buttons.windows} />
          </a>
          <button onClick={() => setFormOpened(true)} className="flat-mobile-view__panel-btn">
            <T p={ss.flats.flat.buttons.book} />
          </button>
          <a href={flat?.urlPlanPDF} target="_blank"  className="flat-mobile-view__panel-btn">
            <T p={ss.flats.flat.buttons.pdf} />
          </a>
        </motion.div>

        <AnimatePresence>
          {formOpened && (
            <motion.div
              variants={formAnimation}
              initial="initial"
              animate="visible"
              exit="exit"
              className="flat-mobile-view__booking"
            >
              <div className="flat-mobile-view__close-booking">
                <Close onClick={() => setFormOpened(false)} />
              </div>
              <BookingForm flatID={flat.number} />
            </motion.div>
          )}
        </AnimatePresence>

      </div>
    </div>
  );
};
