/**
 * @description The InfoPage component.
 */
import React from "react";
import { T } from "@libs/ML/lib/components/T";
import { INFO, ABOUT_SECTION } from "@/menuConfig";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgInfo } from "@shared/images/bg/exports";
import { PageLayout } from "@shared/components/PageLayout";
import { ss } from '@features/singles/stringSource';
import { useString } from "@libs/ML/lib/hooks/useString";
import { TextPageLayout } from "@shared/components/TextPageLayout";

type Props = {}

export const InfoPage: React.FC<Props> = function (props) {
  const content = useString(ss.singles.info.content);

  const plProps = {
    title: <T p={INFO.labelPath} />,
    breadcrumbs: ABOUT_SECTION,
    bg: <BackgroundSet conf={bgInfo} />
  };

  return (
    <PageLayout {...plProps}>
      <TextPageLayout>{content}</TextPageLayout>
    </PageLayout>
  );
};
