import { QueryKey, useQuery } from 'react-query';
import { AxiosError } from "axios";

import { useLocale } from "@libs/ML/lib/hooks/useLocale";
import { useIsMobile } from "@shared/hooks/useIsMobile";

export interface IUseQueryMLExtraParams {
  isMobile: boolean;
  locale: string | null;
}

export function useQueryML<T, Error = AxiosError>(key: QueryKey, handler: (...args: any) => any) {
  const locale = useLocale();
  const isMobile = useIsMobile();

  const queryKeyParams: IUseQueryMLExtraParams = {
    locale,
    isMobile,
  };
  const usingKey = key instanceof Array ? [...key, queryKeyParams] : [key, queryKeyParams];

  return useQuery<T, Error>(usingKey, handler);
}
