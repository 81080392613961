/**
 * The app ducks are connected here
 */

import { SimpleDuck } from "@libs/Ducks/lib/core/duckStack";
import { ducks as posts } from "@features/posts/ducks";
import { ducks as flats } from "@features/flats/ducks";
import { ducks as singles } from "@features/singles/ducks";
import settings from "@shared/ducks/settings.duck";
import locale from "@libs/ML/lib/locale.duck";
import { ReactRouterDetector } from "@libs/ML/lib/langDetectors/ReactRouterDetector";
import { appConf } from "@/config";
import { getLocaleFromPathname } from "@libs/ML/lib/utils/getLocaleFromPathname";
import { langDetector } from "@/services/langDetector";

// add lang detector
locale.sagas = [...locale.sagas, ...langDetector.sagas];

/**
 * Connect it here
 */
export const ducks: SimpleDuck[] = [
  locale,
  settings,
  ...posts,
  ...flats,
  ...singles,
];
