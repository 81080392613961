/**
 * @description The CharsPage component.
 */
import React from "react";
import { Chars } from "@features/singles/components/Chars";

type Props = {}

export const CharsPage: React.FC<Props> = function (props) {
  return (
    <Chars />
  );
};
