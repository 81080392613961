/**
 * @description The DummyPage component.
 */
import React, { ReactElement } from "react";

import { getItems, IConstructionListItem } from "@features/posts/api/ConstructionAPI";
import { useQueryML } from "@shared/hooks/useQueryML";
import { T } from "@libs/ML/lib/components/T";
import { CONSTRUCTION, EVENTS_SECTION } from "@/menuConfig";
import { PageLayout } from "@shared/components/PageLayout";
import { ConstructionList } from "@features/posts/components/ConstructionList";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgConstruction } from "@shared/images/bg/exports";

type Props = {}

export const Page = function(props: Props): ReactElement {
  const { isLoading, error, data } = useQueryML<IConstructionListItem[]>("constructionList", getItems);

  const plProps = {
    title: <T p={CONSTRUCTION.labelPath} />,
    isLoading,
    error: error?.message,
    breadcrumbs: EVENTS_SECTION,
    bg: <BackgroundSet conf={bgConstruction} />
  };

  return (
    <PageLayout {...plProps}>
      <ConstructionList items={data || []} />
    </PageLayout>
  );
};
