/**
 * @description The DummyPage component.
 */
import React, { ReactElement } from "react";
import { T } from "@libs/ML/lib/components/T";
import { ABOUT_SECTION, GALLERY } from "@/menuConfig";
import { PageLayout } from "@shared/components/PageLayout";
import { GalleryAlbumsList } from "@features/posts/components/GalleryAlbumsList";
import { useGalleryList } from "@features/posts/hooks/useGallery";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgGallery } from "@shared/images/bg/exports";

type Props = {}

export const Page = function (props: Props): ReactElement {
  const { isLoading, error, data } = useGalleryList();

  const plProps = {
    title: <T p={GALLERY.labelPath} />,
    isLoading,
    error: error?.message,
    breadcrumbs: ABOUT_SECTION,
    bg: <BackgroundSet conf={bgGallery} />
  };

  return (
    <PageLayout {...plProps}>
      <GalleryAlbumsList items={data || []} />
    </PageLayout>
  );
};
