import _imgS11Desktop from "./s1/1.jpg";
import _imgS11Mobile from "./s1/1m.jpg";
import _imgS21Desktop from "./s2/1.jpg";
import _imgS22Desktop from "./s2/2.jpg";
import _imgS21Mobile from "./s2/1m.jpg";
import _imgS22Mobile from "./s2/2m.jpg";
import _imgS31Desktop from "./s3/1.jpg";
import _imgS32Desktop from "./s3/2.jpg";
import _imgS33Desktop from "./s3/3.jpg";
import _imgS31Mobile from "./s3/1m.jpg";
import _imgS41Desktop from "./s4/1.jpg";
import _imgS42Desktop from "./s4/2.jpg";
import _imgS41Mobile from "./s4/1m.jpg";
import _imgS42Mobile from "./s4/2m.jpg";
import _imgS43Mobile from "./s4/3m.jpg";


export const imgS11Desktop = _imgS11Desktop;
export const imgS11Mobile = _imgS11Mobile;
export const imgS1 = { desktop: [_imgS11Desktop], mobile: [_imgS11Mobile], };

export const imgS21Desktop = _imgS21Desktop;
export const imgS22Desktop = _imgS22Desktop;
export const imgS21Mobile = _imgS21Mobile;
export const imgS22Mobile = _imgS22Mobile;
export const imgS2 = { desktop: [_imgS21Desktop, _imgS22Desktop], mobile: [_imgS21Mobile, _imgS22Mobile], };

export const imgS31Desktop = _imgS31Desktop;
export const imgS32Desktop = _imgS32Desktop;
export const imgS33Desktop = _imgS33Desktop;
export const imgS31Mobile = _imgS31Mobile;
export const imgS3 = { desktop: [_imgS31Desktop, _imgS32Desktop, _imgS33Desktop], mobile: [_imgS31Mobile], };

export const imgS41Desktop = _imgS41Desktop;
export const imgS42Desktop = _imgS42Desktop;
export const imgS41Mobile = _imgS41Mobile;
export const imgS42Mobile = _imgS42Mobile;
export const imgS43Mobile = _imgS43Mobile;
export const imgS4 = { desktop: [_imgS41Desktop, _imgS42Desktop], mobile: [_imgS41Mobile, _imgS42Mobile, _imgS43Mobile], };


