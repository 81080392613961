/**
 * @description The ConstructionListEl component.
 */
import React from "react";
import { IConstructionListItem } from "@features/posts/api/ConstructionAPI";
import { useIsMobile } from "@shared/hooks/useIsMobile";

import { Link } from "@libs/ML/lib/components/Link";
import { format } from "date-fns";
import { DateView } from "@shared/components/DateView";

type Props = {} & IConstructionListItem;

export const ConstructionListEl: React.FC<Props> = function (props) {
  const isMobile = useIsMobile();

  let {
    slug,
    cover,
    mobileCover,
    date,
  } = props;

  cover = isMobile ? mobileCover ? mobileCover : cover : cover;

  return (
    <Link to={`/construction/${slug}`} className="construction-list-el">
      <div className="construction-list-el__cont">
        <div className="construction-list-el__bg">
          <img src={cover.src} alt={cover.alt} className="construction-list-el__bg-i"/>
        </div>
        <div className="construction-list-el__fg">
          <div className="construction-list-el__date">
            <DateView format="simple" date={date} />
          </div>
        </div>
      </div>
    </Link>
  );
};
