/**
 * @description The FloorPlanLegend component.
 */
import React from "react";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/features/flats/stringSource';
import "./FloorPlanLegend.scss";

type Props = {
  available?: boolean;
  sold?: boolean;
};

export const FloorPlanLegend: React.FC<Props> = function (props) {
  const { available = true, sold = true } = props;

  return (
    <div className="floor-plan-legend">
      <div className="floor-plan-legend__cont">

        {available && (
          <div className="floor-plan-legend__el">
            <div className="floor-plan-legend__el-mark floor-plan-legend__el-mark--available" />
            <div className="floor-plan-legend__el-text">
              <T p={ss.flats.flat.status.available} />
            </div>
          </div>
        )}

        {sold && (
          <div className="floor-plan-legend__el">
            <div className="floor-plan-legend__el-mark" />
            <div className="floor-plan-legend__el-text">
              <T p={ss.flats.flat.status.sold} />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};
