/**
 * @description The DummyPage component.
 */
import React, { ReactElement } from "react";
import { useRouteMatch } from "react-router";

import { useQueryML } from "@shared/hooks/useQueryML";
import { getItem, IConstructionSingleItem } from "@features/posts/api/ConstructionAPI";
import { PageLayout } from "@shared/components/PageLayout";
import { ConstructionItem } from "@features/posts/components/ConstructionItem";
import { EVENTS_SECTION } from "@/menuConfig";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgConstruction } from "@shared/images/bg/exports";

type Props = {}

export const Page = function (props: Props): ReactElement {
  const match = useRouteMatch<{ slug: string | undefined }>();
  const { isLoading, error, data } = useQueryML<IConstructionSingleItem>(["constructionItem", match.params.slug], getItem);

  const plProps = {
    isLoading,
    error: error?.message,
    breadcrumbs: EVENTS_SECTION,
    mods: ['post-content'],
    bg: <BackgroundSet conf={bgConstruction} />
  };

  return (
    <PageLayout {...plProps}>
      <ConstructionItem {...data || {} as IConstructionSingleItem} />
    </PageLayout>
  );
};
