/**
 * @description The ConstructionItem component.
 */
import React from "react";
import { IConstructionSingleItem } from "@features/posts/api/ConstructionAPI";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { PostContent } from "@shared/components/PostContent";
import "./ConstructionItem.scss";
import { Back } from "@shared/components/Back";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";
import { motion } from "framer-motion";

type Props = {} & IConstructionSingleItem;

export const ConstructionItem: React.FC<Props> = function (props) {
  const isMobile = useIsMobile();
  const { photos, mobilePhotos, content } = props;
  const photosToUse = isMobile && mobilePhotos ? mobilePhotos : photos;
  const variants = useListAnimationVars({
    itemDelay: 0.1,
    itemYGap: 2,
  });

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants.list}
      className="construction-el">
      <div className="construction-el__cont">

        {content && (
          <motion.div className="construction-el__text-cont" variants={variants.item}>
            <PostContent content={content} />
          </motion.div>
        )}

        <PostContent content={(
          photosToUse.map(el => (
            <motion.img variants={variants.item} key={el.src} src={el.src} alt={el.alt} className="construction-el__img"/>
          ))
        )}/>

        <Back to="/construction" />

      </div>
    </motion.div>
  );
};
