/**
 * @description The House component.
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHoverState } from "@shared/hooks/useHoverState";
import { useFloorNav } from "@features/flats/hooks/useFloorNav";
import { useGoToWithLocale } from "@libs/ML/lib/hooks/useGoToWithLocale";
import { useFlatsData } from "@features/flats/hooks/useFlatsData";
import { useLoadThreeJSModel } from "@shared/hooks/useLoadThreeJSModel";
import { appConf } from "@/config";
import { HouseScene } from "@features/flats/components/HouseScene";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { makeFloorFlatLink } from "@features/flats/utils/makeFloorFlatLink";
import { FloorNav } from "@features/flats/components/FloorNav";
import "./House.scss";
import { ss } from '@/features/flats/stringSource';
import { T } from "@/libs/ML/lib/components/T";
import { ReactComponent as IconRotate } from "./i/rotate.svg";
import { PageLoader, pageLoaderAnimation } from "@shared/components/PageLoader";
import { motion, AnimatePresence } from "framer-motion";
import { emitLoadingDone } from "@shared/events/loading";
import { useWaitFirstUserAction } from "@shared/hooks/useWaitFirstUserAction";

type Props = {}

export const House: React.FC<Props> = function (props) {
  const { isLoading: isFlatsLoading, error: flatsError, data: flatsManager } = useFlatsData();
  const { isLoaded: isModelLoaded, progress, model, isError: isModelError } = useLoadThreeJSModel(appConf.assetsURL + appConf.assets.houseSceneURL);
  const [isModelInit, setIsModelInit] = useState(false);
  const { latestHover, hover, setHover } = useHoverState<number | null>();
  const nav = useFloorNav(hover || 0, flatsManager);
  const goTo = useGoToWithLocale();
  const isMobile = useIsMobile();
  const isUserDidAction = useWaitFirstUserAction(!!(model && flatsManager && isModelInit));

  // set default hover flat
  useEffect(() => {
    if (flatsManager) {
      let floors = flatsManager.getAvailableFloors();
      if (floors.length) {
        setHover(floors[Math.ceil(floors.length / 2)].number);
      }
    }
  }, [flatsManager]);

  // flags
  const isError = !!flatsError || isModelError;
  const isLoading = !isModelLoaded || isFlatsLoading || !isModelInit;
  const isHasAvailable = nav.availableFloorsNumbers.length > 0;

  // click handler
  const handleClick = useCallback((floorNumber: number | null, custom = false) => {
    if ((!custom ? !isMobile : custom) && floorNumber && nav.availableFloorsNumbers.indexOf(floorNumber as number) > -1) {
      goTo(makeFloorFlatLink(floorNumber))
    }
  }, [setHover, isMobile, hover, nav]);

  // hover handler
  const handleHover = useCallback((floorNumber: number | null) => {
    if (!isMobile && nav.availableFloorsNumbers.indexOf(floorNumber as number) > -1) {
      setHover(floorNumber);
    }
  }, [setHover, isMobile, nav]);

  // get available flats count
  const availableFlats = useMemo(() => {
    if (!flatsManager || !latestHover) return 0;
    return flatsManager.getFloorFlats(latestHover).filter(f => f.isAvailable).length;
  }, [flatsManager, latestHover]);

  // scene animation
  const sceneAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
      },
      show: {
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut'
        }
      }
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      emitLoadingDone();
    }
  }, [isLoading]);

  return (
    <div className="house">
      <div className="house__cont">

        {model && flatsManager && (
          <motion.div
            variants={sceneAnimation}
            initial="initial"
            animate={isLoading ? 'initial' : 'show'}
            className="house__scene"
          >
            <HouseScene
              onClick={handleClick}
              isInit={isModelInit}
              onHover={handleHover}
              hover={latestHover}
              hoverFact={hover}
              onInit={() => setIsModelInit(true)}
              model={model}
              animate={!isUserDidAction}
            />
          </motion.div>
        )}

        {isHasAvailable && (
          <div className="house__nav">
            <div className="house__nav-cont">
              <FloorNav
                mode="button"
                current={nav.current as number}
                next={nav.next as number}
                prev={nav.prev as number}
                onNext={setHover}
                onPrev={setHover}
              />
            </div>

            {!isMobile && (
              <div onClick={() => handleClick(latestHover)} className="house__available">
                {nav.current !== null && nav.current > 1 ? (
                  <T p={ss.flats.house.flatsAvailable} params={{ n: availableFlats }}/>
                ) : (
                  <T p={ss.flats.house.npAvailable} />
                )}
              </div>
            )}
          </div>
        )}

        <div className="house__rotate">
          <IconRotate />
        </div>

        {isHasAvailable && isMobile && (
          <div className="house__panel">
            <div className="house__panel-info">
              <div className="house__panel-row">{latestHover} <T p={ss.flats.house.mobilePanel.floor} /></div>
              {nav.current !== null && nav.current > 1 && (
                <div className="house__panel-row">{availableFlats} <T p={ss.flats.house.mobilePanel.flats} /></div>
              )}
            </div>
            <button onClick={() => handleClick(latestHover, true)} className="house__panel-choose">
              {nav.current !== null && nav.current > 1 ? (
                <T p={ss.flats.house.mobilePanel.choose} />
              ) : (
                <T p={ss.flats.house.npAvailable} />
              )}
            </button>
          </div>
        )}

        <AnimatePresence>
          {isLoading && (
            <motion.div
              key="loader"
              variants={pageLoaderAnimation}
              initial="initial"
              animate="show"
              exit="exit"
              className="house__loader"
            >
              <PageLoader />
            </motion.div>
          )}
        </AnimatePresence>

      </div>
    </div>
  );
};
