import { Locale } from "@libs/ML";

/**
 * @param p
 * @param allowed
 */
export function getLocaleFromPathname(p: string, allowed: Locale[]) {
  let parts = p.split("/").filter(l => l.length > 0);
  return parts.length ? allowed.find(l => l === parts[0]) || null : null;
}
