/**
 * @description The Page404 component.
 */
import React, { useEffect } from "react";
import { T } from "@/libs/ML/lib/components/T";
import { Link } from "@libs/ML/lib/components/Link";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bg404 } from "@shared/images/bg/exports";
import { ss } from '@/shared/stringSource';
import "./Page404.scss";
import { emitLoadingDone } from "@shared/events/loading";

type Props = {}

export const Page404: React.FC<Props> = function (props) {
  useEffect(() => {
    emitLoadingDone();
  }, []);

  return (
    <div className="p404">
      <div className="p404__cont">

        <div className="p404__bg">
          <BackgroundSet conf={bg404} />
        </div>

        <div className="p404__fg">
          <h1 className="p404__title">
            <T p={ss.shared.p404.title} />
          </h1>

          <div className="p404__text">
            <T p={ss.shared.p404.text} html />
          </div>

          <div className="p404__links">
            <Link to="/" className="p404__link">
              <T p={ss.shared.p404.button} />
            </Link>
          </div>
        </div>

      </div>
    </div>
  );
};
