import { useMemo } from "react";

export interface IUseListAnimationVarsOptions {
  itemDelay?: number;
  itemStagger?: number;
  itemDuration?: number;
  itemYGap?: number;
  direction?: number;
}

const defaultOpts = {
  itemDelay: 0.3 + 0.2,
  itemStagger: 0.15,
  itemDuration: 0.5,
  itemYGap: 0.5,
  direction: 1,
};

export function useListAnimationVars(options?: IUseListAnimationVarsOptions) {
  const opts = options || defaultOpts;

  const variants = useMemo(() => {
    const list = {
      hidden: {},
      visible: {
        transition: {
          delayChildren: opts.itemDelay !== undefined ? opts.itemDelay : defaultOpts.itemDelay,
          staggerChildren: opts.itemStagger !== undefined ? opts.itemStagger : defaultOpts.itemStagger,
          staggerDirection: opts.direction !== undefined ? opts.direction : defaultOpts.direction,
        }
      },
      exit: {},
    };

    const item = {
      hidden: { opacity: 0, y: `${opts.itemYGap !== undefined ? opts.itemYGap : defaultOpts.itemYGap}rem` },
      visible: { opacity: 1, y: 0, transition: {
          duration: opts.itemDuration !== undefined ? opts.itemDuration : defaultOpts.itemDuration,
        }},
      exit: { opacity: 0, }
    };

    return {
      list,
      item,
    }
  }, [opts]);

  return variants;
}
