import { useQuery } from 'react-query';
import { get } from "@shared/api/SettingsAPI";
import { useLocale } from "@libs/ML/lib/hooks/useLocale";

export function useSettings() {
  let locale = useLocale();

  const { data, error, isLoading } = useQuery(['settings', locale], async () => {
    return await get(locale as string);
  });
  return {
    isLoading,
    error,
    ...data,
  }
}
