import { IServerItem } from "@shared/api/commons";
import * as mock from "@libs/Mocks/dataFields";
import api from "@/services/api";
import { IUseQueryMLExtraParams } from "@shared/hooks/useQueryML";
import { QueryKey } from "react-query";

export interface ITeamItem extends IServerItem {
  name: string;
  logo: string;
  position: string;
  link: string | null;
  description: string;
}

function mockItems(): ITeamItem[] {
  return Array(20).fill("").map(() => ({
    id: mock.id(),
    name: mock.title(),
    position: mock.title(),
    logo: mock.photo(),
    link: mock.photo(),
    description: mock.summary(),
  }));
}

export function getItems(params: {queryKey: QueryKey}): Promise<ITeamItem[]> {
  let extraQueryParams = params.queryKey[params.queryKey.length - 1] as IUseQueryMLExtraParams;
  return api.get("/team/", undefined, {
    headers: {
      'Accept-Language': extraQueryParams.locale,
    },
    transformResponse: raw => {
      let data = JSON.parse(raw).team as any[];
      return data.map(el => ({
        id: el.id,
        name: el.title,
        position: el.title,
        description: el.content,
        logo: el.logo,
        link: el.site || null,
      }));
    },
  }, {
    getPreloadingImages: (data: ITeamItem[]) => {
      let images: string[] = [];
      data.filter((_, i) => i <= 3).forEach(item => {
        images.push(item.logo);
      });
      return images;
    },
  });
}
