/**
 * @description The TextSlideInView component.
 */
import React from "react";
import { SharedProps, SomeSFAnimation } from "@shared/components/SomeSFAnimation";
import { AnimateInView } from "@shared/components/AnimateInView";

type Props = {

} & SharedProps;

export const SomeSFInView: React.FC<Props> = function (props) {
  const { children, delay = 0.35, speed = 0.7, dir = "up", gap, full } = props;

  return (
    <AnimateInView>{({ ref, controls }) => (
      <SomeSFAnimation
        delay={delay}
        speed={speed}
        dir={dir}
        animationControl={controls}
        rootRef={ref}
        gap={gap}
        full={full}
      >{children}</SomeSFAnimation>
    )}</AnimateInView>
  );
};
