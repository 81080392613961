import _imgS11Desktop from "./s1/1.jpg";
import _imgS12Desktop from "./s1/2.jpg";
import _imgS11Mobile from "./s1/1m.jpg";
import _imgS12Mobile from "./s1/2m.jpg";
import _imgS101Desktop from "./s10/1.jpg";
import _imgS102Desktop from "./s10/2.jpg";
import _imgS101Mobile from "./s10/1m.jpg";
import _imgS111Desktop from "./s11/1.jpg";
import _imgS112Desktop from "./s11/2.jpg";
import _imgS113Desktop from "./s11/3.jpg";
import _imgS111Mobile from "./s11/1m.jpg";
import _imgS121Desktop from "./s12/1.jpg";
import _imgS121Mobile from "./s12/1m.jpg";
import _imgS21Desktop from "./s2/1.jpg";
import _imgS22Desktop from "./s2/2.jpg";
import _imgS23Desktop from "./s2/3.jpg";
import _imgS21Mobile from "./s2/1m.jpg";
import _imgS22Mobile from "./s2/2m.jpg";
import _imgS23Mobile from "./s2/3m.jpg";
import _imgS31Desktop from "./s3/1.jpg";
import _imgS32Desktop from "./s3/2.jpg";
import _imgS31Mobile from "./s3/1m.jpg";
import _imgS32Mobile from "./s3/2m.jpg";
import _imgS41Desktop from "./s4/1.jpg";
import _imgS42Desktop from "./s4/2.jpg";
import _imgS43Desktop from "./s4/3.jpg";
import _imgS41Mobile from "./s4/1m.jpg";
import _imgS42Mobile from "./s4/2m.jpg";
import _imgS43Mobile from "./s4/3m.jpg";
import _imgS51Desktop from "./s5/1.jpg";
import _imgS52Desktop from "./s5/2.jpg";
import _imgS51Mobile from "./s5/1m.jpg";
import _imgS52Mobile from "./s5/2m.jpg";
import _imgS61Desktop from "./s6/1.jpg";
import _imgS61Mobile from "./s6/1m.jpg";
import _imgS71Desktop from "./s7/1.jpg";
import _imgS72Desktop from "./s7/2.jpg";
import _imgS73Desktop from "./s7/3.jpg";
import _imgS71Mobile from "./s7/1m.jpg";
import _imgS81Desktop from "./s8/1.jpg";
import _imgS82Desktop from "./s8/2.jpg";
import _imgS81Mobile from "./s8/1m.jpg";
import _imgS91Desktop from "./s9/1.jpg";
import _imgS92Desktop from "./s9/2.jpg";
import _imgS93Desktop from "./s9/3.jpg";
import _imgS91Mobile from "./s9/1m.jpg";


export const imgS11Desktop = _imgS11Desktop;
export const imgS12Desktop = _imgS12Desktop;
export const imgS11Mobile = _imgS11Mobile;
export const imgS12Mobile = _imgS12Mobile;
export const imgS1 = { desktop: [_imgS11Desktop, _imgS12Desktop], mobile: [_imgS11Mobile, _imgS12Mobile], };

export const imgS101Desktop = _imgS101Desktop;
export const imgS102Desktop = _imgS102Desktop;
export const imgS101Mobile = _imgS101Mobile;
export const imgS10 = { desktop: [_imgS101Desktop, _imgS102Desktop], mobile: [_imgS101Mobile], };

export const imgS111Desktop = _imgS111Desktop;
export const imgS112Desktop = _imgS112Desktop;
export const imgS113Desktop = _imgS113Desktop;
export const imgS111Mobile = _imgS111Mobile;
export const imgS11 = { desktop: [_imgS111Desktop, _imgS112Desktop, _imgS113Desktop], mobile: [_imgS111Mobile], };

export const imgS121Desktop = _imgS121Desktop;
export const imgS121Mobile = _imgS121Mobile;
export const imgS12 = { desktop: [_imgS121Desktop], mobile: [_imgS121Mobile], };

export const imgS21Desktop = _imgS21Desktop;
export const imgS22Desktop = _imgS22Desktop;
export const imgS23Desktop = _imgS23Desktop;
export const imgS21Mobile = _imgS21Mobile;
export const imgS22Mobile = _imgS22Mobile;
export const imgS23Mobile = _imgS23Mobile;
export const imgS2 = { desktop: [_imgS21Desktop, _imgS22Desktop, _imgS23Desktop], mobile: [_imgS21Mobile, _imgS22Mobile, _imgS23Mobile], };

export const imgS31Desktop = _imgS31Desktop;
export const imgS32Desktop = _imgS32Desktop;
export const imgS31Mobile = _imgS31Mobile;
export const imgS32Mobile = _imgS32Mobile;
export const imgS3 = { desktop: [_imgS31Desktop, _imgS32Desktop], mobile: [_imgS31Mobile, _imgS32Mobile], };

export const imgS41Desktop = _imgS41Desktop;
export const imgS42Desktop = _imgS42Desktop;
export const imgS43Desktop = _imgS43Desktop;
export const imgS41Mobile = _imgS41Mobile;
export const imgS42Mobile = _imgS42Mobile;
export const imgS43Mobile = _imgS43Mobile;
export const imgS4 = { desktop: [_imgS41Desktop, _imgS42Desktop, _imgS43Desktop], mobile: [_imgS41Mobile, _imgS42Mobile, _imgS43Mobile], };

export const imgS51Desktop = _imgS51Desktop;
export const imgS52Desktop = _imgS52Desktop;
export const imgS51Mobile = _imgS51Mobile;
export const imgS52Mobile = _imgS52Mobile;
export const imgS5 = { desktop: [_imgS51Desktop, _imgS52Desktop], mobile: [_imgS51Mobile, _imgS52Mobile], };

export const imgS61Desktop = _imgS61Desktop;
export const imgS61Mobile = _imgS61Mobile;
export const imgS6 = { desktop: [_imgS61Desktop], mobile: [_imgS61Mobile], };

export const imgS71Desktop = _imgS71Desktop;
export const imgS72Desktop = _imgS72Desktop;
export const imgS73Desktop = _imgS73Desktop;
export const imgS71Mobile = _imgS71Mobile;
export const imgS7 = { desktop: [_imgS71Desktop, _imgS72Desktop, _imgS73Desktop], mobile: [_imgS71Mobile], };

export const imgS81Desktop = _imgS81Desktop;
export const imgS82Desktop = _imgS82Desktop;
export const imgS81Mobile = _imgS81Mobile;
export const imgS8 = { desktop: [_imgS81Desktop, _imgS82Desktop], mobile: [_imgS81Mobile], };

export const imgS91Desktop = _imgS91Desktop;
export const imgS92Desktop = _imgS92Desktop;
export const imgS93Desktop = _imgS93Desktop;
export const imgS91Mobile = _imgS91Mobile;
export const imgS9 = { desktop: [_imgS91Desktop, _imgS92Desktop, _imgS93Desktop], mobile: [_imgS91Mobile], };


