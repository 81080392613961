import { generatePath, matchPath } from 'react-router';

import * as route from './route';
import { Conf, RouterType } from './Conf';

/**
 * Router state: current page (route) and matches
 */
export interface CurrentRouterState {
  routeID: string | null;
  matches: {
    [key: string]: string;
  };
}

/**
 * Common service
 */
export interface Serviceable {
  generatePath: typeof generatePath;
  getCurrentState(pathname: string): CurrentRouterState;
  addRoutes(r: route.Config[]): void;
  getPathname(): string;
}

export class Service implements Serviceable {
  routes: route.Config[];

  conf: Conf;

  /**
   * Generates route path by path pattern and matches' params
   * @param path
   * @param params
   */
  generatePath = generatePath;

  constructor(conf: Conf) {
    this.routes = [];
    this.conf = conf;
  }

  /**
   * @param r - routes configs
   */
  addRoutes(r: route.Config[]): void {
    this.routes = [...this.routes, ...r];
  }

  /**
   * Gets current state by pathname
   * @param pathname
   */
  getCurrentState(pathname: string = this.getPathname()): CurrentRouterState {
    let out: CurrentRouterState = {
      routeID: null,
      matches: {},
    };

    for (let i = 0; i < this.routes.length; i++) {
      const c = this.routes[i];
      const matchResult = matchPath(pathname, {
        path: c.path,
        exact: c.exact,
      });
      if (matchResult !== null) {
        out = {
          matches: matchResult.params,
          routeID: c.id,
        };
        return out;
      }
    }

    return out;
  }

  /**
   * Gets a pathname
   */
  getPathname(w: Window = window): string {
    return this.conf.routerType === RouterType.Browser ? w.location.pathname : w.location.hash.slice(1);
  }
}
