/**
 * @description The DummyPage component.
 */
import React, { ReactElement } from "react";
import { useRouteMatch } from "react-router";
import { useQueryML } from "@shared/hooks/useQueryML";
import { getItem, INewsSingleItem } from "@features/posts/api/NewsAPI";

import { PageLayout } from "@shared/components/PageLayout/PageLayout";
import { PostContent } from "@shared/components/PostContent";
import { DateView } from "@shared/components/DateView";
import { EVENTS_SECTION } from "@/menuConfig";
import "./NewsItemPage.scss";
import { Back } from "@shared/components/Back";
import { bgNews } from "@shared/images/bg/exports";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { SomeSFAnimation } from "@shared/components/SomeSFAnimation";
import { useIsMobile } from "@shared/hooks/useIsMobile";

type Props = {}

export const Page = function (props: Props): ReactElement {
  const match = useRouteMatch<{ slug: string | undefined }>();
  const { isLoading, error, data } = useQueryML<INewsSingleItem>(["newsItem", match.params.slug], getItem);
  const isMobile = useIsMobile();

  return (
    <>
      <PageLayout
        isLoading={isLoading}
        error={error?.message}
        title={data?.title}
        breadcrumbs={EVENTS_SECTION}
        mods={['post-content']}
        bg={<BackgroundSet conf={bgNews} />}
      >
        <div className="news-item">
          <div className="news-item__date">
            <SomeSFAnimation delay={0.3} gap={10}>
              <DateView date={data?.date || new Date()} />
            </SomeSFAnimation>
          </div>
          <div className="news-item__content">
            <SomeSFAnimation delay={0.3} gap={isMobile ? 20 : 50}>
              <PostContent mods={["news"]} content={data?.body || ""} />
            </SomeSFAnimation>
          </div>
        </div>
      </PageLayout>
      <Back to="/news" />
    </>
  );
};
