import { pgettext } from "@libs/ML/lib/django/stack";

export interface ITransformStringStorageItemSettings {
  params?: {[p: string]: string | number};
}

export function transformStringStorageItem(p: string, value: string, settings: ITransformStringStorageItemSettings = {}): string {
  let params = settings.params || {};
  value = pgettext(p, value);
  Object.keys(params).forEach((key) => {
    value = value.replace(`{{ ${key} }}`, `${params[key]}`);
    value = value.replace(`{{${key}}}`, `${params[key]}`);
  });
  return value;
}
