/**
 * @description The Submit component.
 */
import React from "react";
import { T } from "@/libs/ML/lib/components/T";
import { ss as sharedSS } from '@/shared/stringSource';
import "./Submit.scss";

type Props = {
  disabled: boolean;
  submitting: boolean;
};

export const Submit: React.FC<Props> = function (props) {
  const { submitting, disabled } = props;

  return (
    <button className="submit" type="submit" disabled={disabled}>
      <T p={submitting ? sharedSS.shared.form.sending : sharedSS.shared.form.submit} />
    </button>
  );
};
