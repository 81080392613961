/**
 * @description The Menu component.
 */
import React from "react";
import "./Menu.scss";
import { menuConf, IMenuItem } from "@/menuConfig";
import { NavLink } from "@libs/ML/lib/components/NavLink";
import { NavLink as RouterNavLink } from 'react-router-dom';
import { T } from "@libs/ML/lib/components/T";
import { useSettings } from "@shared/hooks/useSettings";
import { useLangSwitcher } from "@shared/hooks/useLangSwitcher";
import { Close } from "@shared/components/Close";
import { useMenuState } from "@shared/hooks/useMenuState";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import logo from "@/shared/images/logo.svg";
import { bgMenu1Desktop, bg4041Mobile, bgMenu1Mobile } from "@shared/images/bg/exports";
import { Link } from "@libs/ML/lib/components/Link";

type Props = {}
// todo add the bg

export const Menu: React.FC<Props> = function (props) {
  const { phone, googleMap, address, instagram, facebook } = useSettings();
  const langSwitcher = useLangSwitcher();
  const { isOpen, hide } = useMenuState();
  const isMobile = useIsMobile();

  const renderItem = (item: IMenuItem) => {
    if (item.path) {
      return <NavLink
        className="menu__link"
        to={item.path}
        exact={!!item.menuExact}
        activeClassName={item.disableActiveClass ? 'active-disabled' : 'active'}
      ><T p={item.labelPath} /></NavLink>
    }
    return <span className="menu__link"><T p={item.labelPath} /></span>
  };

  return (
    <div className={makeMods("menu", {
      open: isOpen,
    })}>
      <div className="menu__bg">
        <img src={isMobile ? bgMenu1Mobile : bgMenu1Desktop} alt="" className="menu__bg-img"/>
      </div>
      <div className="menu__cont">

        <Link to="/" className="menu__logo">
          <img src={logo} alt="Mayak"/>
        </Link>

        <div className="menu__main">
          {menuConf.map((section, i) => (
            <div className="menu__section" key={i}>
              {section.heading && (
                <div className="menu__section-heading">
                  {renderItem(section.heading)}
                </div>
              )}
              {section.items.length > 0 && (
                <div className="menu__section-items">
                  {section.items.map(item => (
                    <div key={item.labelPath} className="menu__section-item">
                      {renderItem(item)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="menu__additional">
          <div className="menu__address">
            <a href={`tel:${phone}`} className="menu__address-el">{phone}</a>
            <a href="https://www.google.com.ua/maps/dir//48.4660013,35.0650933/@48.4651067,35.0635544,17.82z" target="_blank" className="menu__address-el">{address}</a>
          </div>
          <div className="menu__additional-main">
            <div className="menu__socials">
              <a href={instagram} target="_blank" className="menu__social menu__social--insta" />
              <a href={facebook} target="_blank" className="menu__social menu__social--fb" />
            </div>
            <div className="menu__lang-switcher">
              <RouterNavLink className="menu__lang-switcher-el" to={langSwitcher.ru}>RU</RouterNavLink>
              <RouterNavLink className="menu__lang-switcher-el" to={langSwitcher.en}>EN</RouterNavLink>
              <RouterNavLink className="menu__lang-switcher-el" to={langSwitcher.uk}>UK</RouterNavLink>
            </div>
          </div>
        </div>

        <div className="menu__close">
          <Close onClick={hide} />
        </div>

      </div>
    </div>
  );
};
