import { api } from "@/services";
import { Locale } from "@libs/ML";

export interface ISettings {
  "phone": string,
  "phoneList": string[],
  "instagram": string,
  "youtube": string,
  "facebook": string,
  "googleMap": string,
  "address": string,
  "salesOfficeAddress": string,
  "email": string,
  "buildProgress": number,
  "constructionFinishDate": string,
  "workingTime": string
}

export function get(locale: Locale): Promise<ISettings> {
  return api.get("/setting/", undefined, {
    headers: {
      "Accept-Language": locale,
    },
    transformResponse: (raw) => {
      let data = JSON.parse(raw);
      return {
        "phone": data.phone,
        "phoneList": data.phone_list,
        "instagram": data.instagram,
        "youtube": data.youtube,
        "facebook": data.facebook,
        "googleMap": data.google_map,
        "address": data.address,
        "salesOfficeAddress": data.sales_office_address,
        "email": data.email,
        "buildProgress": data.build_progress,
        "constructionFinishDate": data.construction_finish_date,
        "workingTime": data.working_time,
      }
    },
  });
}
