/**
 * @description The DocumentsList component.
 */
import React, { ReactNode, useEffect, useState, useMemo } from "react";

import { IDocumentItem } from "@features/posts/api/DocumentsAPI";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from "@features/posts/stringSource";
import { DateTimeView } from "@shared/components/DateTimeView";
import "./DocumentsList.scss";
import { Hidden } from "@shared/components/Hidden";
import { motion } from "framer-motion";
import { useIsMobile } from "@shared/hooks/useIsMobile";

type Props = {
  title: ReactNode,
  items: IDocumentItem[],
  index: number,
};

export const DocumentsList: React.FC<Props> = function (props) {
  const [showAll, setShowAll] = useState(false);
  const isMobile = useIsMobile();

  const variants = useMemo(() => {
    const list = {
      hidden: { opacity: 0, y: '1em' },
      visible: {
        opacity: 1, y: '0em',
        transition: {
          duration: 0.5,
          delay: 0.4 + (0.8 * props.index),
          delayChildren: 0.3 + 0.4,
          staggerChildren: 0.1
        }
      },
    };

    const item = {
      hidden: { opacity: 0, y: '0.5em' },
      visible: { opacity: 1, y: 0 },
    };

    return {
      list,
      item,
    }
  }, []);

  useEffect(() => {
    setShowAll(false);
  }, [props.items]);

  const renderItem = (item: IDocumentItem) => (
    <div key={item.id} className="documents-list__list-el">

      <div className="documents-list-el">
        <div className="documents-list-el__cont">
          <div className="documents-list-el__col documents-list__list-col">
            <a href={item.file.link} target="_blank" className="documents-list-el__link"/>
            <div className="documents-list-el__text">
              <div className="documents-list-el__text-row">{item.title}</div>
              <div className="documents-list-el__text-row">{item.file.type}, {item.file.size}</div>
            </div>
          </div>
          <div className="documents-list-el__col documents-list__list-col">
            <div className="documents-list-el__text">
              <div className="documents-list-el__text-row">
                <DateTimeView date={item.pubDate}/>
              </div>
              <div className="documents-list-el__text-row documents-list-el__text-row--author">{item.author}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants.list}
      className="documents-list">
      <div className="documents-list__cont">

        <div className="documents-list__heading">
          <h2 className="documents-list__title">{props.title}</h2>
        </div>

        <div className="documents-list__list">
          {!isMobile && (
            <motion.div variants={variants.item} className="documents-list__list-head">
              <div className="documents-list__list-head-col documents-list__list-col">
                <T p={ss.posts.documents.columns.document}/>
              </div>
              <div className="documents-list__list-head-col documents-list__list-col">
                <T p={ss.posts.documents.columns.published}/>
              </div>
            </motion.div>
          )}
          <div className="documents-list__list-main">
            <div className="documents-list__list-items">

              {props.items.filter((_, i) => i < 3).map(item => (
                <motion.div variants={variants.item}>
                  {renderItem(item)}
                </motion.div>
              ))}

              {props.items.length > 3 && (
                <Hidden isShow={showAll}>
                  {props.items.filter((_, i) => i >= 3).map(item => (
                    <motion.div variants={variants.item}>
                      {renderItem(item)}
                    </motion.div>
                  ))}
                </Hidden>
              )}

            </div>
          </div>
        </div>

        {props.items.length > 3 && (
          <motion.div variants={variants.item} className="documents-list__more">
            <button
              onClick={() => setShowAll(!showAll)}
              className={`documents-list__toggle-more ${showAll ? 'active' : ''}`}>{showAll ? (
              <T p={ss.posts.documents.showLess}/>
            ) : (
              <T p={ss.posts.documents.showMore}/>
            )}</button>
          </motion.div>
        )}

      </div>
    </motion.div>
  );
};
