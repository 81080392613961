import faker from 'faker';




















































export const pmSRC = [
  [`ТЦ «Пассаж»`, [48.464320416773496, 35.047265359470195], 'entertainment'],
  [`ТРК МОСТ-сити`, [48.46664814468874, 35.05085343996953], 'entertainment'],
  [`КДЦ Менора`, [48.46395259794079, 35.05334020970788], 'entertainment'],
  [`ТЦ ATRIUM`, [48.45778980537326, 35.05965018062346], 'entertainment'],
  [`ТЦ Европа`, [48.465645246322524, 35.046847293519626], 'entertainment'],
  [`Городской крейсерский яхт-клуб`, [48.461120571155654, 35.07779230658845], 'entertainment'],
  [`Яхт-клуб "ПАРУС"`, [48.45219321689437, 35.079299450254304], 'entertainment'],
  [`Le Silpo`, [48.46043151442509, 35.04993577578728], 'shops'],
  [`Сильпо`, [48.468047283549886, 35.0488466738244], 'shops'],
  [`Сильпо`, [48.46280351762057, 35.05497955143094], 'shops'],
  [`VARUS`, [48.468494807171304, 35.05680595834284], 'shops'],
  [`COMFY`, [48.469447606690665, 35.052315183351745], 'shops'],
  [`ELDORADO`, [48.47593882771104, 35.02078083157894], 'shops'],
  [`Coast Restaurant & Lounge`, [48.463473151372554, 35.07425427541103], 'cafe'],
  [`ресторан Шопочем`, [48.4614013861535, 35.07007393687644], 'cafe'],
  [`Ирландский паб&ресторан Банка`, [48.4601576053321, 35.06730387447746], 'cafe'],
  [`Ресторан Dollar`, [48.460057678233326, 35.06927327170699], 'cafe'],
  [`Артист Restaurant & Coffee`, [48.45752390422164, 35.062803733820594], 'cafe'],
  [`Ресторан Buon Fratelli`, [48.4576153378033, 35.05725125419753], 'cafe'],
  [`Ресторан GIANNIVINO`, [48.459216043210425, 35.05690706843356], 'cafe'],
  [`Ресторан Мамою Клянусь`, [48.461828409341656, 35.06105775494787], 'cafe'],
  [`Фитнес-клуб Sport Star`, [48.46545004082752, 35.06646151425588], 'sport'],
  [`Wellness-клуб "Перспектива"`, [48.46267104256034, 35.064245556441655], 'sport'],
  [`MGym Menorah Fitness`, [48.4636863764638, 35.05388191161594], 'sport'],
  [`Фитнес-клуб Fit Haus`, [48.46259632046902, 35.055201108140274], 'sport'],
  [`Студия балета и пилатеса Balletera`, [48.46093174671297, 35.06609255590301], 'sport'],
  [`Студия Pilates Room`, [48.46521505980468, 35.05897102314774], 'sport'],
  [`Спортивный комплекс «Cлавутич»`, [48.457365909741824, 35.075339805793796], 'sport'],
  [`луб "Днепр-Бадминтон"`, [48.4654007305894, 35.060196149958685], 'sport'],
  [`Теннисные корты`, [48.46222251241481, 35.07439340719543], 'sport'],
  [`Волейбольные площадки`, [48.46312874414798, 35.08125737315012], 'sport'],
  [`Больница Мечникова`, [48.45386688182762, 35.06955627249785], 'health'],
  [`Днепропетровский центр первичной медико-санитарной помощи № 4 Амбулатория № 1-2`, [48.46719772984812, 35.05515920415418], 'health'],
  [`Днепровская городская поликлиника №1`, [48.45964506854396, 35.06765382326683], 'health'],
  [`Поликлиника детская`, [48.45691046742214, 35.05689064199714], 'health'],
  [`Медичний центр "Оксфорд Медікал Дніпро на Глінки"`, [48.46573536224451, 35.05098888627519], 'health'],
  [`Клиника СмартМед`, [48.45573708840382, 35.06041630725922], 'health'],
  [`Центр семейного здоровья Гелиос`, [48.46189534986343, 35.06328771739095], 'health'],
  [`Частная общеобразовательная школа Primus Inter Pares School`, [48.46508862131198, 35.0680738310681], 'school'],
  [`Специализированная школа №67`, [48.45994929364562, 35.06327074077024], 'school'],
  [`Частная школа «Logos School»`, [48.460888761509736, 35.044205260944686], 'school'],
  [`Средняя школа № 9`, [48.47426338229667, 35.027256296892666], 'school'],
  [`Средняя школа № 21`, [48.46496321657862, 35.03598471673649], 'school'],
  [`Лицей №100`, [48.46477352718366, 35.05628917221794], 'school'],
  [`Частный детский сад Hello Baby`, [48.45630825223473, 35.05650112966127], 'child'],
  [`Детский сад "Розвиток"`, [48.46225649400309, 35.04007746429217], 'child'],
  [`KidsСlub - частный детский сад`, [48.4680613192739, 35.03671101875213], 'child'],
  [`Частный садик "Абрикос"`, [48.459934901725426, 35.048538531984335], 'child'],
  [`Малышкин мир. Частный детский сад-ясли`, [48.46962824453243, 35.01953623959903], 'child'],
  [`Дошкольное учебное заведение № 227 "Днепряночка"`, [48.47421782260838, 35.03887777596031], 'child'],
].map((el) => ({
  label: el[0] as string,
  coords: el[1] as [number, number],
  type: el[2] as string,
  id: faker.datatype.uuid() as string,
}));
