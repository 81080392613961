/**
 * @description The TeamListEl component.
 */
import React from "react";
import { ITeamItem } from "@features/posts/api/TeamAPI";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from "@features/posts/stringSource";

type Props = {} & ITeamItem;

export const TeamListEl: React.FC<Props> = function (props) {
  const { name, logo, position, link, description } = props;

  const content = (
    <div className="team-list-el__cont">
      <div className="team-list-el__position">{position}</div>
      <div className="team-list-el__content">
        <div className="team-list-el__logo">
          <img src={logo} alt={name} className="team-list-el__logo-img"/>
        </div>
        <div className="team-list-el__text content-area" dangerouslySetInnerHTML={{__html: description}} />
      </div>
      <div className="team-list-el__link">
        {link && (
          <div className="team-list-el__link-label">
            <T p={ss.posts.team.goToSite} />
          </div>
        )}
      </div>
    </div>
  );

  if (link) {
    return (
      <a className="team-list-el" href={link} target="_blank">{content}</a>
    );
  }

  return (
    <div className="team-list-el">
      {content}
    </div>
  );
};
