export enum RouterType {
  Hash = 'hash',
  Browser = 'browser',
}

export interface Conf {
  routerType: RouterType;
  breadcrumbsSize: number;
  useTrailingSlash: boolean;
}
