/**
 * @description The PostContent component.
 */
import React, { useMemo } from "react";
import { ModsArray } from "@libs/YandexBEM/lib/modsFromArray";
import { ModsObject } from "@libs/YandexBEM/lib/modsFromObject";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import "./PostContent.scss";

type Props = {
  content: any;
  mods?: ModsArray | ModsObject;
};

export const PostContent: React.FC<Props> = function (props) {
  const {
    content,
    mods = [],
  } = props;

  const preparedContent = useMemo(() => {
    if (typeof content !== "string") return content;
    let prepared = content;
    prepared = prepared.replace(`<iframe `, `<div class="iframe-wrap"><iframe `);
    prepared = prepared.replace(`</iframe> `, `</iframe></div>`);
    return prepared;
  }, [content]);

  const propsForRender = {
    ...(typeof content === "string" ? {
      dangerouslySetInnerHTML: {
        __html: preparedContent,
      }
    } : {
      children: typeof preparedContent === "function" ? preparedContent() : preparedContent,
    })
  };

  return (
    <div className={makeMods("post-content", mods)}>
      <div className="post-content__cont">
        <div className="content-area" {...propsForRender} />
      </div>
    </div>
  );
};

