/**
 * Feature ducks
 */

import { SimpleDuck } from "@libs/Ducks/lib/core/duckStack";
import { connectable as search } from "@features/flats/ducks/search.duck";

/**
 * Add ducks to array here
 */
export const ducks: SimpleDuck[] = [
  search,
];
