export const EVENT_LOADING_DONE = 'mayak/loadingDone';
export const EVENT_LOADER_HIDDEN = 'mayak/loaderHidden';

export function emitLoadingDone() {
  window.dispatchEvent(new CustomEvent(EVENT_LOADING_DONE, {
    detail: {
      react: true,
    }
  }));
}
