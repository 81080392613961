/**
 * Preloads image via HTML tag creating
 * @param src
 */
export function preloadImage(src: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    let img = new Image();
    img.src = src;
    img.onload = () => {
      resolve(img)
    };
    img.onerror = (event) => {
      reject(event);
    }
  });
}

/**
 * Preloads images set
 * @param src
 */
export function preloadImagesSet(src: string[]) {
  return Promise.all(src.map(preloadImage));
}
