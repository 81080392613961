import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface APIServiceHeaders {
  [key: string]: string;
}

export interface APIServiceAuthConf {
  isUseByDefault?: boolean;
  getHeaders?(): APIServiceHeaders;
}

export const DEFAULT_AUTH_CONF: APIServiceAuthConf = {
  getHeaders(): APIServiceHeaders {
    return {};
  },
  isUseByDefault: false,
};

export interface APIServiceI18nConf {
  isUseByDefault?: boolean;
  getHeaders?(): APIServiceHeaders;
}

export const DEFAULT_I18N_CONF: APIServiceI18nConf = {
  getHeaders(): APIServiceHeaders {
    return {};
  },
  isUseByDefault: false,
};

export interface APIServiceConf {
  readonly auth?: APIServiceAuthConf;
  readonly i18n?: APIServiceI18nConf;
  readonly baseURL: string;
  readonly defaultHeaders?: APIServiceHeaders;
  readonly imagesPreloadingHandler?: (src: string[]) => Promise<HTMLImageElement[]>;
}

export const DEFAULT_CONF: APIServiceConf = {
  auth: DEFAULT_AUTH_CONF,
  i18n: DEFAULT_I18N_CONF,
  baseURL: '',
  defaultHeaders: {},
  imagesPreloadingHandler: undefined,
};

export interface APIServiceUseReqConf {
  auth?: boolean;
  i18n?: boolean;
  mock?: (...args: any) => any;
  getPreloadingImages?: (data: any, ...args: any) => string[];
}

export interface APIServiceQueryParams {
  [key: string]: string | number | null;
}

/**
 * Service class
 */
export default class APIService {
  public config: APIServiceConf = DEFAULT_CONF;

  constructor(config?: APIServiceConf) {
    this.config = config || DEFAULT_CONF;
  }

  /**
   * Generates headers for the request
   */
  makeHeaders(useConf: APIServiceUseReqConf): APIServiceHeaders {
    let out: APIServiceHeaders = { ...this.config.defaultHeaders };
    if (useConf.auth && this.config.auth && this.config.auth.getHeaders) {
      out = { ...out, ...this.config.auth.getHeaders() };
    }
    if (useConf.i18n && this.config.i18n && this.config.i18n.getHeaders) {
      out = { ...out, ...this.config.i18n.getHeaders() };
    }
    return out;
  }

  /**
   * Generates request conf
   */
  makeReqConf(conf: AxiosRequestConfig = {}, useConf: APIServiceUseReqConf): AxiosRequestConfig {
    let headers = {
      ...this.makeHeaders(useConf),
    };
    if (conf.headers) headers = { ...headers, ...conf.headers };
    return {
      baseURL: this.config.baseURL,
      ...conf,
      headers,
    };
  }

  /**
   * Makes axios request
   */
  request<T = any, R = AxiosResponse<T>>(conf: AxiosRequestConfig = {}, useConf?: APIServiceUseReqConf): Promise<R> {
    if (useConf && useConf.mock) {
      return new Promise<R>((res) => {
        let mock = useConf && useConf.mock;
        setTimeout(() => {
          res((mock ? mock() : {}) as unknown as R);
        }, 1000);
      });
    }

    let tmpData: any;

    return axios.request(this.makeReqConf(conf, useConf || {}))
      .then((resp) => {
        let data = resp.data;
        tmpData = data;
        if (useConf?.getPreloadingImages && this.config.imagesPreloadingHandler) {
          return this.config.imagesPreloadingHandler(useConf.getPreloadingImages(tmpData));
        }
        return data;
      })
      .then(() => {
        return tmpData;
      })
      .catch(e => {
        throw e;
      });
  }

  /**
   * Makes get request
   */
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    q: APIServiceQueryParams = {},
    reqConf: AxiosRequestConfig = {},
    useConf?: APIServiceUseReqConf,
  ): Promise<R> {
    return this.request(
      {
        ...reqConf,
        method: 'get',
        url,
        params: q,
      },
      useConf,
    );
  }

  /**
   * Makes post request
   */
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data: any,
    q: APIServiceQueryParams = {},
    reqConf: AxiosRequestConfig = {},
    useConf?: APIServiceUseReqConf,
  ): Promise<R> {
    return this.request(
      {
        ...reqConf,
        method: 'post',
        url,
        data,
        params: q,
      },
      useConf,
    );
  }

  /**
   * Makes put request
   */
  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data: any,
    q: APIServiceQueryParams = {},
    reqConf: AxiosRequestConfig = {},
    useConf?: APIServiceUseReqConf,
  ): Promise<R> {
    return this.request(
      {
        ...reqConf,
        method: 'put',
        url,
        data,
        params: q,
      },
      useConf,
    );
  }

  /**
   * Makes patch request
   */
  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data: any,
    q: APIServiceQueryParams = {},
    reqConf: AxiosRequestConfig = {},
    useConf?: APIServiceUseReqConf,
  ): Promise<R> {
    return this.request(
      {
        ...reqConf,
        method: 'patch',
        url,
        data,
        params: q,
      },
      useConf,
    );
  }

  /**
   * Makes delete request
   */
  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    data: any,
    q: APIServiceQueryParams = {},
    reqConf: AxiosRequestConfig = {},
    useConf?: APIServiceUseReqConf,
  ): Promise<R> {
    return this.request(
      {
        ...reqConf,
        method: 'delete',
        url,
        data,
        params: q,
      },
      useConf,
    );
  }
}
