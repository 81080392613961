/**
 * @description The HouseScene component.
 */
import React, { useRef, useEffect } from "react";
import {
  PerspectiveCamera,
  WebGLRenderer,
  GammaEncoding,
  Scene,
  Color,
  Fog,
  PCFSoftShadowMap,
  LinearToneMapping,
  Mesh, Material, MeshPhongMaterial,
  Vector2, Raycaster, Intersection, Object3D
} from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import "./HouseScene.scss";

type FloorObject = {
  n: number,
  floor: Mesh & {
    floorNumber: number,
  },
  number?: Mesh,
}

type ThreeInterface = {
  camera: PerspectiveCamera,
  renderer: WebGLRenderer,
  scene: Scene,
  controls: OrbitControls,
  materials: {
    floorBase: Material,
    floorHover: Material,
    numberBase: Material | null,
    numberHover: Material | null,
  },
  raycaster: Raycaster,
  mouseVector: Vector2,
  floors: FloorObject[],
  canvas: HTMLCanvasElement,
}

type Props = {
  isInit: boolean;
  onInit: () => any;
  hover: number | null;
  hoverFact:  number | null;
  onHover: (f: number | null) => any;
  onClick: (f: number | null) => any;
  model: Object3D;
  animate?: boolean;
};

export const HouseScene: React.FC<Props> = function (props) {
  const { onInit, onHover, onClick, hover, hoverFact, model, isInit, animate = false } = props;
  const rendererRef = useRef<HTMLDivElement>(null);
  const hoverFactRef = useRef(hoverFact);
  const threeRef = useRef<ThreeInterface>();
  const animationFrame = useRef(0);
  const propsRef = useRef(props);

  useEffect(() => {
    hoverFactRef.current = hoverFact;
  }, [hoverFact]);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  useEffect(() => {
    // get widgth and height
    const getCanvasSize = () => {
      let bcr = (rendererRef.current as HTMLElement).getBoundingClientRect();
      return {
        width: bcr.width,
        height: bcr.height,
      }
    };

    // camera conf
    let canvasSize = getCanvasSize();
    const camera = new PerspectiveCamera(60, canvasSize.width / canvasSize.height, 0.1, 2000);

    // renderer conf
    const renderer = new WebGLRenderer({
      antialias: true,
    });
    renderer.outputEncoding = GammaEncoding;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;
    renderer.physicallyCorrectLights = true;
    renderer.toneMapping = LinearToneMapping;
    renderer.toneMappingExposure = 1.5;

    // scene conf
    const scene = new Scene();
    scene.background = new Color('#464646');
    scene.fog = new Fog('#515151', 0.10, 70);
    scene.add(camera);
    scene.add(...model.children.filter(el => {
      return el.uuid !== 'CF515C6B-2A4B-4895-884C-8DDB21B097F2' && el.uuid !== "FA4D5B8A-C6D9-435C-81E4-220C9FBE9CB3"
    }));

    // extract floors objects
    const floors: FloorObject[] = [];
    const floorsNToIndexMap: {[k: number]: number} = {};
    const floorsGroup = "8899DBE8-EB08-4AC7-861B-2BAC5F77B5DB";
    const floorsUnderGroup = "4FA15AF0-DF57-417C-A16A-78DDA8A93575";
    const numbersGroup = "B2B04CA0-308A-40CD-9ED1-3482AC3DAC99";
    scene.children.find(el => el.uuid === floorsGroup)?.children.forEach(obj => {
      if (obj.type === 'Mesh' && obj.name.match("floor_")) {
        let n = parseInt(obj.name.replace("floor_", ''));
        (obj as any).floorNumber = n;
        floors.push({
          n,
          floor: obj as any,
          number: obj as Mesh,
        });
        floorsNToIndexMap[n] = floors.length - 1;
      }
    });
    scene.children.find(el => el.uuid === floorsUnderGroup)?.children.forEach(obj => {
      if (obj.type === 'Mesh' && obj.name.match("ST-")) {
        let n = parseInt(obj.name.replace("ST-", '')) * -1;
        (obj as any).floorNumber = n;
        floors.push({
          n: n,
          floor: obj as any,
          number: undefined,
        });
        floorsNToIndexMap[n] = floors.length - 1;
      }
    });
    scene.children.find(el => el.uuid === numbersGroup)?.children.forEach(obj => {
      if (obj.type === 'Mesh' && obj.name.match("number_")) {
        let n = parseInt(obj.name.replace("number_", '')),
          index = floorsNToIndexMap[n];
        if (index !== undefined && floors[index]) {
          floors[index].number = obj as Mesh;
        }
      }
    });

    // split materials and set opacity
    const floorBaseMaterial = (floors[0].floor.material as MeshPhongMaterial).clone();
    const floorHoverMaterial = floorBaseMaterial.clone();
    floorHoverMaterial.color.setHex(0x1D754B);

    const numberHoverMaterial = floors[0].number ? (floors[0].number.material as MeshPhongMaterial).clone() : null;
    const numberBaseMaterial = numberHoverMaterial ? numberHoverMaterial.clone() : null;
    if (numberBaseMaterial) {
      numberBaseMaterial.opacity = 0;
      numberBaseMaterial.transparent = true;
      floors.forEach(f => f.number !== undefined && (f.number.material = numberBaseMaterial));
    }

    // init renderer
    renderer.setSize(canvasSize.width, canvasSize.height);
    renderer.setPixelRatio(window.devicePixelRatio);
    const canvas = (rendererRef.current as HTMLElement).appendChild(renderer.domElement);

    // controls conf
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.maxPolarAngle = Math.PI * 0.4;
    controls.minDistance = 12;
    controls.maxDistance = 40;
    controls.enablePan = false;

    // set camera pos
    camera.position.set(16.317592974770676, 13.2243354876804, -15.04442943993347);
    camera.rotation.set(-180, 45, -180);
    camera.frustumCulled = true;
    controls.target.set(2.9 - 0.5, 7, -2.1);
    controls.update();

    // mouse events
    const mouseVector = new Vector2(canvasSize.width / 2, canvasSize.height / 2);
    const raycaster = new Raycaster();
    const hoverObjects = floors.map(f => f.floor);

    const handleHover = (intersects: Intersection[]) => {
      if (!intersects.length) {
        propsRef.current.onHover(null);
        return
      }
      let floor = (intersects[0].object as any).floorNumber;
      if (floor && floor !== hoverFactRef.current) {
        propsRef.current.onHover(floor as number);
      } else {
        propsRef.current.onHover(null);
      }
    };

    const handleClick = () => {
      raycaster.setFromCamera(mouseVector, camera);
      const intersects = raycaster.intersectObjects(hoverObjects);
      if (!intersects.length) {
        return
      }
      let floor = (intersects[0].object as any).floorNumber;
      if (floor) {
        propsRef.current.onClick(floor);
      }
    };
    window.addEventListener('click', handleClick, false);

    // bind refs
    threeRef.current = {
      camera,
      controls,
      scene,
      renderer,
      materials: {
        floorBase: floorBaseMaterial,
        floorHover: floorHoverMaterial,
        numberBase: numberBaseMaterial,
        numberHover: numberHoverMaterial,
      },
      mouseVector,
      raycaster,
      floors,
      canvas,
    };

    // run animation cycle
    const animate = () => {
      animationFrame.current = requestAnimationFrame(animate);
      raycaster.setFromCamera(mouseVector, camera);
      const intersects = raycaster.intersectObjects(hoverObjects);
      handleHover(intersects);
      if (propsRef.current.animate) {
        if (propsRef.current.isInit) {
          controls.autoRotate = true;
          controls.autoRotateSpeed = 1;
        }
      } else {
        controls.autoRotate = false;
      }
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    // add resize handler
    const handleResize = () => {
      let size = getCanvasSize();
      camera.aspect = size.width / size.height;
      camera.updateProjectionMatrix();
      renderer.setSize(size.width, size.height);
    };
    window.addEventListener('resize', handleResize);

    // add mousemove handler
    const handleMouseMove = (e: MouseEvent) => {
      let size = getCanvasSize();
      mouseVector.x = (e.clientX / size.width) * 2 - 1;
      mouseVector.y = - (e.clientY / size.height) * 2 + 1;
    };
    (rendererRef.current as HTMLElement).addEventListener("mousemove", handleMouseMove, false);

    // set is init
    onInit();

    // reset
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('click', handleClick);
      cancelAnimationFrame(animationFrame.current);
    };
  }, []);

  useEffect(() => {
    if (!threeRef.current) return;
    const { materials, floors } = threeRef.current as ThreeInterface;
    floors.forEach(f => {
      f.floor.material = materials.floorBase;
      if (f.number && materials.numberBase) {
        f.number.material = materials.numberBase;
      }
    });
    if (hover !== null) {
      let floor = floors.find(f => f.n === hover);
      if (!floor) return;
      floor.floor.material = materials.floorHover;
      if (floor.number && materials.numberHover) {
        floor.number.material = materials.numberHover;
      }
    }
  }, [hover]);

  return (
    <div className="house-scene">
      <div className="house-scene__cont">

        <div ref={rendererRef} className="house-scene__renderer" />

      </div>
    </div>
  );
};
