/**
 * @description The DateTimeView component.
 */
import React, { useMemo } from "react";
import { useLocale } from "@libs/ML/lib/hooks/useLocale";
import { dateConf } from "@/dateConfig";
import format from "date-fns/format";

type Props = {
  date: Date | string;
}

export const DateTimeView: React.FC<Props> = function (props) {
  const locale = useLocale();
  const { date } = props;

  const dateToUse: Date = useMemo(() => {
    return date instanceof Date ? date : new Date(date);
  }, [date]);

  const formatted = useMemo(() => {
    const options = {
      locale: locale !== null ? dateConf.i18nMap[locale] : undefined
    };

    let formatStr = "dd.MM.yyyy HH:mm";
    if (locale === "en") formatStr = "MM/dd/yyyy p";

    return format(dateToUse, formatStr, options);
  }, [dateToUse, locale]);

  return (
    <div className="date-time-view">{formatted}</div>
  );
};
