/**
 * @description The FeedbackForm component.
 */
import React, { useEffect } from "react";
import { T } from "@/libs/ML/lib/components/T";
import { FormInput } from "@shared/components/FormInput";
import { Submit } from "@shared/components/Submit";
import { useFormState } from "@shared/hooks/useFormState";
import { Hidden } from "@shared/components/Hidden";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { ss as sharedSS } from '@/shared/stringSource';
import { ss } from '@/features/singles/stringSource';
import "./CallbackForm.scss";
import { AnimateInView } from "@shared/components/AnimateInView";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";
import { motion } from "framer-motion";
import { useMutation } from 'react-query';
import { postCallback } from "@features/singles/api/FeedbackAPI";

type Props = {}

const validationSchema = yup.object().shape({
  name: yup.string().required(sharedSS.shared.form.validation.required),
  phone: yup.string()
    .required(sharedSS.shared.form.validation.required)
    .min(19, sharedSS.shared.form.validation.phone)
    .max(19, sharedSS.shared.form.validation.phone),
});

interface IFields {
  name: string;
  phone: string;
}

export const CallbackForm: React.FC<Props> = function (props) {
  const { sent, setSent } = useFormState();
  const { isError, isSuccess, isLoading, mutate } = useMutation(async (values: IFields) => {
    return await postCallback(values);
  });
  const { handleSubmit, formState: { errors }, register, reset } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const animateVars = useListAnimationVars({
    itemDelay: 0.7,
  });

  useEffect(() => {
    if (isLoading) {
      setSent(false);
    } else if (isSuccess || isError) {
      setSent(true);
      if (!isError) reset();
    }
  }, [isSuccess, isError, isLoading]);

  const submit = (values: IFields) => {
    mutate(values);
  };

  return (
    <AnimateInView>
      {({ ref, controls }) => (
        <motion.form ref={ref} animate={controls} initial="hidden" variants={animateVars.list} onSubmit={handleSubmit(submit)} className="callback-form">
          <div className="callback-form__cont">

            <motion.h2 variants={animateVars.item} className="callback-form__title">
              <T p={ss.singles.contact.callMe.title} />
            </motion.h2>

            <div className="callback-form__form">
              <div className="callback-form__col">
                <motion.div variants={animateVars.item} className="callback-form__input">
                  <FormInput field="name" disabled={isLoading} error={errors.name?.message} register={register} />
                </motion.div>
              </div>
              <div className="callback-form__col">
                <motion.div variants={animateVars.item} className="callback-form__input">
                  <FormInput field="phone" disabled={isLoading} error={errors.phone?.message} register={register} />
                </motion.div>
              </div>
            </div>

            <motion.div variants={animateVars.item} className="callback-form__submit">
              <Submit disabled={isLoading} submitting={isLoading} />
            </motion.div>

            <Hidden isShow={sent}>
              <div className="callback-form__sent">
                <T p={isError ? sharedSS.shared.form.result.error : sharedSS.shared.form.result.ok} />
              </div>
            </Hidden>

          </div>
        </motion.form>
      )}
    </AnimateInView>
  );
};
