/**
 * @description The PlacementPage component.
 */
import React, { useMemo, useState } from "react";
import { GoogleMap } from "@shared/components/GoogleMap";
import { placementMarkers, typesConf } from "@features/singles/placementMarkers";
import { ABOUT_SECTION, PLACEMENT } from "@/menuConfig";
import { T } from "@libs/ML/lib/components/T";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgPlacement } from "@shared/images/bg/exports";
import { PageLayout } from "@shared/components/PageLayout";
import { TextSlideInView } from "@shared/components/TextSlideInView";
import { ss } from '../stringSource';
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { iconAll1Desktop, iconAll3Desktop } from "@shared/images/placement/icons/exports";
import { imgS1, imgS2, imgS3, imgS4 } from "@shared/images/placement/images/exports";
import { SomeSFInView } from "@shared/components/SomeSFInView";
import { useRem } from "@shared/hooks/useRem";
import { ParallaxedImage } from "@shared/components/ParallaxedImage";
import { AnimateInView } from "@shared/components/AnimateInView";
import { HomeSlider } from "@features/singles/components/HomeSlider";
import { motion } from "framer-motion";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";
import "./PlacementPage.scss";
import { usePreloadImages } from "@shared/hooks/usePreloadImages";
import { CONTACTS_MAP_MARKER } from "@/googleMapConfig";

type Props = {};

export const PlacementPage: React.FC<Props> = function (props) {
  const [filter, setFilter] = useState(typesConf[0].type);
  const [infraCatsOpen, setInfraCatsOpen] = useState(false);
  const isMobile = useIsMobile();
  const { rem } = useRem();
  const listAnimationVariants = useListAnimationVars({
    itemYGap: 4,
    itemDuration: 0.7,
  });

  const markers = useMemo(() => {
    return placementMarkers.filter(el => el.isMain ? true : filter === typesConf[0].type || el.type === filter);
  }, [placementMarkers, filter]);

  const toPreloadImages = useMemo(() => {
    return isMobile ? [
      iconAll1Desktop,
      ...imgS1.mobile,
      ...imgS2.mobile,
    ] : [
      iconAll1Desktop,
      ...imgS1.desktop,
      ...imgS2.desktop,
    ];
  }, [isMobile]);
  const { isLoading } = usePreloadImages(toPreloadImages);

  const plProps = {
    title: <T p={PLACEMENT.labelPath}/>,
    breadcrumbs: ABOUT_SECTION,
    bg: <BackgroundSet conf={bgPlacement} mods={['placement']}/>,
    mods: ['w100'],
    isLoading,
  };

  const renderInfra = () => (
    <div className={makeMods('placement__map-infra', {
      'm-open': infraCatsOpen,
    })}>
      <div className="placement-infra">
        <div className="placement-infra__cont">
          <div className="placement-infra__list">
            {typesConf.map(t => (
              <button
                key={t.type}
                onClick={() => {
                  setFilter(t.type);
                  setInfraCatsOpen(false);
                }}
                className={makeMods('placement-infra__el', {
                  active: filter === t.type,
                  [`type-${t.type}`]: true,
                })}
              >
                {isMobile && t.icon && (
                  <div className="placement-infra__icon">
                    <t.icon/>
                  </div>
                )}
                <span className="placement-infra__label">
                  <T p={t.label}/>
                </span>
              </button>
            ))}
          </div>
        </div>
        {isMobile && (
          <button onClick={() => setInfraCatsOpen(false)} className="placement-infra__close"/>
        )}
      </div>
    </div>
  );

  return (
    <PageLayout {...plProps}>
      <div className="placement">
        <div className="placement__cont">

          <div className="placement__section placement__section--map">
            <div className="placement__text">
              <div className="placement__text-row">
                <TextSlideInView dir="right">
                  <T html p={ss.singles.placement.sections.map.textRow1}/>
                </TextSlideInView>
              </div>
            </div>

            <SomeSFInView delay={1.1} gap={50}>
              <div className="placement__map">
                <div className="placement__map-cont">
                  <GoogleMap
                    center={{
                      lat: 48.46541189686506,
                      lng: 35.06522922584944,
                    }}
                    zoom={15}
                    markers={markers}
                  />
                  {isMobile && (
                    <button onClick={() => setInfraCatsOpen(true)} className="placement__m-map-cats">
                      <T p={ss.singles.placement.infra.btnCats}/>
                    </button>
                  )}
                </div>
                {!isMobile && renderInfra()}
              </div>
            </SomeSFInView>
            {isMobile && renderInfra()}
          </div>

          <div className="placement__section placement__section--1">
            <div className="placement__text">
              <h2 className="placement__title">
                <TextSlideInView>
                  <T html p={ss.singles.placement.sections.s1.title}/>
                </TextSlideInView>
              </h2>
              <div className="placement__text-row">
                <TextSlideInView delay={0.7 + 0.15} dir="right">
                  <T html p={ss.singles.placement.sections.s1.textRow1}/>
                </TextSlideInView>
              </div>
              <div className="placement__icon">
                <SomeSFInView delay={0.7 + 0.4} gap={rem(80)}>
                  <div className="placement__icon-wrap">
                    <img className="placement__icon-img" src={iconAll1Desktop} alt=""/>
                  </div>
                </SomeSFInView>
              </div>
            </div>

            <div className="placement__media">
              {isMobile ? (
                <SomeSFInView>
                  {imgS1.mobile.length > 1 ? (
                    <HomeSlider
                      randomGapFrom={40}
                      randomGapTo={40}
                      slides={imgS1.mobile.map(s => ({ desktop: s }))}
                    />
                  ) : (
                    <ParallaxedImage gap={50} src={imgS1.mobile[0]} propWidth={320} propHeight={445}/>
                  )}
                </SomeSFInView>
              ) : (
                <AnimateInView>{({ controls, ref }) => (
                  <motion.div initial="hidden" animate={controls} ref={ref} variants={listAnimationVariants.list}
                              className="placement__media-images">
                    <motion.div variants={listAnimationVariants.item} className="placement__media-image media-image">
                      <ParallaxedImage gap={50} src={imgS1.desktop[0]} propWidth={1859} propHeight={683}/>
                    </motion.div>
                  </motion.div>
                )}</AnimateInView>
              )}
            </div>
          </div>

          <div className="placement__section placement__section--2">
            <div className="placement__text">
              <div className="placement__text-row">
                <TextSlideInView dir="right">
                  <T html p={ss.singles.placement.sections.s2.textRow1}/>
                </TextSlideInView>
              </div>
            </div>

            <div className="placement__media">
              {isMobile ? (
                <SomeSFInView>
                  {imgS2.mobile.length > 1 ? (
                    <HomeSlider
                      randomGapFrom={40}
                      randomGapTo={40}
                      slides={imgS2.mobile.map(s => ({ desktop: s }))}
                    />
                  ) : (
                    <ParallaxedImage gap={50} src={imgS2.mobile[0]} propWidth={320} propHeight={445}/>
                  )}
                </SomeSFInView>
              ) : (
                <AnimateInView>{({ controls, ref }) => (
                  <motion.div initial="hidden" animate={controls} ref={ref} variants={listAnimationVariants.list}
                              className="placement__media-images">
                    <motion.div variants={listAnimationVariants.item} className="placement__media-image media-image">
                      <ParallaxedImage gap={80} src={imgS2.desktop[0]} propWidth={1000} propHeight={550}/>
                    </motion.div>
                    <motion.div variants={listAnimationVariants.item} className="placement__media-image media-image">
                      <ParallaxedImage gap={40} src={imgS2.desktop[1]} propWidth={863} propHeight={394}/>
                    </motion.div>
                  </motion.div>
                )}</AnimateInView>
              )}
            </div>
          </div>

          <div className="placement__section placement__section--3">
            <div className="placement__text">
              <h2 className="placement__title">
                <TextSlideInView delay={0.7 + 0.15}>
                  <T html p={ss.singles.placement.sections.s3.title}/>
                </TextSlideInView>
              </h2>
              <div className="placement__text-row">
                <TextSlideInView dir="right" delay={0.7 + 0.15 * 2}>
                  <T html p={ss.singles.placement.sections.s3.textRow1}/>
                </TextSlideInView>
              </div>
              <div className="placement__text-row">
                <TextSlideInView dir="right" delay={0.7 + 0.15 * 3}>
                  <T html p={ss.singles.placement.sections.s3.textRow2}/>
                </TextSlideInView>
              </div>
              <div className="placement__icon">
                <SomeSFInView gap={rem(80)} delay={0.7 + 0.4}>
                  <div className="placement__icon-wrap">
                    <img className="placement__icon-img" src={iconAll3Desktop} alt=""/>
                  </div>
                </SomeSFInView>
              </div>
            </div>

            <div className="placement__media">
              {isMobile ? (
                <SomeSFInView>
                  {imgS3.mobile.length > 1 ? (
                    <HomeSlider
                      randomGapFrom={40}
                      randomGapTo={40}
                      slides={imgS3.mobile.map(s => ({ desktop: s }))}
                    />
                  ) : (
                    <ParallaxedImage gap={50} src={imgS3.mobile[0]} propWidth={320} propHeight={445}/>
                  )}
                </SomeSFInView>
              ) : (
                <AnimateInView>{({ controls, ref }) => (
                  <motion.div initial="hidden" animate={controls} ref={ref} variants={listAnimationVariants.list}
                              className="placement__media-images">
                    <motion.div variants={listAnimationVariants.item} className="placement__media-image media-image">
                      <ParallaxedImage gap={0} src={imgS3.desktop[0]} propWidth={450} propHeight={382}/>
                    </motion.div>
                    <motion.div variants={listAnimationVariants.item} className="placement__media-image media-image">
                      <ParallaxedImage gap={0} src={imgS3.desktop[1]} propWidth={450} propHeight={382}/>
                    </motion.div>
                    <motion.div variants={listAnimationVariants.item} className="placement__media-image media-image">
                      <ParallaxedImage gap={0} src={imgS3.desktop[2]} propWidth={1410} propHeight={551}/>
                    </motion.div>
                  </motion.div>
                )}</AnimateInView>
              )}
            </div>
          </div>

          <div className="placement__section placement__section--4">
            <div className="placement__text">
              <div className="placement__text-row">
                <TextSlideInView dir="right">
                  <T html p={ss.singles.placement.sections.s4.textRow1}/>
                </TextSlideInView>
              </div>
            </div>

            <div className="placement__media">
              {isMobile ? (
                <SomeSFInView>
                  {imgS4.mobile.length > 1 ? (
                    <HomeSlider
                      randomGapFrom={40}
                      randomGapTo={40}
                      slides={imgS4.mobile.map(s => ({ desktop: s }))}
                    />
                  ) : (
                    <ParallaxedImage gap={50} src={imgS4.mobile[0]} propWidth={320} propHeight={445}/>
                  )}
                </SomeSFInView>
              ) : (
                <AnimateInView>{({ controls, ref }) => (
                  <motion.div initial="hidden" animate={controls} ref={ref} variants={listAnimationVariants.list}
                              className="placement__media-images">
                    <motion.div variants={listAnimationVariants.item} className="placement__media-image media-image">
                      <ParallaxedImage gap={80} src={imgS4.desktop[0]} propWidth={998} propHeight={550}/>
                    </motion.div>
                    <motion.div variants={listAnimationVariants.item} className="placement__media-image media-image">
                      <ParallaxedImage gap={40} src={imgS4.desktop[1]} propWidth={799} propHeight={747}/>
                    </motion.div>
                  </motion.div>
                )}</AnimateInView>
              )}
            </div>
          </div>

        </div>
      </div>
    </PageLayout>
  );
};
