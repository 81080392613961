/**
 * @description The Link component.
 */
import React from "react";
import { LinkProps, Link as _Link } from "react-router-dom";

import { useLocale } from "@libs/ML/lib/hooks/useLocale";

type Props = {
  [p: string]: any;
} & LinkProps;

export const Link: React.FC<Props> = function ({ to, ...props }) {
  let locale = useLocale();

  return (
    <_Link to={`/${locale}${to}`} {...props} />
  );
};
