/**
 * @description The Compass component.
 */
import React from "react";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/features/flats/stringSource';
import { ReactComponent as BgIcon } from "./i/bg.svg";
import "./Compass.scss";

type Props = {}

export const Compass: React.FC<Props> = function (props) {
  return (
    <div className="compass">
      <div className="compass__cont">
        <BgIcon />
        <div className="compass__letter">
          <T p={ss.flats.compass.north} />
        </div>
      </div>
    </div>
  );
};
