import { useState, useEffect, useMemo, useCallback } from 'react';

function getRootFontSize() {
  const html = document.querySelector("html");
  return window.getComputedStyle(html as HTMLHtmlElement).fontSize;
}

export function useRem(rootFZ = 20) {
  const [fz, setFZ] = useState(getRootFontSize());

  useEffect(() => {
    const handle = () => {
      setFZ(getRootFontSize());
    };
    window.addEventListener('resize', handle);
    return () => window.removeEventListener('resize', handle);
  }, []);

  const currentFzInPx = useMemo(() => {
    return parseFloat(fz);
  }, [fz]);

  const currentRatio = useMemo(() => {
    return currentFzInPx / rootFZ;
  }, [currentFzInPx]);

  const rem = useCallback((targetFZ: number, useRound: boolean = false) => {
    let result = targetFZ * currentRatio;
    if (useRound) result = Math.round(result);
    return result;
  }, [currentRatio]);

  return {
    rem,
    ratio: currentRatio,
    rootFZ: currentFzInPx,
  };
}
