/**
 * @description The TextSlideAnimation component.
 */
import React, { Ref, useMemo } from "react";
import { AnimationControls, motion } from "framer-motion";
import "./TextSlideAnimation.scss";

export type SharedProps = {
  dir?: "up" | "down" | "left" | "right";
  speed?: number;
  delay?: number;
};

type Props = {
  animationControl?: AnimationControls;
  rootRef?: Ref<HTMLDivElement>;
} & SharedProps;

export const TextSlideAnimation: React.FC<Props> = function (props) {
  const { children, dir = "up", speed = 1, delay = 0, animationControl, rootRef } = props;

  const animation = useMemo(() => {
    let x1 = '0%',
      x2 = '0%',
      y1 = '100%',
      y2 = '0%';

    if (dir === "down") {
      y1 = '-100%';
    } else if (dir === 'right') {
      y1 = '0%';
      x1 = '-100%';
    } else if (dir === 'left') {
      x1 = '100%';
    }

    return {
      initial: 'hidden',
      animate: animationControl || 'visible',
      variants: {
        hidden: { opacity: 0, y: y1, x: x1, z: 0 },
        visible: { opacity: 1, y: y2, x: x2, z: 0 },
      },
      transition: { duration: speed, delay: delay },
    };
  }, [children, dir, speed, delay, animationControl]);

  let rootProps = {
    ref: rootRef,
  };

  return (
    <div {...rootProps} className="ts-animation">
      <motion.div className="ts-animation__cont" {...animation}>{typeof children === "string" ? (
        <span>{children}</span>
      ) : children}</motion.div>
    </div>
  );
};
