import { Config } from "@libs/RRH/lib/route";
import { featureConf } from "./config";

const NAMESPACE = featureConf.name;

export const keys = {
  home: `${NAMESPACE}/home`,
  chars: `${NAMESPACE}/chars`,
  contact: `${NAMESPACE}/contact`,
  placement: `${NAMESPACE}/placement`,
  tour: `${NAMESPACE}/tour`,
  tourView: `${NAMESPACE}/tourView`,
  how: `${NAMESPACE}/how`,
  info: `${NAMESPACE}/info`,
};

export const routes = [
  new Config({
    id: keys.home,
    path: "/",
    mods: ["home"],
  }),
  new Config({
    id: keys.chars,
    path: "/characteristics",
  }),
  new Config({
    id: keys.contact,
    path: "/contact",
  }),
  new Config({
    id: keys.placement,
    path: "/placement",
  }),
  new Config({
    id: keys.tour,
    path: "/tour",
  }),
  new Config({
    id: keys.tourView,
    path: "/tour-view",
  }),
  new Config({
    id: keys.how,
    path: "/how-to-buy",
  }),
  new Config({
    id: keys.info,
    path: "/info",
  }),
];
