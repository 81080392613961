import { useCallback, useEffect, useState } from "react";
import { useFlatsData } from "@features/flats/hooks/useFlatsData";
import { useGoToWithLocale } from "@libs/ML/lib/hooks/useGoToWithLocale";
import { useFloorFlatParams } from "@features/flats/hooks/useFloorFlatParams";

export interface IUseWrongFlatRedirectOpts {
  to?: string;
  checkOnlyIfNotNull?: boolean;
}

export function useWrongFlatRedirect(
  {
    to = '/apartments/',
    checkOnlyIfNotNull = false,
  }: IUseWrongFlatRedirectOpts = {},
) {
  const [canRender, setCanRender] = useState(false);
  const goTo = useGoToWithLocale();
  const redirect = useCallback(() => {
    return goTo(to);
  }, [goTo, to]);
  const params = useFloorFlatParams();
  const { data: flatsData, isLoading } = useFlatsData();

  useEffect(() => {
    if (checkOnlyIfNotNull) {
      if (params.flatID === null) {
        setCanRender(true);
        return;
      }
    }

    if (params.flatID === null) return redirect();
    if (flatsData && !isLoading) {
      let flat = flatsData.getFlatByID(params.flatID);
      if (flat === undefined) return redirect();
      if (!flat.isAvailable) return redirect();
    }

    setCanRender(true);
  }, [params, flatsData, isLoading, redirect]);

  return canRender;
}
