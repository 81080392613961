import bg1 from './bg/1.png';
import bg_1 from './bg/-1.png';
import bg_2 from './bg/-2.png';
import bg_3 from './bg/-3.png';
import bg_4 from './bg/-4.png';
import bg2 from './bg/2.png';
import bg3 from './bg/3.png';
import bg18 from './bg/18.png';
import bg17 from './bg/17.png';
import bg26 from './bg/26.png';
import bg27 from './bg/27.png';
import bg28 from './bg/28.png';
import bg29 from './bg/29.png';
import bg30 from './bg/30.png';
import bg31 from './bg/31.png';
import bg32 from './bg/32.png';
import json2 from './json/2.json';
import json3 from './json/3.json';
import json17 from './json/17.json';
import json18 from './json/18.json';
import json26 from './json/26.json';
import json27 from './json/27.json';
import json28 from './json/28.json';
import json29 from './json/29.json';
import json30 from './json/30.json';
import json31 from './json/31.json';
import json32 from './json/32.json';
import json1 from './json/1.json';
import json_1 from './json/-1.json';
import json_2 from './json/-2.json';
import json_3 from './json/-3.json';
import json_4 from './json/-4.json';

(json1 as IFloorConf).bg.url = bg1;
(json1 as IFloorConf).ignoreAvailable = true;
(json_1 as IFloorConf).bg.url = bg_1;
(json_1 as IFloorConf).ignoreAvailable = true;
(json_1 as IFloorConf).mods = ['extended'];
(json_2 as IFloorConf).bg.url = bg_2;
(json_2 as IFloorConf).ignoreAvailable = true;
(json_2 as IFloorConf).mods = ['extended'];
(json_3 as IFloorConf).bg.url = bg_3;
(json_3 as IFloorConf).ignoreAvailable = true;
(json_3 as IFloorConf).mods = ['extended'];
(json_4 as IFloorConf).bg.url = bg_4;
(json_4 as IFloorConf).ignoreAvailable = true;
(json_4 as IFloorConf).mods = ['extended'];
(json2 as IFloorConf).bg.url = bg2;
(json3 as IFloorConf).bg.url = bg3;
(json17 as IFloorConf).bg.url = bg17;
(json18 as IFloorConf).bg.url = bg18;
(json26 as IFloorConf).bg.url = bg26;
(json27 as IFloorConf).bg.url = bg27;
(json28 as IFloorConf).bg.url = bg28;
(json29 as IFloorConf).bg.url = bg29;
(json30 as IFloorConf).bg.url = bg30;
(json31 as IFloorConf).bg.url = bg31;
(json32 as IFloorConf).bg.url = bg32;

// fix text translating
(json17 as IFloorConf).flats = (json17 as IFloorConf).flats.map(f => ({
  ...f,
  label2Center: {
    ...f.label2Center,
    cy: (f.label2Center.cy || 0) + 5
  }
}));

export interface ILabelBox {
  width: number;
  height: number;
  x: number | null;
  y: number | null;
  cx: number | null;
  cy: number | null;
}

export interface IFlatConf {
  type: string;
  clickable?: boolean;
  path: string | null;
  polygon: string | null;
  fill: string;
  label1Center: ILabelBox;
  label2Center: ILabelBox;
}

export interface IFloorConf {
  svg: {
    width: number;
    height: number;
  };
  bg: {
    width: number;
    height: number;
    transform: string;
    url?: string | null;
  };
  flats: IFlatConf[];
  ignoreAvailable?: boolean;
  mods?: string[];
}

export const floorsConf: {
  [key: number]: IFloorConf;
} = {
  1: json1,
  '-1': json_1,
  '-2': json_2,
  '-3': json_3,
  '-4': json_4,

  2: json2,
  4: json2,
  6: json2,
  8: json2,
  10: json2,
  12: json2,
  14: json2,

  3: json3,
  5: json3,
  7: json3,
  9: json3,
  11: json3,
  13: json3,
  15: json3,

  16: json18,
  18: json18,
  20: json18,
  22: json18,
  24: json18,

  17: json17,
  19: json17,
  21: json17,
  23: json17,
  25: json17,

  26: json26,
  27: json27,
  28: json28,
  29: json29,
  30: json30,
  31: json31,
  32: json32,
};

export function getFlatTypesList() {
  return Object.keys(floorsConf).reduce((acc: string[], floorNumber) => {
    let floor = floorsConf[(floorNumber as unknown as number)];
    acc = [...acc, ...floor.flats.map(f => f.type)];
    return acc;
  }, []);
}
