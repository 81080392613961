/**
 * @description The PageLayout component.
 */
import React, { Fragment, ReactNode, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion"
import { Mods } from "@shared/props";
import mm from "@libs/YandexBEM/lib/mm";
import mergeMods from "@libs/YandexBEM/lib/mergeMods";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { PageLoader, pageLoaderAnimation } from "@shared/components/PageLoader";
import { PageLoadingError } from "@shared/components/PageLoadingError";
import { IMenuSection } from "@/menuConfig";
import { Breadcrumbs } from "@shared/components/Breadcrumbs";
import "./PageLayout.scss";
import { emitRecheckScrollState } from "@shared/hooks/useBodyScroll";
import { emitLoadingDone } from "@shared/events/loading";

type Props = {
  title?: ReactNode;
  children?: ReactNode;
  bg?: ReactNode;
  mods?: Mods;
  useLoader?: boolean;
  isLoading?: boolean;
  error?: string;
  errorText?: string | ReactNode;
  breadcrumbs?: IMenuSection;
}

const titleAnimation = {
  initial: 'hidden',
  animate: 'visible',
  variants: {
    hidden: { opacity: 0, y: '10%' },
    visible: { opacity: 1, y: '0%' },
  },
  transition: { duration: 0.55, delay: 0.12 },
};

const bgAnimation = {
  initial: 'hidden',
  animate: 'visible',
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  },
  transition: { duration: 0.55 },
};

const breadcrumbsAnimation = {
  ...bgAnimation,
  transition: {
    duration: 0.3,
  }
};

const loaderAnimation = {
  ...pageLoaderAnimation,
};

export const PageLayout: React.FC<Props> = function (props) {
  const {
    title = null,
    children = null,
    bg = null,
    mods = [],
    useLoader = true,
    isLoading = false,
    error = null,
    errorText = undefined,
    breadcrumbs = undefined,
  } = props;

  const isMobile = useIsMobile();
  const renderBg = !!bg;
  const modsToUse = mergeMods(mods, {
    error: !!error,
    loading: isLoading,
    "use-loader": useLoader,
    "has-bg": renderBg,
    "has-bg-desktop": !!bg,
    "has-title": title !== null,
    "has-breadcrumbs": !!breadcrumbs,
  });

  useEffect(() => {
    emitRecheckScrollState();
  }, [isLoading, error, children, isMobile]);

  useEffect(() => {
    if (!isLoading) emitLoadingDone();
  }, [isLoading]);

  const renderPage = () => {
    return (
      <div key="page" className="pl__page">
        {error ? (
          <div className="pl__error">
            <PageLoadingError>{errorText}</PageLoadingError>
          </div>
        ) : (
          <Fragment>
            {title && (
              <motion.h1 {...titleAnimation} className="pl__title">{title}</motion.h1>
            )}
            <div className="pl__page-content">{typeof children === "function" ? children() : children}</div>
          </Fragment>
        )}
      </div>
    );
  };

  return (
    <div className={mm("pl", modsToUse)}>
      <div className="pl__cont">

        {renderBg && (
          <motion.div {...bgAnimation} className="pl__bg">
            {bg}
          </motion.div>
        )}

        <div className="pl__fg">
          <div className="pl__fg-cont">
            {breadcrumbs && (
              <motion.div className="pl__breadcrumbs">
                <Breadcrumbs section={breadcrumbs}/>
              </motion.div>
            )}

            <AnimatePresence>
              {useLoader ? isLoading ? (
                <motion.div
                  key="loader"
                  variants={loaderAnimation}
                  initial="initial"
                  animate="show"
                  exit="exit"
                  className="pl__loader"
                >
                  <PageLoader/>
                </motion.div>
              ) : renderPage() : renderPage()}
            </AnimatePresence>
          </div>
        </div>

      </div>
    </div>
  );
};
