import { useMemo } from 'react';
import { useLocale } from "@libs/ML/lib/hooks/useLocale";
import { useCurrentPage } from "@shared/hooks/useCurrentPage";
import rrh from "@/services/rrh";

export function useLangSwitcher() {
  let locale = useLocale(),
    { page, matches } = useCurrentPage();

  return useMemo(() => {
    try {
      return {
        ru: page ? rrh.generatePath(page.path, { ...matches, locale: 'ru' }) : '',
        en: page ? rrh.generatePath(page.path, { ...matches, locale: 'en' }) : '',
        uk: page ? rrh.generatePath(page.path, { ...matches, locale: 'uk' }) : '',
      };
    } catch (e) {
      return {
        ru: '',
        en: '',
        uk: '',
      };
    }
  }, [page?.path, locale]);
}
