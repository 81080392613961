/**
 * @description The FormInput component.
 */
import React from "react";
import { UseFormRegister } from "react-hook-form";
import { ReactComponent as IconEmail } from "./i/email.svg";
import { ReactComponent as IconPhone } from "./i/phone.svg";
import { ReactComponent as IconName } from "./i/name.svg";
import { ReactComponent as IconMessage } from "./i/message.svg";
import { T } from "@/libs/ML/lib/components/T";
import { useString } from "@libs/ML/lib/hooks/useString";
import { IMaskInput } from 'react-imask';
import { ss as sharedSS } from '@/shared/stringSource';
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { Mods } from "@shared/props";
import "./FormInput.scss";

const fieldToIconMap = {
  name: IconName,
  email: IconEmail,
  phone: IconPhone,
  message: IconMessage,
};

const fieldToPlaceholderMap = {
  name: sharedSS.shared.form.field.name,
  email: sharedSS.shared.form.field.email,
  phone: sharedSS.shared.form.field.phone,
  message: sharedSS.shared.form.field.message,
};

type Props = {
  field: 'name' | 'phone' | 'email' | 'message';
  disabled?: boolean;
  error?: string;
  register: UseFormRegister<any>;
  mods?: Mods;
};

export const FormInput: React.FC<Props> = function (props) {
  const { field, disabled = false, error = null, register, mods = [] } = props;
  const Icon = fieldToIconMap[field] || IconName;
  const placeholder = useString(fieldToPlaceholderMap[field] || '');
  const inputProps = register(field);
  const errorString = useString(error || '');

  const renderTextarea = () => {
    return (
      <div className="form-input__textarea-cont">
        <div className="form-input__textarea-label">
          <div className="form-input__icon">
            <Icon />
          </div>
          <T p={placeholder} />
        </div>
        <textarea disabled={disabled} className="form-input__textarea" {...inputProps} />
      </div>
    );
  };

  const renderInput = () => {
    if (field === "message") {
      return renderTextarea();
    }

    return (
      <div className="form-input__input-cont">
        <div className="form-input__icon">
          <Icon />
        </div>
        {field === 'phone' ? (
          <IMaskInput
            disabled={disabled}
            unmask={true}
            mask={'{+38} (\\000) 000-00-00'}
            className="form-input__input"
            placeholder={placeholder}
            inputMode="tel"
            inputRef={inputProps.ref}
            name={inputProps.name}
            onBlur={inputProps.onBlur}
            onChange={inputProps.onChange}
          />
        ) : (
          <input
            disabled={disabled}
            className="form-input__input"
            placeholder={placeholder}
            type={field === 'email' ? 'email' : 'text'}
            {...inputProps}/>
        )}
      </div>
    );
  };

  return (
    <div className={makeMods('form-input', mods)}>
      <div className="form-input__cont">

        <div className="form-input__input-wrap">
          {renderInput()}
        </div>

        {error && (
          <div className="form-input__error">
            {errorString}
          </div>
        )}

      </div>
    </div>
  );
};
