/**
 * @description The HomePage component.
 */
import React, { ReactElement } from "react";
import { Home } from "@features/singles/components/Home";

type Props = {}

export const HomePage = function(props: Props): ReactElement {
  return (
    <Home />
  );
};
