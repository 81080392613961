import { useCallback, useEffect, useState } from "react";
import { useFlatsData } from "@features/flats/hooks/useFlatsData";
import { useGoToWithLocale } from "@libs/ML/lib/hooks/useGoToWithLocale";
import { useFloorFlatParams } from "@features/flats/hooks/useFloorFlatParams";

export interface IUseWrongFloorRedirectOpts {
  to?: string;
}

export function useWrongFloorRedirect(
  {
    to = '/apartments/'
  }: IUseWrongFloorRedirectOpts = {},
) {
  const [canRender, setCanRender] = useState(false);
  const goTo = useGoToWithLocale();
  const redirect = useCallback(() => {
    return goTo(to);
  }, [goTo, to]);
  const params = useFloorFlatParams();
  const { data: flatsData, isLoading } = useFlatsData();

  useEffect(() => {
    if (params.floorNumber === null) return redirect();

    if (flatsData && !isLoading) {
      if (!flatsData.isFloorAvailable(params.floorNumber)) return redirect();
    }

    setCanRender(true);
  }, [params, flatsData, isLoading, redirect]);

  return canRender;
}
