import { useState, useEffect, useMemo } from 'react';

export interface IUseFormStateOptions {
  delay?: number;
}

const defaultOptions = {
  delay: 5000,
};

export function useFormState(options?: IUseFormStateOptions) {
  const opts = useMemo(() => {
    return {
      ...defaultOptions,
      ...(options ? options : {}),
    }
  }, [options]);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (sent) {
      const timeout = setTimeout(() => {
        setSent(false);
      }, opts.delay);
      return () => clearTimeout(timeout);
    }
  }, [sent, opts]);

  return {
    sent,
    setSent,
  }
}
