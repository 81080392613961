/**
 * @description The Filters component.
 */
import React from "react";
import { SearchState } from "@features/flats/hooks/useSearchState";
import { FlatManager } from "@features/flats/services/flatManager";
import { RangeSliderInput } from "@shared/components/RangeSliderInput";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/features/flats/stringSource';
import "./Filters.scss";

type Props = {
  bedrooms: SearchState['bedrooms'],
  setBedrooms: SearchState['setBedrooms'],
  areaFrom: SearchState['areaFrom'],
  areaTo: SearchState['areaTo'],
  setArea: SearchState['setArea'],
  floorFrom: SearchState['floorFrom'],
  floorTo: SearchState['floorTo'],
  setFloor: SearchState['setFloor'],
  areaBorders: ReturnType<typeof FlatManager.prototype.getAreaBorders>,
  isShowAreaFilter: boolean,
  floorsBorders: ReturnType<typeof FlatManager.prototype.getFloorsBorders>,
  isShowFloorsFilter: boolean,
  bedroomsOptions: ReturnType<typeof FlatManager.prototype.getBedroomsOptions>,
  isShowBedroomsFilter: boolean,
};

export const Filters: React.FC<Props> = function (props) {
  const p = props;

  return (
    <div className="search-filters">
      <div className="search-filters__cont">

        <div className="search-filters__row">
          <div className="search-filters__label">
            <T p={ss.flats.search.filters.bedrooms} />
          </div>
          <div className="search-filters__inputs">
            <div className="search-filters__radio-group">
              {p.bedroomsOptions.map(o => (
                <button
                  key={o}
                  onClick={() => p.setBedrooms(o)}
                  className={`search-filters__radio ${p.bedrooms === o ? 'active' : ''}`}
                >{o}</button>
              ))}
            </div>
          </div>
        </div>

        <div className="search-filters__row">
          <div className="search-filters__label">
            <T p={ss.flats.search.filters.floor} />
          </div>
          <div className="search-filters__inputs">
            <RangeSliderInput
              min={p.floorsBorders[0]}
              max={p.floorsBorders[1]}
              valueFrom={p.floorFrom}
              valueTo={p.floorTo}
              onChange={p.setFloor}
            />
          </div>
        </div>

        <div className="search-filters__row">
          <div className="search-filters__label">
            <T p={ss.flats.search.filters.area} />
          </div>
          <div className="search-filters__inputs">
            <RangeSliderInput
              min={p.areaBorders[0]}
              max={p.areaBorders[1]}
              valueFrom={p.areaFrom}
              valueTo={p.areaTo}
              onChange={p.setArea}
            />
          </div>
        </div>

      </div>
    </div>
  );
};
