import _bg4041Desktop from "./404/1.jpg";
import _bg4041Mobile from "./404/1m.png";
import _bgCameras1Desktop from "./cameras/1.jpg";
import _bgCameras1Mobile from "./cameras/1m.png";
import _bgChars1Desktop from "./chars/1.jpg";
import _bgChars2Desktop from "./chars/2.jpg";
import _bgChars3Desktop from "./chars/3.jpg";
import _bgChars4Desktop from "./chars/4.jpg";
import _bgChars5Desktop from "./chars/5.jpg";
import _bgChars6Desktop from "./chars/6.jpg";
import _bgChars7Desktop from "./chars/7.jpg";
import _bgChars8Desktop from "./chars/8.jpg";
import _bgChars9Desktop from "./chars/9.jpg";
import _bgChars10Desktop from "./chars/10.jpg";
import _bgChars11Desktop from "./chars/11.jpg";
import _bgChars1Mobile from "./chars/1m.png";
import _bgChars2Mobile from "./chars/2m.png";
import _bgChars3Mobile from "./chars/3m.png";
import _bgChars4Mobile from "./chars/4m.png";
import _bgChars5Mobile from "./chars/5m.png";
import _bgChars6Mobile from "./chars/6m.png";
import _bgChars7Mobile from "./chars/7m.png";
import _bgChars8Mobile from "./chars/8m.png";
import _bgChars9Mobile from "./chars/9m.png";
import _bgChars10Mobile from "./chars/10m.png";
import _bgChars11Mobile from "./chars/11m.png";
import _bgChars12Mobile from "./chars/12m.png";
import _bgConstruction1Desktop from "./construction/1.jpg";
import _bgConstruction1Mobile from "./construction/1m.png";
import _bgContact1Desktop from "./contact/1.jpg";
import _bgContact1Mobile from "./contact/1m.png";
import _bgDocs1Desktop from "./docs/1.jpg";
import _bgDocs1Mobile from "./docs/1m.png";
import _bgGallery1Desktop from "./gallery/1.jpg";
import _bgHome1Desktop from "./home/1.jpg";
import _bgHome2Desktop from "./home/2.jpg";
import _bgHome3Desktop from "./home/3.jpg";
import _bgHome4Desktop from "./home/4.jpg";
import _bgHome5Desktop from "./home/5.jpg";
import _bgHome1Mobile from "./home/1m.png";
import _bgHome2Mobile from "./home/2m.png";
import _bgHome3Mobile from "./home/3m.png";
import _bgHome4Mobile from "./home/4m.png";
import _bgHome5Mobile from "./home/5m.png";
import _bgHow1Desktop from "./how/1.jpg";
import _bgHow1Mobile from "./how/1m.png";
import _bgInfo1Desktop from "./info/1.jpg";
import _bgInfo1Mobile from "./info/1m.png";
import _bgMenu1Desktop from "./menu/1.jpg";
import _bgMenu1Mobile from "./menu/1m.jpg";
import _bgNews1Desktop from "./news/1.jpg";
import _bgNews1Mobile from "./news/1m.png";
import _bgPlacement1Desktop from "./placement/1.jpg";
import _bgPlacement2Desktop from "./placement/2.jpg";
import _bgPlacement3Desktop from "./placement/3.jpg";
import _bgPlacement4Desktop from "./placement/4.jpg";
import _bgPlacement1Mobile from "./placement/1m.png";
import _bgSearch1Desktop from "./search/1.jpg";
import _bgTeam1Desktop from "./team/1.jpg";
import _bgTeam1Mobile from "./team/1m.png";


export const bg4041Desktop = _bg4041Desktop;
export const bg4041Mobile = _bg4041Mobile;
export const bg404 = { desktop: [_bg4041Desktop], mobile: [_bg4041Mobile], };

export const bgCameras1Desktop = _bgCameras1Desktop;
export const bgCameras1Mobile = _bgCameras1Mobile;
export const bgCameras = { desktop: [_bgCameras1Desktop], mobile: [_bgCameras1Mobile], };

export const bgChars1Desktop = _bgChars1Desktop;
export const bgChars2Desktop = _bgChars2Desktop;
export const bgChars3Desktop = _bgChars3Desktop;
export const bgChars4Desktop = _bgChars4Desktop;
export const bgChars5Desktop = _bgChars5Desktop;
export const bgChars6Desktop = _bgChars6Desktop;
export const bgChars7Desktop = _bgChars7Desktop;
export const bgChars8Desktop = _bgChars8Desktop;
export const bgChars9Desktop = _bgChars9Desktop;
export const bgChars10Desktop = _bgChars10Desktop;
export const bgChars11Desktop = _bgChars11Desktop;
export const bgChars1Mobile = _bgChars1Mobile;
export const bgChars2Mobile = _bgChars2Mobile;
export const bgChars3Mobile = _bgChars3Mobile;
export const bgChars4Mobile = _bgChars4Mobile;
export const bgChars5Mobile = _bgChars5Mobile;
export const bgChars6Mobile = _bgChars6Mobile;
export const bgChars7Mobile = _bgChars7Mobile;
export const bgChars8Mobile = _bgChars8Mobile;
export const bgChars9Mobile = _bgChars9Mobile;
export const bgChars10Mobile = _bgChars10Mobile;
export const bgChars11Mobile = _bgChars11Mobile;
export const bgChars12Mobile = _bgChars12Mobile;
export const bgChars = { desktop: [_bgChars1Desktop, _bgChars2Desktop, _bgChars3Desktop, _bgChars4Desktop, _bgChars5Desktop, _bgChars6Desktop, _bgChars7Desktop, _bgChars8Desktop, _bgChars9Desktop, _bgChars10Desktop, _bgChars11Desktop], mobile: [_bgChars1Mobile, _bgChars2Mobile, _bgChars3Mobile, _bgChars4Mobile, _bgChars5Mobile, _bgChars6Mobile, _bgChars7Mobile, _bgChars8Mobile, _bgChars9Mobile, _bgChars10Mobile, _bgChars11Mobile, _bgChars12Mobile], };

export const bgConstruction1Desktop = _bgConstruction1Desktop;
export const bgConstruction1Mobile = _bgConstruction1Mobile;
export const bgConstruction = { desktop: [_bgConstruction1Desktop], mobile: [_bgConstruction1Mobile], };

export const bgContact1Desktop = _bgContact1Desktop;
export const bgContact1Mobile = _bgContact1Mobile;
export const bgContact = { desktop: [_bgContact1Desktop], mobile: [_bgContact1Mobile], };

export const bgDocs1Desktop = _bgDocs1Desktop;
export const bgDocs1Mobile = _bgDocs1Mobile;
export const bgDocs = { desktop: [_bgDocs1Desktop], mobile: [_bgDocs1Mobile], };

export const bgGallery1Desktop = _bgGallery1Desktop;
export const bgGallery = { desktop: [_bgGallery1Desktop], mobile: [], };

export const bgHome1Desktop = _bgHome1Desktop;
export const bgHome2Desktop = _bgHome2Desktop;
export const bgHome3Desktop = _bgHome3Desktop;
export const bgHome4Desktop = _bgHome4Desktop;
export const bgHome5Desktop = _bgHome5Desktop;
export const bgHome1Mobile = _bgHome1Mobile;
export const bgHome2Mobile = _bgHome2Mobile;
export const bgHome3Mobile = _bgHome3Mobile;
export const bgHome4Mobile = _bgHome4Mobile;
export const bgHome5Mobile = _bgHome5Mobile;
export const bgHome = { desktop: [_bgHome1Desktop, _bgHome2Desktop, _bgHome3Desktop, _bgHome4Desktop, _bgHome5Desktop], mobile: [_bgHome1Mobile, _bgHome2Mobile, _bgHome3Mobile, _bgHome4Mobile, _bgHome5Mobile], };

export const bgHow1Desktop = _bgHow1Desktop;
export const bgHow1Mobile = _bgHow1Mobile;
export const bgHow = { desktop: [_bgHow1Desktop], mobile: [_bgHow1Mobile], };

export const bgInfo1Desktop = _bgInfo1Desktop;
export const bgInfo1Mobile = _bgInfo1Mobile;
export const bgInfo = { desktop: [_bgInfo1Desktop], mobile: [_bgInfo1Mobile], };

export const bgMenu1Desktop = _bgMenu1Desktop;
export const bgMenu1Mobile = _bgMenu1Mobile;
export const bgMenu = { desktop: [_bgMenu1Desktop], mobile: [_bgMenu1Mobile], };

export const bgNews1Desktop = _bgNews1Desktop;
export const bgNews1Mobile = _bgNews1Mobile;
export const bgNews = { desktop: [_bgNews1Desktop], mobile: [_bgNews1Mobile], };

export const bgPlacement1Desktop = _bgPlacement1Desktop;
export const bgPlacement2Desktop = _bgPlacement2Desktop;
export const bgPlacement3Desktop = _bgPlacement3Desktop;
export const bgPlacement4Desktop = _bgPlacement4Desktop;
export const bgPlacement1Mobile = _bgPlacement1Mobile;
export const bgPlacement = { desktop: [_bgPlacement1Desktop, _bgPlacement2Desktop, _bgPlacement3Desktop, _bgPlacement4Desktop], mobile: [_bgPlacement1Mobile], };

export const bgSearch1Desktop = _bgSearch1Desktop;
export const bgSearch = { desktop: [_bgSearch1Desktop], mobile: [], };

export const bgTeam1Desktop = _bgTeam1Desktop;
export const bgTeam1Mobile = _bgTeam1Mobile;
export const bgTeam = { desktop: [_bgTeam1Desktop], mobile: [_bgTeam1Mobile], };


