/**
 * @description The Cards component.
 */
import React from "react";
import { IFlat } from "@features/flats/services/flatManager";
import { FlatAttr } from "@features/flats/components/Search/FlatAttr";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/features/flats/stringSource';
import { ss as sharedSS } from '@shared/stringSource';
import "./Cards.scss";

type Props = {
  flats: IFlat[],
  onGoToFlat: (flat: IFlat) => any,
};

export const Cards: React.FC<Props> = function (props) {
  const { flats, onGoToFlat } = props;

  return (
    <div className="search-cards">
      <div className="search-cards__cont">

        {flats.map(f => (
          <div onClick={() => onGoToFlat(f)} key={f.id} className="search-cards-el">
            <div className="search-cards-el__cont">

              <div className="search-cards-el__image">
                <img src={f.urlPlanSVG} alt="" className="search-cards-el__img"/>
              </div>

              <div className="search-cards-el__props">
                <div className="search-cards-el__row">
                  <span className="bigger"><FlatAttr flat={f} type="area" /> </span>
                  <T p={sharedSS.shared.areaLetter} />
                </div>
                <div className="search-cards-el__row">
                  <T p={ss.flats.search.values.rooms} params={{ rooms: f.bedrooms }} /> / <T p={ss.flats.search.values.floor} params={{ floor: f.floorNumber }} />
                </div>
                <div className="search-cards-el__row">
                  <T p={ss.flats.search.values.type} params={{ type: f.planCode }} />
                </div>
                {!f.isHidePrice && (
                  <div className="search-cards-el__row">
                    <FlatAttr flat={f} type="priceWithCurrency" />
                  </div>
                )}
              </div>

            </div>
          </div>
        ))}

      </div>
    </div>
  );
};
