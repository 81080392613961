/**
 * @description The DateView component.
 */
import React, { useMemo } from "react";
import format from 'date-fns/format';

import { dateConf } from "@/dateConfig";
import { T } from "@/libs/ML/lib/components/T";
import { useLocale } from "@libs/ML/lib/hooks/useLocale";
import { ss } from "@/shared/stringSource";

type Props = {
  date: Date | string;
  format?: "simple" | "split";
}

export const DateView: React.FC<Props> = function (props) {
  const locale = useLocale();
  const { date, format: f = 'split' } = props;

  const dateToUse: Date = useMemo(() => {
    return date instanceof Date ? date : new Date(date);
  }, [date]);

  const formatted = useMemo(() => {
    const options = {
      locale: locale !== null ? dateConf.i18nMap[locale]: undefined
    };
    const day = format(dateToUse, "dd"),
      month = format(dateToUse, "MM"),
      monthName = format(dateToUse, "MMMM", options),
      year = format(dateToUse, "yyyy");

    return {
      day,
      month,
      monthName,
      year,
    };
  }, [dateToUse, locale]);

  let toRender: React.ReactNode = f === "split" ?
    <T p={ss.shared.dateFormat.default} params={formatted} /> : `${formatted.day}.${formatted.month}.${formatted.year}`;
  if (locale === "en") toRender = `${formatted.month}/${formatted.day}/${formatted.year}`;

  return (
    <div className="date-view" style={{whiteSpace: "pre"}}>{toRender}</div>
  );
};
