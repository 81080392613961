import { routesToKeyMap } from "@/routes";
import { ss } from "@shared/stringSource";
import { keys as posts } from '@features/posts/routes';
import { keys as flats } from '@features/flats/routes';
import { keys as singles } from '@features/singles/routes';

export interface IMenuItem {
  path?: string;
  labelPath: string;
  menuExact?: boolean;
  breadcrumbsExact?: boolean;
  disableActiveClass?: boolean;
  ignoreInBreadcrumbs?: boolean;
}

export interface IMenuSection {
  heading?: IMenuItem,
  items: IMenuItem[],
}

/**
 * Items
 */
export const ABOUT_HEADING: IMenuItem = {
  path: routesToKeyMap[singles.home],
  labelPath: ss.shared.menu.about,
  menuExact: true,
  breadcrumbsExact: true,
};
export const PLACEMENT: IMenuItem = {
  path: routesToKeyMap[singles.placement],
  labelPath: ss.shared.menu.placement,
  menuExact: true,
  breadcrumbsExact: true,
};
export const HOME: IMenuItem = {
  path: routesToKeyMap[singles.home],
  labelPath: ss.shared.menu.home,
  menuExact: true,
  breadcrumbsExact: true,
};
export const GALLERY: IMenuItem = {
  path: routesToKeyMap[posts.galleryList],
  labelPath: ss.shared.menu.gallery,
  menuExact: true,
  breadcrumbsExact: true,
};
export const TEAM: IMenuItem = {
  path: routesToKeyMap[posts.teamList],
  labelPath: ss.shared.menu.team,
  menuExact: true,
  breadcrumbsExact: true,
};
export const DOCUMENTS: IMenuItem = {
  path: routesToKeyMap[posts.documentsList],
  labelPath: ss.shared.menu.docs,
  menuExact: true,
  breadcrumbsExact: true,
};
export const CHARS: IMenuItem = {
  path: routesToKeyMap[singles.chars],
  labelPath: ss.shared.menu.chars,
  menuExact: true,
  breadcrumbsExact: true,
};
export const INFO: IMenuItem = {
  path: routesToKeyMap[singles.info],
  labelPath: ss.shared.menu.info,
  menuExact: true,
  breadcrumbsExact: true,
};

export const FLATS_HEADING: IMenuItem = {
  path: routesToKeyMap[flats.house],
  labelPath: ss.shared.menu.flats,
  menuExact: false,
  breadcrumbsExact: false,
};
export const HOUSE: IMenuItem = {
  path: routesToKeyMap[flats.house],
  labelPath: ss.shared.menu.house,
  menuExact: false,
  breadcrumbsExact: false,
};
export const SEARCH: IMenuItem = {
  path: routesToKeyMap[flats.search],
  labelPath: ss.shared.menu.search,
  menuExact: true,
  breadcrumbsExact: true,
};
export const HOW: IMenuItem = {
  path: routesToKeyMap[singles.how],
  labelPath: ss.shared.menu.how,
  menuExact: true,
  breadcrumbsExact: true,
};
export const TOUR: IMenuItem = {
  path: routesToKeyMap[singles.tour],
  labelPath: ss.shared.header.tour,
  menuExact: true,
  breadcrumbsExact: true,
  ignoreInBreadcrumbs: true,
};

export const EVENTS_HEADING: IMenuItem = {
  path: routesToKeyMap[posts.newsList],
  labelPath: ss.shared.menu.events,
  menuExact: true,
  breadcrumbsExact: true,
};
export const NEWS: IMenuItem = {
  path: routesToKeyMap[posts.newsList],
  labelPath: ss.shared.menu.news,
  menuExact: true,
  breadcrumbsExact: false,
};
export const CONSTRUCTION: IMenuItem = {
  path: routesToKeyMap[posts.dynamicList],
  labelPath: ss.shared.menu.construction,
  menuExact: true,
  breadcrumbsExact: false,
};
export const CAMERA: IMenuItem = {
  labelPath: ss.shared.menu.camera,
  menuExact: true,
  breadcrumbsExact: true,
};

export const CONTACT_HEADING: IMenuItem = {
  path: routesToKeyMap[singles.contact],
  labelPath: ss.shared.menu.contacts,
  menuExact: true,
  breadcrumbsExact: true,
};
export const SALES: IMenuItem = {
  path: routesToKeyMap[singles.contact] + "?section=sales",
  labelPath: ss.shared.menu.salesOffice,
  menuExact: true,
  breadcrumbsExact: true,
};
export const FEEDBACK: IMenuItem = {
  path: routesToKeyMap[singles.contact] + "?section=feedback",
  labelPath: ss.shared.menu.feedback,
  menuExact: true,
  breadcrumbsExact: true,
  disableActiveClass: true,
};
export const CALL: IMenuItem = {
  path: routesToKeyMap[singles.contact] + "?section=call",
  labelPath: ss.shared.menu.call,
  menuExact: true,
  breadcrumbsExact: true,
  disableActiveClass: true,
};

/**
 * Sections
 */
export const ABOUT_SECTION: IMenuSection = {
  heading: ABOUT_HEADING,
  items: [PLACEMENT, HOME, GALLERY, TEAM, DOCUMENTS, CHARS, INFO],
};

export const FLATS_SECTION: IMenuSection = {
  heading: FLATS_HEADING,
  items: [HOUSE, SEARCH, HOW, TOUR],
};

export const EVENTS_SECTION: IMenuSection = {
  heading: EVENTS_HEADING,
  items: [NEWS, CONSTRUCTION,
    // CAMERA
  ],
};

export const CONTACT_SECTION: IMenuSection = {
  heading: CONTACT_HEADING,
  items: [SALES, FEEDBACK, CALL],
};

export const menuConf: IMenuSection[] = [
  ABOUT_SECTION,
  FLATS_SECTION,
  EVENTS_SECTION,
  CONTACT_SECTION,
];
