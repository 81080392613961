import { AppConfig } from '@libs/Structure';
import { RouterType } from '@libs/RRH';

const isProd = process.env.NODE_ENV === 'production';

const staticVersion = '1.0.4';

const conf = {
  name: 'mayak',
  isProduction: isProd,
  ml: {
    enabled: true,
    locales: ['en', 'ru', 'uk'],
    defaultLocale: 'uk',
    detector: {
      routerLangParam: 'locale',
    },
  },
  router: {
    routerType: RouterType.Hash,
    breadcrumbsSize: 1,
    useTrailingSlash: true,
  },
  api: {
    siteURL: isProd ? '/' : 'https://beta-mayak.daytona.com.ua/',
    baseURL: isProd ? '/api/' : 'https://beta-mayak.daytona.com.ua/api/',
  },
  assetsURL: '//3d.daytona.com.ua/mayak/assets',
  auth: {
    enabled: false,
  },
  responsive: {
    mobile: 768,
  },
  assets: {
    version: staticVersion,
    houseSceneURL: `/scenes/house/model_360.json?v=${staticVersion}`,
    tour: `/tours/main/pano.xml?v=${staticVersion}`,
    tourImagesDir: '/tour-images',
  }
};

export const appConf: AppConfig & typeof conf = conf;
