import { IServerItem, IPhoto } from "@shared/api/commons";
import * as mock from "@libs/Mocks/dataFields";
import { api } from "@/services";
import { QueryKey } from 'react-query';
import { IUseQueryMLExtraParams } from "@shared/hooks/useQueryML";

export interface IConstructionListItem extends IServerItem {
  date: Date;
  cover: IPhoto;
  mobileCover: IPhoto;
  slug: string;
}

export interface IConstructionSingleItem extends IServerItem {
  date: Date;
  photos: IPhoto[];
  mobilePhotos: IPhoto[];
  slug: string;
  content?: string;
}

function mockList(): IConstructionListItem[] {
  return Array(40).fill("").map(() => ({
    id: mock.id(),
    slug: mock.slug(),
    date: mock.date(),
    cover: {
      src: mock.photo(),
      alt: mock.alt(),
    },
    mobileCover: {
      src: mock.photo(),
      alt: mock.alt(),
    },
  }));
}

function mockItem(): IConstructionSingleItem {
  return {
    id: mock.id(),
    slug: mock.slug(),
    date: mock.date(),
    photos: Array(10).fill("").map(() => ({
      src: mock.photo(),
      alt: mock.alt(),
    })),
    mobilePhotos: Array(10).fill("").map(() => ({
      src: mock.photo(),
      alt: mock.alt(),
    })),
  };
}

export function getItems(params: {queryKey: QueryKey}): Promise<IConstructionListItem[]> {
  let extraQueryParams = params.queryKey[params.queryKey.length - 1] as IUseQueryMLExtraParams;
  return api.get("/construction-dynamic/", undefined, {
    headers: {
      'Accept-Language': extraQueryParams.locale,
    },
    transformResponse: raw => JSON.parse(raw).map((el: any) => ({
      id: el.id,
      slug: el.slug,
      date: new Date(el.date_created),
      cover: {
        src: el.photo,
        alt: "",
      },
      mobileCover: {
        src: el.mobile_photo,
        alt: "",
      },
    })),
  }, {
    getPreloadingImages: (data: IConstructionListItem[]) => {
      let images: string[] = [];
      data.filter((_, i) => i <= 3).forEach(item => {
        images.push(item[extraQueryParams.isMobile ? 'mobileCover' : 'cover'].src);
      });
      return images;
    },
  });
}

export function getItem(params: {queryKey: QueryKey}): Promise<IConstructionSingleItem> {
  let slug = params.queryKey[1];
  let extraQueryParams = params.queryKey[params.queryKey.length - 1] as IUseQueryMLExtraParams;
  return api.get(`/construction-dynamic/${slug}/`, {}, {
    headers: {
      'Accept-Language': extraQueryParams.locale,
    },
    transformResponse: raw => {
      let el = JSON.parse(raw);
      return {
        id: el.id,
        slug: el.slug,
        date: new Date(el.date_created),
        content: el.content,
        photos: el.gallery_photos.map((p: any) => ({
          src: p.photo,
          alt: p.alt,
        })),
        mobilePhotos: el.gallery_photos.map((p: any) => ({
          src: p.mobile_photo,
          alt: p.alt,
        })),
      };
    },
  }, {
    getPreloadingImages: (data: IConstructionSingleItem) => {
      let images: string[] = [];
      data[extraQueryParams.isMobile ? 'mobilePhotos' : 'photos'].filter((_, i) => i <= 3).forEach(item => {
        images.push(item.src);
      });
      return images;
    },
  });
}
