/**
 * @description The Flat component.
 */
import React, { useMemo } from "react";
import { IFlat } from "@features/flats/services/flatManager";
import { ReactComponent as PDFIcon } from "./i/pdf.svg";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/features/flats/stringSource';
import "./Flat.scss";
import { motion } from "framer-motion";
import { Compass } from "@features/flats/components/Compass";

type Props = {
  flat: IFlat | null;
  onOpenBook: () => any;
  renderFloorNav: () => React.ReactElement;
};

export const Flat: React.FC<Props> = function (props) {
  const { flat, onOpenBook, renderFloorNav } = props;

  const animation = useMemo(() => {
    return {
      root: {
        hidden: {},
        visible: {
          transition: {
            delayChildren: 0.5,
            staggerChildren: 0.15,
          }
        },
        exit: {
          transition: {
            duration: 0.7,
            delayChildren: 0,
            staggerChildren: 0,
          }
        }
      },
      plan: {
        hidden: {
          opacity: 0,
          scale: 0.9,
        },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            duration: 0.4,
          }
        },
        exit: {
          opacity: 0,
          transition: {
            duration: 0.4,
          }
        }
      },
      button: {
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
        },
        exit: {
          opacity: 0,
          transition: {
            duration: 0.3,
          }
        }
      }
    };
  }, []);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={animation.root}
      className="floor-flat-flat"
    >
      <div className="floor-flat-flat__cont">

        <div className="floor-flat-flat__plan">
          <motion.div
            variants={animation.plan}
            className="floor-flat-flat__plan-img-wrap"
          >
            <svg width="1" height="1" viewBox="0 0 1 1" />
            <img src={flat?.urlPlanSVG} alt="" className="floor-flat-flat__plan-img"/>
            <div className="floor-flat-flat__compass">
              <Compass />
            </div>
          </motion.div>

          <div className="floor-flat-flat__buttons">
            <div className="floor-flat-flat__main-buttons">
              <motion.button variants={animation.button} onClick={onOpenBook} className="floor-flat-flat__button">
                <T p={ss.flats.flat.buttons.book} />
              </motion.button>
              <motion.a variants={animation.button} href={flat?.urlTour} target="_blank" className="floor-flat-flat__button">
                <T p={ss.flats.flat.buttons.windows} />
              </motion.a>
            </div>
            <div className="floor-flat-flat__pdf">
              <motion.a variants={animation.button} href={flat?.urlPlanPDF} target="_blank"><PDFIcon /><T p={ss.flats.flat.buttons.pdf} /></motion.a>
            </div>
          </div>
        </div>

        <motion.div variants={animation.button} className="floor-flat-flat__floor-nav">
          {renderFloorNav()}
        </motion.div>

      </div>
    </motion.div>
  );
};
