/**
 * @description The HoverFlatInfo component.
 */
import React  from "react";
import { IFlat } from "@features/flats/services/flatManager";
import { T } from "@/libs/ML/lib/components/T";
import { FlatAttr } from "@features/flats/components/Search/FlatAttr";
import { ss } from '@/features/flats/stringSource';
import "./HoverFlatInfo.scss";
import { motion } from "framer-motion";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";

type Props = {
  flat: IFlat;
};

export const HoverFlatInfo: React.FC<Props> = function (props) {
  const { flat } = props;
  const animation = useListAnimationVars({ itemDelay: 0.1 });
  const isCommerceRoom = flat.floorNumber <= 1;

  const renderRow = (label: string, value: any) => {
    return (
      <motion.div variants={animation.item} className="floor-flat-fhi__row">
        <div className="floor-flat-fhi__label">
          <T p={label} />
        </div>
        <div className="floor-flat-fhi__value">{value}</div>
      </motion.div>
    );
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={animation.list}
      className="floor-flat-fhi">
      <div className="floor-flat-fhi__cont">

        {renderRow(ss.flats.flat.infoPanel.type, <FlatAttr flat={flat} type="planCode" />)}
        {renderRow(isCommerceRoom ? ss.flats.flat.infoPanel.numberOfRoom : ss.flats.flat.infoPanel.number, <FlatAttr flat={flat} type="number" />)}
        {!isCommerceRoom && renderRow(ss.flats.flat.infoPanel.bedrooms, <FlatAttr flat={flat} type="bedrooms" />)}
        {renderRow(ss.flats.flat.infoPanel.area, <FlatAttr flat={flat} type="area" />)}
        {!flat.isHidePrice && renderRow(ss.flats.flat.infoPanel.price, <FlatAttr flat={flat} type="price" />)}

      </div>
    </motion.div>
  );
};
