import { StringStorage } from "@libs/ML/lib/stringStorage/StringStorage";
import { transformStringStorageItem } from "@libs/ML/lib/django/transformStringStorageItem";

import stringSources from "@/stringSources";

const stringStorage = new StringStorage({
  transformItem(p: string, value: string, settings): string {
    value = transformStringStorageItem(p, value, settings);
    return value;
  }
});

stringStorage.addItems(stringSources);

export default stringStorage;
