/**
 * @description The DummyPage component.
 */
import React, { ReactElement } from "react";
import { useQueryML } from "@shared/hooks/useQueryML";
import { getItems, IDocumentsSection } from "@features/posts/api/DocumentsAPI";
import { T } from "@libs/ML/lib/components/T";
import { ABOUT_SECTION, DOCUMENTS } from "@/menuConfig";
import { PageLayout } from "@shared/components/PageLayout";
import { DocumentsList } from "@features/posts/components/DocumentsList";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgDocs } from "@shared/images/bg/exports";

type Props = {}

export const Page = function (props: Props): ReactElement {
  const { isLoading, error, data } = useQueryML<IDocumentsSection[]>("documentsList", getItems);

  const plProps = {
    title: <T p={DOCUMENTS.labelPath}/>,
    isLoading,
    error: error?.message,
    breadcrumbs: ABOUT_SECTION,
    bg: <BackgroundSet conf={bgDocs}/>
  };
  return (
    <PageLayout {...plProps}>
      <div className="documents">
        <div className="documents__cont">

          {data?.map((section, index) => (
            <DocumentsList
              index={index}
              key={section.id}
              items={section.documents}
              title={section.title}
            />
          ))}

        </div>
      </div>
    </PageLayout>
  );
};
