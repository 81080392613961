/**
 * @description The CharsSection component.
 */
import React, { useMemo } from "react";
import { CharsSection as Conf, mediaSizeConf, parallaxConf } from "@features/singles/components/Chars/charsConf";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { T } from "@/libs/ML/lib/components/T";
import { ParallaxedImage } from "@shared/components/ParallaxedImage";
import { HomeSlider } from "@features/singles/components/HomeSlider";
import { TextSlideInView } from "@shared/components/TextSlideInView";
import { SomeSFInView } from "@shared/components/SomeSFInView";
import { useRem } from "@shared/hooks/useRem";
import { AnimateInView } from "@shared/components/AnimateInView";
import { motion } from "framer-motion";

type Props = {
  isMobile: boolean;
} & Conf;

export const CharsSection: React.FC<Props> = function (props) {
  const { isMobile, id, icon, media, textRows, title, type } = props;
  const { rem } = useRem();

  const animation = useMemo(() => {
    return {
      list: {
        variants: {
          hidden: {},
          visible: {
            transition: {
              delay: 0,
              duration: 0,
              staggerChildren: type === 1 ? 0.15 : 0.5,
              delayChildren: type === 1 ? 0.7 + 0.3 : 0,
              staggerDirection: -1,
            }
          },
        }
      },
      item: {
        variants: {
          hidden: { opacity: 0, y: rem(80) },
          visible: {
            opacity: 1, y: 0, transition: {
              duration: 0.7,
            }
          },
        }
      },
    }
  }, [rem, type, id]);

  const renderIcon = () => (
    <div className="chars-section__icon">
      <SomeSFInView delay={type === 1 ? 0.7 : 0.7} gap={rem(80)}>
        <div className="chars-section__icon-wrap">
          <img src={icon} alt="" className="chars-section__icon-img"/>
        </div>
      </SomeSFInView>
    </div>
  );

  const renderMedia = () => (
    <AnimateInView>
      {({ ref, controls }) => (
        <motion.div initial="hidden" animate={controls} {...animation.list} ref={ref} className="chars-section__media-images">
          {media.d.map((el, i) => (
            <motion.div {...animation.item} key={i} style={{
              width: `${rem(mediaSizeConf[type][i].w)}px`,
              height: `${rem(mediaSizeConf[type][i].h)}px`
            }} className="chars-section__media-image media-image">
              <ParallaxedImage
                fill
                gap={parallaxConf[type].gap}
                src={el}
              />
            </motion.div>
          ))}
        </motion.div>
      )}
    </AnimateInView>
  );

  const renderDesktop = () => {
    return (
      <div className="chars-section__wrap">
        <div className="chars-section__text">
          {(type !== 1 && type !== 5) && renderIcon()}
          <h2 className="chars-section__title">
            <TextSlideInView delay={type === 1 ? 0.3 : 0.7}>
              <T html p={title} />
            </TextSlideInView>
          </h2>
          {textRows.filter((el, i) => type === 1 ? i === 0 : true).map((el, i) => (
            <div key={i} className="chars-section__text-row">
              <TextSlideInView dir="right">
                <T html p={el} />
              </TextSlideInView>
            </div>
          ))}
        </div>
        <div className="chars-section__media">
          {(type === 1 || type === 5) && renderIcon()}
          {renderMedia()}
          {type === 1 && (
            <div className="chars-section__media-text">
              <div className="chars-section__text-row">
                <TextSlideInView dir="right">
                  <T html p={textRows[1]} />
                </TextSlideInView>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className="m-chars-section">
        <div className="m-chars-section__cont">

          <h2 className="m-chars-section__title">
            <TextSlideInView delay={type === 1 ? 0.2 : 0.7}>
              <T html p={title} />
            </TextSlideInView>
          </h2>

          <div className="m-chars-section__text-row">
            <TextSlideInView delay={type === 1 ? 0.5 : 0.7} dir="right">
              <T html p={textRows[0]} />
            </TextSlideInView>
          </div>

          <div className="m-chars-section__icon">
            {renderIcon()}
          </div>

          {textRows[1] && (
            <div className="m-chars-section__text-row m-chars-section__text-row--special">
              <TextSlideInView dir="right" delay={type === 1 ? 0.9 : 0.7}>
                <T html p={textRows[1]} />
              </TextSlideInView>
            </div>
          )}

          <div className="m-chars-section__media">
            {media.m.length > 1 ? (
              <HomeSlider
                randomGapFrom={40}
                randomGapTo={40}
                delay={type === 1 ? 0.9 : 0.7}
                slides={media.m.map(s => ({ desktop: s }))}
              />
            ) : (
              <SomeSFInView>
                <ParallaxedImage propWidth={320} propHeight={456} src={media.m[0]} gap={40} />
              </SomeSFInView>
            )}
          </div>

        </div>
      </div>
    );
  };

  const renderItem = () => {
    return isMobile ? renderMobile() : renderDesktop();
  };

  return (
    <div className={makeMods('chars-section', {
      [`${id}`]: true,
      [`type-${type}`]: true,
    })}>
      <div className="chars-section__cont">
        {renderItem()}
      </div>
    </div>
  );
};
