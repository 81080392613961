/**
 * @description The Back component.
 */
import React from "react";
import { Close } from "@shared/components/Close";
import "./Back.scss";

type Props = {
  to?: string;
  onClick?: (...args: any) => any;
};

export const Back: React.FC<Props> = function ({ to, onClick, ...rest }) {
  return (
    <div className="back">
      <div className="back__cont">

        <div className="back__line back__line--first" />
        <div className="back__button">
          <Close mods={['back']} to={to} onClick={onClick} {...rest} />
        </div>
        <div className="back__line back__line--second" />
        <div className="back__arrow" />

      </div>
    </div>
  );
};

