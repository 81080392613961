import { flattenObj } from "@libs/ML/lib/utils/flattenObject";

interface PathMappableObject {
  [p: string]: PathMappableObject | string;
}

/**
 * Replace values on paths in multilevel dictionary.
 * Usage: path autocomplete for StringSource
 * @param o
 * @param startPath
 */
export function makeObjectPropsPathMap<T extends PathMappableObject = PathMappableObject>(o: T, startPath?: string): T {
  let out: PathMappableObject = {};
  Object.keys(o).forEach(k => {
    let p = startPath ? `${startPath}.${k}` : k;
    if (typeof o[k] !== "string") {
      out[k] = makeObjectPropsPathMap(o[k] as T,  p);
    } else {
      out[k] = p;
    }
  });
  return out as T;
}

/**
 * Collapse multi-level object to flat object with keys by paths
 * @param o
 */
export function makeFlatPathToValueMap(o: PathMappableObject): {[path: string]: string} {
  return flattenObj(o);
}
