/**
 * @description The FloorNav component.
 */
import React, { useMemo } from "react";
import { makeFloorFlatLink } from "@features/flats/utils/makeFloorFlatLink";
import { Link } from "@libs/ML/lib/components/Link";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/features/flats/stringSource';
import { ReactComponent as IconPrev } from "./i/prev.svg";
import { ReactComponent as IconNext } from "./i/next.svg";
import "./FloorNav.scss";
import { makeMods } from "@libs/YandexBEM/lib/mm";

type Props = {
  mode: 'link' | 'button';
  onNext?: (next: number) => any;
  onPrev?: (prev: number) => any;
  current: number;
  next: number;
  prev: number;
  viewOnly?: boolean;
};

export const FloorNav: React.FC<Props> = function (props) {
  const { mode, next, prev, current, onNext, onPrev, viewOnly = false } = props;
  const isLink = mode === 'link';

  const prevLink = useMemo(() => {
    return makeFloorFlatLink(prev);
  }, [prev]);
  const nextLink = useMemo(() => {
    return makeFloorFlatLink(next);
  }, [next]);

  return (
    <div className={makeMods('floor-nav', {
      'view-only': viewOnly
    })}>
      <div className="floor-nav__cont">
        <div className="floor-nav__label">
          <T p={ss.flats.nav.floor} />
        </div>
        <div className="floor-nav__control">
          {isLink ? (
            <Link to={nextLink} className="floor-nav__prev"><IconPrev /></Link>
          ) : (
            <button onClick={() => onNext && onNext(next)} className="floor-nav__prev"><IconPrev /></button>
          )}
          <div className="floor-nav__current">
            {current}
          </div>
          {isLink ? (
            <Link to={prevLink} className="floor-nav__next"><IconNext /></Link>
          ) : (
            <button onClick={() => onPrev && onPrev(prev)} className="floor-nav__next"><IconNext /></button>
          )}
        </div>
      </div>
    </div>
  );
};
