import { useState, useMemo, useEffect, useCallback } from 'react';

export function useFullHeight() {
  const [t, setT] = useState(0);

  useEffect(() => {
    const handler = () => {
      setT(new Date().getTime());
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  const getHeight = useCallback(() => {
    let b = document.querySelector('.breadcrumbs')?.getBoundingClientRect();
    if (!b) return 0;
    return window.innerHeight - b.height;
  }, []);

  return useMemo(() => {
    return getHeight();
  }, [getHeight, t]);
}
