/**
 * @description The AnimateInView component.
 */
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

export type SharedProps = {
  variationForStart?: string;
};

type Props = {
  children: (...a: any) => any;
} & SharedProps;

export const AnimateInView: React.FC<Props> = function (props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { children, variationForStart = 'visible' } = props;

  useEffect(() => {
    if (inView) {
      controls.start(variationForStart);
    }
  }, [controls, inView, variationForStart]);

  return children({ ref, controls });
};
