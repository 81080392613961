/**
 * @description The HomeSlider component.
 */
import React, { useEffect, useMemo } from "react";
import "./HomeSlider.scss";
import { ParallaxedImage } from "@shared/components/ParallaxedImage";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Keyboard } from 'swiper';
import { useRem } from "@shared/hooks/useRem";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { randomIntBetween } from "@libs/Mocks/random";
import { SharedProps } from "@shared/components/ParallaxedImage";

SwiperCore.use([Navigation, Pagination, Keyboard]);

type Props = {
  slides: {
    desktop: string,
    mobile?: string,
    alt?: string,
  }[],
  delay?: number,
  duration?: number,
  animation?: boolean,
} & SharedProps;

export const HomeSlider: React.FC<Props> = function (props) {
  const { slides, randomGapFrom = 80, randomGapTo = 140, delay = 0.35, duration = 0.7, animation: animate = true } = props;
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { rem } = useRem();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (inView) controls.start('visible');
  }, [controls, inView]);

  const animation = useMemo(() => {
    return {
      list: animate ? {
        initial: 'hidden',
        animate: controls,
        variants: {
          hidden: {
            opacity: 0,
            y: '30%',
          },
          visible: {
            opacity: 1,
            y: 0,
            transition: {
              delay: 0,
              duration: 0.7,
              staggerChildren: 0.13,
              delayChildren: delay,
            }
          },
        }
      } : {
        initial: 'hidden',
      },
      item: animate ? {
        variants: {
          hidden: { opacity: 0, y: '30%' },
          visible: {
            opacity: 1, y: 0, transition: {
              duration: duration,
            }
          },
        }
      } : {
        variants: {
          hidden: {
            opacity: 1,
          },
          visible: {
            opacity: 1,
          }
        },
      },
    }
  }, [controls, delay, animate]);

  return (
    <motion.div {...animation.list} ref={ref} className="home-slider">
      <div className="home-slider__cont">

        <div className="home-slider__slider">
          <Swiper
            keyboard
            navigation
            pagination={{ clickable: false }}
            spaceBetween={rem(isMobile ? 10 : 50)}
            slidesPerView={1}
          >
            {slides.map((slide, i) => (
              <SwiperSlide key={i}>
                <motion.div {...animation.item}>
                  <ParallaxedImage
                    gap={isMobile ? 100 : 0}
                    src={slide.desktop}
                    mobileSrc={slide.mobile}
                    alt={slide.alt}
                    propWidth={isMobile ? 320 : 881}
                    propHeight={isMobile ? 455 : 511}
                    randomGapFrom={randomGapFrom}
                    randomGapTo={randomGapTo}
                  />
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

      </div>
    </motion.div>
  );
};
