import { useState, useEffect, useCallback } from 'react';

export function useHoverState<T = null>(initialValue: T | null = null) {
  const [hover, setHover] = useState(initialValue);
  const [latestHover, setLatestHover] = useState(initialValue);

  useEffect(() => {
    if (hover !== null) {
      setLatestHover(hover);
    }
  }, [hover]);

  const reset = useCallback((value: T) => {
    setHover(value);
    setLatestHover(value);
  }, [setHover, setLatestHover]);

  return {
    hover,
    latestHover,
    setHover,
    reset,
  }
}
