/**
 * @description The HomeHero component.
 */
import React, { useEffect, useState, useRef } from "react";
import gsap from 'gsap';
import { useSelector, useDispatch } from "react-redux";
import { selectIsHomeAnimationPlayed, set as setSettings } from "@shared/ducks/settings.duck";
import img1 from '@shared/images/home/hero/1.jpg';
import img2 from '@shared/images/home/hero/2.jpg';
import img3 from '@shared/images/home/hero/3.jpg';
import img4 from '@shared/images/home/hero/4.jpg';
import img1m from '@shared/images/home/hero/1m.jpg';
import img2m from '@shared/images/home/hero/2m.jpg';
import img3m from '@shared/images/home/hero/3m.jpg';
import img4m from '@shared/images/home/hero/4m.jpg';
import "./HomeHero.scss";
import { FullscreenImage } from "@shared/components/FullscreenImage";
import logo from "@/shared/images/logo.svg";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { useRem } from "@shared/hooks/useRem";
import { EVENT_LOADER_HIDDEN } from "@shared/events/loading";
import overImg from './i/over.png';
import { useBodyScroll } from "@shared/hooks/useBodyScroll";
import { HomeHeroSVGContent } from "@features/singles/components/HomeHero/HomeHeroSVGContent";
import { makeMods } from "@libs/YandexBEM/lib/mm";

const images = [
  {
    d: {
      src: img1,
      width: 3000,
      height: 1531,
    }, m: {
      src: img1m,
      width: 1060,
      height: 1531
    },
  },
  {
    d: {
      src: img2,
      width: 2000,
      height: 849,
    }, m: {
      src: img2m,
      width: 587,
      height: 849
    },
  },
  {
    d: {
      src: img3,
      width: 3000,
      height: 1275,
    }, m: {
      src: img3m,
      width: 640,
      height: 1275
    },
  },
  {
    d: {
      src: img4,
      width: 3000,
      height: 1531,
    }, m: {
      src: img4m,
      width: 1730,
      height: 2500
    },
  },
];

type Props = {
  done: (...a: any) => any,
};

export const HomeHeroSVG: React.FC<Props> = function (props) {
  const frameBgRef = useRef<SVGGElement>(null);
  const frameMiddleRef = useRef<SVGGElement>(null);
  const frameFgRef = useRef<SVGGElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { scrollPassOneScreen } = useBodyScroll();
  const [canStart, setCanStart] = useState(!!(window as any).mayakLoaderHidden);
  const used = useSelector(selectIsHomeAnimationPlayed);
  const use = () => {
    dispatch(setSettings({
      isHomeAnimationPlayed: true,
    }));
  };
  const usedRef = useRef(used);

  useEffect(() => {
    if (!canStart) return;
    // return ;

    // extract refs
    const frameBg = frameBgRef.current as any as SVGGElement;
    const frameMiddle = frameMiddleRef.current as any as SVGGElement;
    const frameFg = frameFgRef.current as any as SVGGElement;
    const scroll = scrollRef.current as any as HTMLDivElement;

    // extractImages
    const bgImages = frameBg.querySelectorAll('.home-hero-svg__image');
    const middleImages = frameMiddle.querySelectorAll('.home-hero-svg__image');
    const fgImages = frameFg.querySelectorAll('.home-hero-svg__image');

    // create main animation timeline
    let timeline = gsap.timeline({ delay: 0.2, onComplete: () => {
        props.done();
      }});

    if (!used) {
      use();

      // add intro
      timeline.fromTo(frameFg, {opacity: 0, scale: 0.9, }, {opacity: 1, scale: 1, duration: 0.5*1.3});
      timeline.fromTo(frameMiddle, {opacity: 0, scale: 0.9}, {opacity: 1, scale: 1, duration: 0.5*1.3}, "-=0.32");
      timeline.fromTo(frameBg, {opacity: 0, scale: 0.9}, {opacity: 1, scale: 1, duration: 0.5*1.3}, "-=0.32");

      // run slides animation
      for (let i = 0; i < bgImages.length - 1; i++) {
        let currentTl = gsap.timeline({ delay: 1.3 });
        currentTl.fromTo([bgImages[i], fgImages[i]], {scale: 1}, {scale: 1.15, duration: 1.3}, 0);
        currentTl.fromTo([bgImages[i + 1], fgImages[i + 1]], {scale: 1.15, opacity: 0}, {scale: 1, opacity: 1, duration: 1.3}, 0);
        currentTl.fromTo(middleImages[i], {scale: 1}, {scale: 1.15, duration: 1.3 + 0.35}, 1.3 - 0.9);
        currentTl.fromTo(middleImages[i + 1], {scale: 1.15, opacity: 0}, {scale: 1, opacity: 1, duration: 1.3 + 0.35}, 1.3 - 0.9);
        timeline.add(currentTl);
      }

      // add ending
      timeline.to(frameFg,{opacity: 0, duration: 0.5}, "-=0.55");
      timeline.to(frameMiddle,{opacity: 0, duration: 0.5}, "-=0.6");
    } else {
      timeline.set(bgImages[bgImages.length - 1], { opacity: 1 });
      timeline.fromTo(frameBg, { opacity: 0 }, { opacity: 1, duration: 0.5 });
      // timeline.fromTo(over, {opacity: 0}, {opacity: 1, duration: 1});
    }

    // add sidebar animation
    let sidebarRoot = isMobile ? scroll : document.querySelector('.sidebar') as HTMLDivElement;
    let header =  document.querySelector(isMobile ? '.m-header' : '.header');
    const logo = sidebarRoot.querySelector(`[data-logo]`);
    const arrow = sidebarRoot.querySelector(`[data-arrow]`);
    const tl = gsap.timeline();
    tl.fromTo(header, { opacity: 0 }, { opacity: 1, duration: 0.7 });
    tl.fromTo(logo, { opacity: 0 }, { opacity: 1, duration: 0.7 }, `-=0.4`);
    tl.fromTo(arrow, { opacity: 0 }, { opacity: 1, duration: 0.7 }, `-=0.4`);
    timeline.add(tl, used ? '-=0.5' : '-=0');

    return () => {
      timeline.pause();
      (header as HTMLElement).style.opacity = '';
      (logo as HTMLElement).style.opacity = '';
      (arrow as HTMLElement).style.opacity = '';
    };
  }, [canStart]);

  useEffect(() => {
    if (!canStart) {
      const h = () => {
        setCanStart(true);
      };
      window.addEventListener(EVENT_LOADER_HIDDEN, h);
      return () => {
        window.removeEventListener(EVENT_LOADER_HIDDEN, h);
      };
    }
  }, [canStart]);

  return (
    <div style={scrollPassOneScreen ? {opacity: 0} : {}} className={makeMods('home-hero', {
      used: usedRef.current,
    })}>
      <div className="home-hero__cont">

       <div className="home-hero__svg-cont">
         <HomeHeroSVGContent
           frameBgRef={frameBgRef}
           frameMiddleRef={frameMiddleRef}
           frameFgRef={frameFgRef}
           images={images} />
       </div>

        {isMobile && (
          <div ref={scrollRef} className="home-hero__scroll">
            <div className="home-hero__scroll-logo" data-logo>
              <img src={logo} alt="Mayak"/>
            </div>
            <div className="home-hero__scroll-arrow" data-arrow>
              <b />
              <span />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};
