import { useQuery } from "react-query";
import { preloadImagesSet } from "@libs/Preload/lib/image";

export function usePreloadImages(images: string[],) {
  const { isLoading } = useQuery(['imagesPreloading', images], async () => {
    return await preloadImagesSet(images);
  }, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  return {
    isLoading,
  }
}
