/**
 * @param currentTime
 * @param start
 * @param change
 * @param duration - ms
 */
export function easeInOutQuad(currentTime: number, start: number, change: number, duration: number = 1000) {
  currentTime /= duration / 2;
  if (currentTime < 1) return change / 2 * currentTime * currentTime + start;
  currentTime--;
  return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
}
