/**
 * @description The GalleryAlbum component.
 */
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Keyboard } from 'swiper/core';
import { IGalleryAlbum } from "@features/posts/api/GalleryAPI";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { FullscreenImage } from "@shared/components/FullscreenImage";
import "./GalleryAlbum.scss";
import { Close } from "@shared/components/Close";
import { SomeSFAnimation } from "@shared/components/SomeSFAnimation";

SwiperCore.use([Navigation, Thumbs, Keyboard]);

type Props = {} & IGalleryAlbum;

export const GalleryAlbum: React.FC<Props> = function (props) {
  const isMobile = useIsMobile();
  const { items, itemsMobile } = props;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [current, setCurrent] = useState(0);

  const itemsToUse = isMobile && itemsMobile ? itemsMobile : items;

  return (
    <div className="gallery-album">
      <SomeSFAnimation full gap={0} delay={0.15}>
        <div className="gallery-album__cont">

          <div className="gallery-album__main">
            <Swiper
              keyboard
              speed={700}
              onSlideChange={(swp) => setCurrent(swp.activeIndex)}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              touchReleaseOnEdges={true}
            >
              {itemsToUse.map((l, i) => (
                <SwiperSlide key={l.src + i}>
                  <FullscreenImage alt={l.alt} src={l.src} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="gallery-album__thumbs">
            <div className="gallery-album__thumbs-btn">
              <Close to="/gallery" />
            </div>
            <div className="gallery-album__thumbs-slider">
              <Swiper
                className="gallery-album__thumbs-slider"
                onSwiper={(s) => setThumbsSwiper(s as any)}
                slidesPerView="auto"
                freeMode={true}
                watchSlidesVisibility={true}
                watchSlidesProgress={true}
                direction="vertical"
                touchReleaseOnEdges={true}
              >
                {itemsToUse.map((l, i) => (
                  <SwiperSlide className="gallery-album__thumbs-slide" key={l.src + i}>
                    <div className="gallery-album__thumbs-image">
                      <FullscreenImage alt={l.alt} src={l.src} fillMode="cover" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="gallery-album__counter">
            <b>{current + 1 < 10 ? `0${current + 1}` : current + 1}</b>
            <span><span className="delimiter">/</span> {itemsToUse.length < 10 ? `0${itemsToUse.length}` : itemsToUse.length}</span>
          </div>

        </div>
      </SomeSFAnimation>
    </div>
  );
};
