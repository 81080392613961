/**
 * @description The TourPage component.
 */
import React from "react";
import { Tour } from "@features/singles/components/Tour";

type Props = {}

export const TourPage: React.FC<Props> = function (props) {
  return (
   <Tour />
  );
};
