import { Locale } from "date-fns";
import format from 'date-fns/format';
import { dateConf } from "@/dateConfig";

export interface IAvailableDatesAndMonths {
  year: number;
  months: {
    number: number;
    name: string;
  }[];
}

export function getAvailableYearsAndMonths(dates: Date[], locale?: Locale) {
  let years: IAvailableDatesAndMonths[] = [];

  dates.forEach(el => {
    let year = parseInt(format(el, 'yyyy', { locale })),
      monthName = format(el, 'LLLL', { locale }),
      month = parseInt(format(el, 'MM', { locale }));

    let monthData = {
      number: month,
      name: monthName,
    };

    let yearIdx = years.findIndex(y => y.year === year);
    if (yearIdx !== -1) {
      years[yearIdx].months.push(monthData);
    } else {
      years.push({
        year,
        months: [monthData],
      })
    }
  });

  years = years.map(year => {
    return {
      ...year,
      months: year.months.filter((el, i, arr) => {
        return arr.findIndex((l) => l.number === el.number) === i;
      }).sort((a, b) => a.number - b.number).reverse()
    }
  });

  return years.sort((a, b) => a.year - b.year).reverse();
}
