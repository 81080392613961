/**
 * @description The FlatAttr component.
 */
import React, { Fragment } from "react";
import { IFlat } from "@features/flats/services/flatManager";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/shared/stringSource';

type Props = {
  flat: IFlat;
  type: keyof IFlat | 'priceWithCurrency';
};

export const FlatAttr: React.FC<Props> = function (props) {
  const { flat, type } = props;

  let value = (flat as any)[type];

  if (type === 'price' || type === 'priceWithCurrency') {
    if (flat.isHidePrice) {
      value = '-';
    } else {
      let price = flat.priceString;
      value = type === 'price' ? price : (
        <Fragment>{price} <span className="smaller">
          <T p={ss.shared.currency.uah} />
        </span></Fragment>
      );
    }
  }

  if (type === 'area') {
    value = flat.area.toFixed(2);
  }

  return (
    <span className={makeMods('flat-attr', {
      [type]: true,
    })}>{value || '-'}</span>
  );
};
