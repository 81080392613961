import { IStringSource, makeMap } from "@libs/ML/lib/StringSource";

const strings = {
  posts: { // WARNING! Do not change the scope name!
    ok: "Ok",
    documents: {
      columns: {
        document: "Document",
        published: "Published"
      },
      showMore: "More",
      showLess: "Less",
      sectionTitle: {
        docs: "Projects documentation",
        declarations: "Projects declarations"
      }
    },
    team: {
      goToSite: "Go to the site",
    },
    construction: {
      filter: {
        periodLabel: 'Period:',
        yearsAll: 'All years',
        monthsAll: 'All months',
      }
    },
  }
};

export const stringSource: IStringSource & typeof strings = strings;

export const ss: typeof strings = makeMap(strings) as typeof strings;
