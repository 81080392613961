import { useCallback, useMemo } from 'react';
import { useBodyScrollTo } from "@shared/hooks/useBodyScrollTo";
import { useRem } from "@shared/hooks/useRem";

export function useBodyScrollToElement() {
  const { scrollTo: _scrollTo, stop, animationPlaying, getScrollTop } = useBodyScrollTo();
  const { rem } = useRem();

  const scrollTo = useCallback((selector: string, gapToHeaderPX = 50, duration = 0.5) => {
    let el = document.querySelector(selector);
    if (!el) return;
    let header = document.querySelector('.ws__header > header') as HTMLElement,
      headerMargin = header.getBoundingClientRect().height + rem(gapToHeaderPX),
      elementY = el.getBoundingClientRect().y,
      scrollTop = getScrollTop();
    stop();
    _scrollTo(scrollTop + elementY - headerMargin, duration);
  }, [stop, _scrollTo, rem]);

  return {
    scrollTo,
    stop,
    animationPlaying,
  }
}
