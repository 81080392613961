/**
 * @description The Sidebar component.
 */
import React, { useMemo } from "react";
import logo from "@shared/images/logo.svg";
import "./Sidebar.scss";
import { Link } from "@libs/ML/lib/components/Link";
import { useBodyScroll } from "@shared/hooks/useBodyScroll";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { useLocation } from "react-router";
import { keys as postsRoutesKeys } from "@features/posts/routes";
import { useCurrentPage } from "@shared/hooks/useCurrentPage";

type Props = {}

export const Sidebar: React.FC<Props> = function (props) {
  const location = useLocation();
  const { hasScroll, scrollFinished, scrollDown, scrollUp } = useBodyScroll(location.pathname);
  const page = useCurrentPage();

  const hideScroll = useMemo(() => {
    if (page.page?.id === postsRoutesKeys.galleryList) {
      return true;
    }
    return false;
  }, [page.page?.id]);

  const handleClick = () => {
    if (!hasScroll) return;
    if (scrollFinished) {
      scrollUp();
    } else {
      scrollDown(100);
    }
  };

  return (
    <div className={makeMods("sidebar", {
      ['has-scroll']: hideScroll ? false : hasScroll,
      ['scroll-finished']: hasScroll && scrollFinished,
    })}>
      <div className="sidebar__cont">

        <Link to="/" className="sidebar__logo" data-logo>
          <img src={logo} alt="Mayak"/>
        </Link>
        <div onClick={handleClick} className="sidebar__arrow" data-arrow>
          <b />
          <span />
        </div>

      </div>
    </div>
  );
};
