import { IServerItem, IPhoto } from "@shared/api/commons";
import * as mock from "@/libs/Mocks/dataFields";
import api from "@/services/api";
import { QueryKey } from "react-query";
import { IUseQueryMLExtraParams } from "@shared/hooks/useQueryML";

interface INewsItem extends IServerItem {
  date: Date;
  title: string;
  slug: string;
}

export interface INewsListItem extends INewsItem {
  background: IPhoto;
  backgroundMobile: IPhoto;
}

export interface INewsSingleItem extends INewsItem {
  body: string;
}

function mockList(): INewsListItem[] {
  return Array(20).fill("").map(() => ({
    id: mock.id(),
    date: mock.date(),
    title: mock.title(),
    slug: mock.slug(),
    background: {
      src: mock.photo(),
      alt: mock.alt(),
    },
    backgroundMobile: {
      src: mock.photo(),
      alt: mock.alt(),
    },
  }));
}

function mockSingle(): INewsSingleItem {
  return {
    id: mock.id(),
    date: mock.date(),
    title: mock.title(),
    slug: mock.slug(),
    body: mock.content(),
  };
}

export function getItems(params: {queryKey: QueryKey}): Promise<INewsListItem[]> {
  let extraQueryParams = params.queryKey[params.queryKey.length - 1] as IUseQueryMLExtraParams;
  return api.get("/news/", undefined, {
    headers: {
      'Accept-Language': extraQueryParams.locale,
    },
    transformResponse: (raw) => JSON.parse(raw).map((el: any) => ({
      id: el.id,
      slug: el.slug,
      title: el.title,
      date: new Date(el.date_created),
      background: {
        src: el.photo,
        alt: el.title,
      },
      backgroundMobile: {
        src: el.mobile_photo,
        alt: el.title,
      },
    })),
  }, {
    getPreloadingImages: (data: INewsListItem[]) => {
      let images: string[] = [];
      data.filter((_, i) => i <= 3).forEach(item => {
        images.push(item[extraQueryParams.isMobile ? 'backgroundMobile' : 'background'].src);
      });
      return images;
    }
  });
}

export function getItem(params: {queryKey: QueryKey}): Promise<INewsSingleItem> {
  let slug = params.queryKey[1];
  let extraQueryParams = params.queryKey[params.queryKey.length - 1] as IUseQueryMLExtraParams;
  return api.get(`/news/${slug}/`, {}, {
    headers: {
      'Accept-Language': extraQueryParams.locale,
    },
    transformResponse: (raw) => {
      let el: any = JSON.parse(raw);
      return {
        id: el.id,
        slug: el.slug,
        title: el.title,
        date: new Date(el.date_created),
        body: el.content,
      }
    },
  }, {
    getPreloadingImages: (data: INewsSingleItem) => {
      let images: string[] = [];
      let m,
        rex = /<img[^>]+src="([^"]+)"/g;
      while (m = rex.exec(data.body)) {
        images.push(m[1]);
      }
      return images;
    }
  });
}
