import { useParams } from "react-router";
import { useMemo } from 'react';

export interface IFloorFlatParams {
  floorNumber: number | null;
  flatID: number | null;
}

export function useFloorFlatParams(): IFloorFlatParams {
  const params = useParams<{flat?: string, floor?: string}>();
  let flat = params.flat ? params.flat === 'none' ? null : parseInt(params.flat) : null;
  let floor = params.floor ? parseInt(params.floor) : null;
  return useMemo(() => ({
    floorNumber: isNaN(floor as number) ? null : floor,
    flatID: isNaN(flat as number) ? null : flat,
  }), [flat, floor]);
}
