/**
 * @description The TextSlideInView component.
 */
import React from "react";
import { SharedProps as SharedTextSlideAnimationProps, TextSlideAnimation } from "@shared/components/TextSlideAnimation";
import { AnimateInView } from "@shared/components/AnimateInView";

type Props = {} & SharedTextSlideAnimationProps;

export const TextSlideInView: React.FC<Props> = function (props) {
  const { children, delay = 0.35, speed = 0.7, dir = "up" } = props;

  return (
    <AnimateInView>{({ ref, controls }) => (
      <TextSlideAnimation
        delay={delay}
        speed={speed}
        dir={dir}
        animationControl={controls}
        rootRef={ref}
      >{children}</TextSlideAnimation>
    )}</AnimateInView>
  );
};
