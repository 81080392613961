import { Config } from "@libs/RRH/lib/route";
import { featureConf } from "./config";

const NAMESPACE = featureConf.name;

export const keys = {
  newsList: `${NAMESPACE}/newsList`,
  newsItem: `${NAMESPACE}/newsItem`,
  documentsList: `${NAMESPACE}/documentsList`,
  dynamicList: `${NAMESPACE}/dynamicList`,
  dynamicItem: `${NAMESPACE}/dynamicItem`,
  galleryList: `${NAMESPACE}/galleryList`,
  galleryItem: `${NAMESPACE}/galleryItem`,
  teamList: `${NAMESPACE}/teamList`,
};

export const routes = [
  new Config({
    id: keys.newsList,
    path: "/news",
  }),
  new Config({
    id: keys.newsItem,
    path: "/news/:slug",
  }),
  new Config({
    id: keys.documentsList,
    path: "/documents",
  }),
  new Config({
    id: keys.dynamicList,
    path: "/construction",
  }),
  new Config({
    id: keys.dynamicItem,
    path: "/construction/:slug",
  }),
  new Config({
    id: keys.galleryList,
    path: "/gallery",
  }),
  new Config({
    id: keys.galleryItem,
    path: "/gallery/:slug",
    mods: ['fullscreen'],
  }),
  new Config({
    id: keys.teamList,
    path: "/team",
  }),
];
