/**
 * @description The MobileFloor component.
 */
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { MobileScrollMessage } from "@features/flats/components/MobileScrollMessage";
import { FlatManager } from "@features/flats/services/flatManager";
import { useHoverState } from "@shared/hooks/useHoverState";
import { useFloorNav } from "@features/flats/hooks/useFloorNav";
import { useGoToWithLocale } from "@libs/ML/lib/hooks/useGoToWithLocale";
import { useFullHeight } from "@features/flats/ducks/useFullHeight";
import { useLocation } from "react-router";
import { makeFloorFlatLink } from "@features/flats/utils/makeFloorFlatLink";
import { useRem } from "@shared/hooks/useRem";
import { useIsFirstRender } from "@shared/hooks/useIsFirstRender";
import { FloorPlan } from "@features/flats/components/FloorPlan";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as WaterIcon } from "@features/flats/components/FloorFlat/i/water.svg";
import { Compass } from "@features/flats/components/Compass";
import { FloorPlanLegend } from "@features/flats/components/FloorPlanLegend";
import { T } from "@libs/ML/lib/components/T";
import { ss } from "@features/flats/stringSource";
import { ReactComponent as TreesIcon } from "@features/flats/components/FloorFlat/i/trees.svg";
import { FloorNav } from "@features/flats/components/FloorNav";
import "./MobileFloor.scss";

type Props = {
  floorNumber: number;
  flatsManager: FlatManager;
};

export const MobileFloor: React.FC<Props> = function (props) {
  const { floorNumber, flatsManager } = props;
  const { latestHover, setHover, reset: resetHover } = useHoverState<null|number>();
  const floorNav = useFloorNav(floorNumber, flatsManager);
  const goTo = useGoToWithLocale();
  const height = useFullHeight();
  const location = useLocation();
  const { rem } = useRem();
  const isFR = useIsFirstRender();

  const isShowAvailable = useMemo(() => {
    return flatsManager.getFloorFlats(floorNumber).filter(f => f.isAvailable).length > 0;
  }, [flatsManager]);

  const isShowSold = useMemo(() => {
    return flatsManager.getFloorFlats(floorNumber).filter(f => !f.isAvailable).length > 0;
  }, [flatsManager]);

  // reset hover on floor change
  useEffect(() => {
    resetHover(null);
  }, [floorNumber]);

  // flat click handler
  const handleGoToFlat = useCallback((flatID: number) => {
    let flat = flatsManager.getFlatByID(flatID);
    return goTo(makeFloorFlatLink(flat?.floorNumber, flatID));
  }, [goTo, flatsManager]);

  const changePlanAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
      },
      show: {
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
          delay: 0.5,
        }
      },
      hide: {
        opacity: 0,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
        }
      }
    };
  }, []);

  const initialPlanAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
        scale: 0.95
      },
      show: {
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.7,
          ease: 'easeInOut',
          delay: 0.2,
        }
      },
    };
  }, []);

  const uiAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
      },
      show: {
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
          delay: 0.7,
        }
      },
    };
  }, []);

  return (
    <Fragment>
      <div className="floor-mobile-view">
        <div className="floor-mobile-view__cont">

          <div className="floor-mobile-view__position">

            <motion.div
              variants={initialPlanAnimation}
              initial="initial"
              animate="show"
              className="floor-mobile-view__plan"
            >
              <svg className="floor-mobile-view__plan-prop" width="1" height="1" viewBox='0 0 1 1' />
              <AnimatePresence>
                <motion.div
                  variants={changePlanAnimation}
                  initial={isFR ? false : "initial"}
                  animate="show"
                  exit="hide"
                  key={floorNumber}
                  className="floor-mobile-view__plan-wrap"
                >
                  <FloorPlan
                    floorNumber={floorNumber}
                    flatsManager={flatsManager}
                    hovered={latestHover}
                    onClick={handleGoToFlat}
                    onHover={setHover}
                  />
                </motion.div>
              </AnimatePresence>
            </motion.div>

            <motion.div
              variants={uiAnimation}
              initial="initial"
              animate="show"
            >
              <div className="floor-mobile-view__icons">
                <div className="floor-mobile-view__i-water">
                  <WaterIcon />
                </div>
                <div className="floor-mobile-view__i-trees">
                  <TreesIcon />
                </div>
              </div>

              <div className="floor-mobile-view__streets">
                <div className="floor-mobile-view__street floor-mobile-view__street--top">
                  <T p={ss.flats.floorPlan.streetTop} />
                </div>
                <div className="floor-mobile-view__street floor-mobile-view__street--bottom">
                  <T p={ss.flats.floorPlan.streetBottom} />
                </div>
              </div>

              <div className="floor-mobile-view__compass">
                <Compass />
              </div>

              <div className="floor-mobile-view__nav">
                <FloorNav
                  mode="link"
                  current={floorNav.current as number}
                  prev={floorNav.prev as number}
                  next={floorNav.next as number}
                />
              </div>

              {floorNumber !== 1 && (
                <div className="floor-mobile-view__legend">
                  <FloorPlanLegend available={isShowAvailable} sold={isShowSold} />
                </div>
              )}
            </motion.div>

          </div>

        </div>
      </div>
      <MobileScrollMessage id="floor" />
    </Fragment>
  );
};
