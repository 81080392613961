import history from 'history';
import { applyMiddleware, combineReducers, compose as _compose, createStore as _createStore, Reducer as ReduxReducer } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { all } from 'redux-saga/effects';
import { Store } from "redux";

import { Reducer, Saga } from "@libs/Ducks/lib/core/reduxStack";
import { appConf } from '@/config';
import { ducks } from "@/ducks";
import historyService from "./history";

/**
 * Grab all reducers and ducks together
 */
const reducers: {[p: string]: Reducer<any, any>} = {};
ducks.forEach(d => reducers[d.namespace] = d.reducer);
const sagas = ducks.reduce((acc: Saga[], duck) => [...acc, ...duck.sagas], []);

interface CreateStoreOpts {
  history: history.History;
}

interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof _compose;
}

function createStore(opts: CreateStoreOpts) {
  // make middlewares
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [routerMiddleware(opts.history), sagaMiddleware];
  let compose = _compose;

  // add dev features
  if (!appConf.isProduction) {
    middlewares.unshift(
      createLogger({
        collapsed: true,
      }),
    );
    compose = ((window as unknown) as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || _compose;
  }

  // create store
  const store = _createStore(
    combineReducers({
      router: connectRouter(opts.history),
      ...reducers,
    }) as ReduxReducer,
    {},
    compose(applyMiddleware(...middlewares)),
  );

  // run sagas
  sagaMiddleware.run(function* () {
    yield all([...sagas.map(s => s())]);
  });

  return store;
}

declare let module: {
  hot: {
    data?: {
      store?: Store;
    };
    dispose: (fn: (data: any) => any) => any;
  };
};

const store = module.hot?.data?.store || createStore({ history: historyService });

if (module.hot) {
  module.hot.dispose((data) => {
    /* eslint no-param-reassign: 0 */
    data.store = store;
  });
}

export default store;
