/**
 * Feature config file
 */

import { FeatureConfig } from "@libs/Structure";

/**
 * Define config here
 */
const conf = {
  name: 'singles',
};

export const featureConf: typeof conf & FeatureConfig = conf;
