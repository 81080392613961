/**
 * @description The SomeSFAnimation component.
 */
import React, { Ref, useMemo } from "react";
import { AnimationControls, motion } from "framer-motion";
import { useRem } from "@shared/hooks/useRem";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import "./SomeSFAnimation.scss";

export type SharedProps = {
  dir?: "up" | "down" | "left" | "right";
  speed?: number;
  delay?: number;
  gap?: number | string;
  full?: boolean;
};

type Props = {
  animationControl?: AnimationControls;
  rootRef?: Ref<HTMLDivElement>;
} & SharedProps;

export const SomeSFAnimation: React.FC<Props> = function (props) {
  const { children, dir = "up", speed = 1, delay = 0, gap = '25%', animationControl, rootRef, full = false } = props;
  const { rem } = useRem();
  const gapToUse = typeof gap === "string" ? gap : rem(gap);

  const animation = useMemo(() => {
    let x1: string | number = '0%',
      x2: string | number = '0%',
      y1: string | number = gapToUse,
      y2: string | number = '0%';

    if (dir === "down") {
      y1 = typeof gapToUse === "string" ? '-' + gapToUse : gapToUse * -1;
    } else if (dir === 'right') {
      y1 = '0%';
      x1 = typeof gapToUse === "string" ? '-' + gapToUse : gapToUse * -1;
    } else if (dir === 'left') {
      x1 = gapToUse;
    }

    return {
      initial: 'hidden',
      animate: animationControl || 'visible',
      variants: {
        hidden: { opacity: 0, y: y1, x: x1 },
        visible: { opacity: 1, y: y2, x: x2 },
      },
      transition: { duration: speed, delay: delay },
    };
  }, [children, dir, speed, delay, animationControl]);

  let rootProps = {
    ref: rootRef,
  };

  return (
    <motion.div className={makeMods('ssf-animation', { full })} {...animation} {...rootProps}>{typeof children === "string" ? (
      <span>{children}</span>
    ) : children}</motion.div>
  );
};
