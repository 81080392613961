import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { QueryClientProvider } from "react-query";
import "swiper/swiper.scss";
import 'swiper/components/navigation/navigation.scss';
import "swiper/components/thumbs/thumbs.scss";

import { StringStorageProvider } from "@libs/ML/lib/stringStorage/StringStorageContext";
import { AppContainer } from './AppContainer';
import services from "@/services";
import "@/shared/scss/main.scss";
import { ConnectedRouter } from "connected-react-router";
import "@/libs/Utils/lib/vh/fix100vh";

const App = () => (
  <Provider store={services.store}>
    <ConnectedRouter history={services.history}>
      <QueryClientProvider client={services.queryClient}>
        <StringStorageProvider value={services.stringStorage}>
          <Suspense fallback={() => 'Loading...'}>
            <AppContainer />
          </Suspense>
        </StringStorageProvider>
      </QueryClientProvider>
    </ConnectedRouter>
  </Provider>
);

export default App;
