/**
 * @description The Header component.
 */
import React from "react";
import { NavLink } from "@libs/ML/lib/components/NavLink";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@shared/stringSource';
import { CALL, HOUSE } from '@/menuConfig';
import { useMenuState } from "@shared/hooks/useMenuState";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import "./Header.scss";
import { useIsScrolled } from "@shared/hooks/useIsScrolled";
import logo from './i/logo.svg';

type Props = {}

export const Header: React.FC<Props> = function (props) {
  const isMobile = useIsMobile();
  const { isOpen, toggle } = useMenuState();
  const scrolled = useIsScrolled();

  const mods = {
    scrolled,
    ['menu-opened']: isOpen,
  };

  const handleToggleMenu = () => {
    toggle();
  };

  if (isMobile) {
    return (
      <header className={makeMods("m-header", mods)}>
        <div className="m-header__cont">

          <NavLink to={CALL.path as string} className="m-header__button m-header__button--call" />

          <NavLink to="/" className="m-header__logo">
            <img src={logo} alt="Mayak"/>
          </NavLink>

          <button onClick={handleToggleMenu} className={makeMods("m-header__button", {
            burger: true,
            opened: isOpen,
          })} />

        </div>
      </header>
    );
  }

  return (
    <header className={makeMods("header", mods)}>
      <div className="header__cont">

        <div className="header__menu">
          <NavLink to={CALL.path as string} className="header__menu-el">
            <T p={ss.shared.header.callback} />
          </NavLink>
          <NavLink to="/tour" className="header__menu-el">
            <T p={ss.shared.header.tour} />
          </NavLink>
          <NavLink to={HOUSE.path as string} className="header__menu-el">
            <T p={ss.shared.header.apartments} />
          </NavLink>
        </div>

        <div className="header__toggle">
          <button onClick={handleToggleMenu} className={makeMods("header__burger", {
            opened: isOpen,
          })} />
        </div>

      </div>
    </header>
  );
};
