/**
 * @description The Floor component.
 */
import React, { ReactElement, useMemo, useRef } from "react";
import { FlatManager } from "@features/flats/services/flatManager";
import { FloorPlan } from "@features/flats/components/FloorPlan";
import { motion, AnimatePresence } from "framer-motion";
import { useRem } from "@shared/hooks/useRem";
import "./Floor.scss";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/features/flats/stringSource';
import { ReactComponent as WaterIcon } from "./i/water.svg";
import { ReactComponent as TreesIcon } from "./i/trees.svg";
import { FloorPlanLegend } from "@features/flats/components/FloorPlanLegend";
import { Compass } from "@features/flats/components/Compass";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { useIsFirstRender } from "@shared/hooks/useIsFirstRender";
import propImage from '@/shared/images/proportion1x1.png';
import {floorsConf} from "@features/flats/plans/conf";

type Props = {
  interactive: boolean;
  floorNumber: number;
  flatsManager: FlatManager;
  hovered: number | null;
  selected: number | null;
  onHover: (f: number | null) => any;
  onClick: (f: number) => any;
  renderNav: () => ReactElement<any, any>;
};

export const Floor: React.FC<Props> = function (props) {
  const { interactive, floorNumber, flatsManager, hovered, selected, onClick, onHover, renderNav } = props;
  const { rem } = useRem();
  const isFR = useIsFirstRender();
  const contRef = useRef<HTMLDivElement>(null);

  const isShowAvailable = useMemo(() => {
    return flatsManager.getFloorFlats(floorNumber).filter(f => f.isAvailable).length > 0;
  }, [flatsManager, floorNumber]);

  const isShowSold = useMemo(() => {
    return flatsManager.getFloorFlats(floorNumber).filter(f => !f.isAvailable).length > 0;
  }, [flatsManager, floorNumber]);

  const planAnimation = useMemo(() => {
    return {
      initial: {
        x: 0,
      },
      normal: () => ({
        x: 0,
        height: contRef.current ? contRef.current.getBoundingClientRect().height + 'px' : '100%',
      }),
      disabled: {
        x: rem(552 + 180 - ((552 - 250) / 2)),
        height: rem(250),
      }
    }
  }, [rem]);

  const normalElsAnimation = useMemo(() => {
    return {
      normal: {
        opacity: 1,
      },
      disabled: {
        opacity: 0,
      }
    }
  }, [rem]);

  const disabledElsAnimation = useMemo(() => {
    return {
      normal: {
        opacity: 0,
      },
      disabled: {
        opacity: 1,
      }
    }
  }, [rem]);

  const changePlanAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
        scale: 1 + 0.5 / 10,
      },
      show: {
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
          delay: 0.5,
        }
      },
      hide: {
        opacity: 0,
        scale: 1 - 1 / 10,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
        }
      }
    };
  }, []);

  const variant = selected ? 'disabled' : interactive ? 'normal' : 'disabled';
  const isDisabled = variant === 'disabled';
  const isNormal = variant === 'normal';
  const planDuration = 0.5;
  const elsDuration = 0.3;
  // get floor conf
  const svgConf = floorsConf[floorNumber];

  return (
    <div
      className={makeMods('floor-flat-floor', [variant])}
    >
      <div className="floor-flat-floor__cont">
        <div  ref={contRef} className="floor-flat-floor__cont-height" />
        <motion.div
          initial="initial"
          animate={variant}
          variants={planAnimation}
          transition={{ ease: 'easeInOut', duration: planDuration, delay: elsDuration }}
          className="floor-flat-floor__plan"
        >
          <img src={propImage} alt="" className="floor-flat-floor__plan-prop" />
          <AnimatePresence>
            <motion.div
              variants={changePlanAnimation}
              initial={isFR ? false : "initial"}
              animate="show"
              exit="hide"
              key={floorNumber}
              className="floor-flat-floor__plan-wrap"
            >
              <FloorPlan
                full
                interactive={interactive}
                floorNumber={floorNumber}
                flatsManager={flatsManager}
                hovered={hovered}
                selected={selected}
                onClick={onClick}
                onHover={onHover}
              />
            </motion.div>
          </AnimatePresence>

          <div className="floor-flat-floor__always-els">
            <div className="floor-flat-floor__icon-water">
              <WaterIcon />
            </div>
          </div>

          <motion.div
            initial={false}
            animate={variant}
            variants={normalElsAnimation}
            transition={{ ease: 'easeInOut', duration: elsDuration, delay: isNormal ? planDuration : 0 }}
            className="floor-flat-floor__normal-els">
            <div className="floor-flat-floor__streets">
              <div className="floor-flat-floor__street floor-flat-floor__street--top">
                <T p={ss.flats.floorPlan.streetTop} />
              </div>
              <div className="floor-flat-floor__street floor-flat-floor__street--bottom">
                <T p={ss.flats.floorPlan.streetBottom} />
              </div>
            </div>
            <div className="floor-flat-floor__legend">
              <FloorPlanLegend available={isShowAvailable} sold={isShowSold} />
              {/*{floorNumber !== 1 && (svgConf.mods ? svgConf.mods.indexOf('extended') === -1 : true) && (*/}
              {/*  <FloorPlanLegend available={isShowAvailable} sold={isShowSold} />*/}
              {/*)}*/}
            </div>
            <div className="floor-flat-floor__compass">
              <Compass />
            </div>
            <div className="floor-flat-floor__nav">
              {renderNav()}
            </div>
          </motion.div>

          <motion.div
            initial={false}
            animate={variant}
            variants={disabledElsAnimation}
            transition={{ ease: 'easeInOut', duration: elsDuration, delay: isNormal ? 0 : planDuration + elsDuration }}
            className="floor-flat-floor__disabled-els">
            <div className="floor-flat-floor__icon-trees">
              <TreesIcon />
            </div>
          </motion.div>

          <motion.div
              initial={false}
              animate={variant}
              variants={disabledElsAnimation}
              transition={{ ease: 'easeInOut', duration: elsDuration, delay: isNormal ? 0 : planDuration + elsDuration }}
              className="floor-flat-floor__notice">
            <div className="floor-flat-floor__notice-cont">
              <T html={true} p={ss.flats.flat.priceCurrencyExchangeNotice} />
            </div>
          </motion.div>

        </motion.div>
      </div>
    </div>
  );
};
