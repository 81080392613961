import { stringSource as shared } from "@shared/stringSource";
import { stringSource as posts } from "@features/posts/stringSource";
import { stringSource as flats } from "@features/flats/stringSource";
import { stringSource as singles } from "@features/singles/stringSource";

const all = {
  // put all stringSources here
  ...shared,
  ...posts,
  ...flats,
  ...singles,
};

export default all;
