/**
 * @description The FullscreenImage component.
 */
import React from "react";
import { Mods } from "@shared/props";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import mergeMods from "@libs/YandexBEM/lib/mergeMods";
import "./FullscreenImage.scss";

type Props = {
  alt: string;
  src: string;
  mobileSrc?: string;
  mods?: Mods;
  fillMode?: "cover" | "contain";
};

export const FullscreenImage: React.FC<Props> = function (props) {
  let isMobile = useIsMobile();
  let { mods = [], alt, src, mobileSrc, fillMode = 'contain' } = props;
  let srcToUse = isMobile && mobileSrc ? mobileSrc : src;

  return (
    <div className={makeMods("fs-image", mergeMods(mods, {
      [`fill-mode-${fillMode}`]: true,
    }))}>
      {fillMode !== "cover" && (
        <div className="fs-image__bg">
          <img src={srcToUse} alt={alt} className="fs-image__bg-i"/>
        </div>
      )}
      <div className="fs-image__fg">
        <img src={srcToUse} alt={alt} className="fs-image__fg-i"/>
      </div>
    </div>
  );
};
