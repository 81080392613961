import { IStringSource, makeMap } from "@libs/ML/lib/StringSource";

const strings = {
  shared: { // WARNING! Do not change the scope name!
    ok: "Ok",
    cancel: "Cancel",
    close: "Close",
    open: "Open",
    more: "Explore",
    error: {
      default: "Something went wrong. Please, try again later."
    },
    currency: {
      uah: 'UAH',
    },
    area: 'm2',
    areaLetter: 'sqr. m.',
    dateFormat: {
      default: "{{ day }} {{ monthName }}     {{ year }} Г"
    },
    header: {
      callback: "Callback",
      tour: "3D-tour",
      apartments: "Choose an apartments"
    },
    menu: {
      about: 'About the project',
      placement: 'Placement',
      home: 'Highlights',
      gallery: 'Gallery',
      team: 'Team',
      docs: 'Documents',
      chars: 'Characteristics',
      flats: 'Apartments',
      house: 'Visual choosing',
      search: 'Search by parameters',
      how: 'How to buy',
      info: 'Important information',
      events: 'Events',
      news: 'News',
      construction: 'Construction dynamics',
      camera: 'Web-camera',
      contacts: 'Contacts',
      salesOffice: 'Sales office',
      feedback: 'Feedback',
      call: 'Call me',
    },
    form: {
      field: {
        name: 'Your Name',
        phone: 'Phone',
        email: 'Email',
        message: 'Message',
      },
      validation: {
        required: `This field is required.`,
        email: `Email entered in wrong format.`,
        phone: `Phone entered in wrong format.`,
      },
      submit: 'Submit',
      sending: 'Sending...',
      result: {
        ok: `Form has been successfully sent`,
        error: `Something went wrong`
      }
    },
    p404: {
      title: '404',
      text: `Ошибка! <br/>К сожалению, запрашиваемая вами страница не найдена.<br/>Перейдите на главную страницу или воспользуйтесь меню сайта`,
      button: `перейти на главную`
    }
  }
};

export const stringSource: IStringSource & typeof strings = strings;

export const ss: typeof strings = makeMap(strings) as typeof strings;
