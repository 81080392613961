/**
 * @description The DummyPage component.
 */
import React, { ReactElement } from "react";
import { useQueryML } from "@shared/hooks/useQueryML";
import { getItems, INewsListItem } from "@features/posts/api/NewsAPI";
import { PageLayout } from "@shared/components/PageLayout/PageLayout";
import { NewsList } from "@features/posts/components/NewsList";
import { T } from "@/libs/ML/lib/components/T";
import { EVENTS_SECTION, NEWS } from "@/menuConfig";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgNews } from "@shared/images/bg/exports";

type Props = {}

export const Page = function (props: Props): ReactElement {
  const { isLoading, error, data } = useQueryML<INewsListItem[]>("newsList", getItems);

  const plProps = {
    title: <T p={NEWS.labelPath} />,
    isLoading,
    error: error?.message,
    breadcrumbs: EVENTS_SECTION,
    bg: <BackgroundSet conf={bgNews} />
  };

  return (
    <PageLayout {...plProps}>
      <NewsList
        news={data || []}
      />
    </PageLayout>
  );
};
