import APIService from "@libs/API/lib/APIService";
import { appConf } from "@/config";
import { preloadImagesSet } from "@libs/Preload/lib/image";
import { langDetector } from "@/services/langDetector";

const api = new APIService({
  baseURL: appConf.api.baseURL,
  imagesPreloadingHandler: (src: string[]) => {
    return new Promise<HTMLImageElement[]>(((resolve) => {
      preloadImagesSet(src).then(resolve).catch(resolve);
    }));
  },
  i18n: {
    isUseByDefault: true,
    getHeaders() {
      return {};
    }
  },
});

export default api;
