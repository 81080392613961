export function setVH(propName: string) {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--" + propName, vh + "px");
}

export function fix100vh(propName: string) {
  window.addEventListener('resize', function() {
    setVH(propName);
  });
  setVH(propName);
  setVH(propName + "-init");
}

fix100vh('vh');
