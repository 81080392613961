/**
 * @description The Search component.
 */
import React, { useMemo, useEffect, useCallback } from "react";
import { FlatManager } from "@features/flats/services/flatManager";
import { useSearchState } from "@features/flats/hooks/useSearchState";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { Table } from "./Table";
import { Cards } from "./Cards";
import { Filters } from "./Filters";
import { T } from "@/libs/ML/lib/components/T";
import { ss } from '@/features/flats/stringSource';
import "./Search.scss";
import { useDebouncedCallback } from "@shared/hooks/useDebouncedCallback";
import { useGoToFlat } from "@features/flats/hooks/useGoToFlat";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { useHoverState } from "@shared/hooks/useHoverState";
import Sticky from 'react-stickynode';
import { motion } from "framer-motion";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";

type Props = {
  flatsManager: FlatManager,
};

export const Search: React.FC<Props> = function (props) {
  const { flatsManager } = props;
  const isMobile = useIsMobile();
  const { hover: hoverFlat, setHover: setHoverFlat, latestHover: latestHoverFlat } = useHoverState<null|number>(null);
  const goToFlat = useGoToFlat(true);
  const setHoverFlatDebounced = useDebouncedCallback(setHoverFlat, 300);
  const desktopAnimation = useListAnimationVars();
  const mobileAnimation = useListAnimationVars();

  // get search state
  const {
    bedrooms,
    setBedrooms,
    areaFrom,
    areaTo,
    setArea,
    floorFrom,
    floorTo,
    setFloor,
    orderBy,
    orderDirection,
    setOrder,
    isMobileCardView,
    isMobileOpenedList,
    setIsMobileOpenedList,
    setIsMobileCardView,
    isSetDefaults,
    setIsSetDefaults
  } = useSearchState();

  // get borders for filters
  const borders = useMemo(() => {
    const area = flatsManager.getAreaBorders();
    const floors = flatsManager.getFloorsBorders();
    const bedrooms = flatsManager.getBedroomsOptions();
    let out = {
      areaBorders: area,
      isShowAreaFilter: area[0] !== area[1],
      floorsBorders: floors,
      isShowFloorsFilter: floors[0] !== floors[1],
      bedroomsOptions: bedrooms,
      isShowBedroomsFilter: bedrooms.length > 1,
      isShowAny: true,
    };
    out.isShowAny = out.isShowFloorsFilter || out.isShowAreaFilter || out.isShowBedroomsFilter;
    return out;
  }, [flatsManager]);

  // set default handler
  const setDefaults = useCallback(() => {
    if (borders.isShowBedroomsFilter) setBedrooms(borders.bedroomsOptions[0]);
    if (borders.isShowFloorsFilter) setFloor(borders.floorsBorders[0], borders.floorsBorders[1]);
    if (borders.isShowAreaFilter) setArea(borders.areaBorders[0], borders.areaBorders[1]);
    setOrder('floor', -1);
  }, [borders, setBedrooms, setArea, setFloor, setOrder]);

  // set filters default values
  useEffect(() => {
    if (!isSetDefaults) {
      setDefaults();
      setIsSetDefaults(true);
    }
  }, [borders]);

  // filter and order flats
  const flats = useMemo(() => {
    let t = flatsManager.table();
    if (borders.isShowAreaFilter) t = t.filterByArea(areaFrom, areaTo);
    if (borders.isShowFloorsFilter) t = t.filterByFloor(floorFrom, floorTo);
    if (borders.isShowBedroomsFilter) t = t.filterByBedrooms(bedrooms);
    t = t.sort(orderBy, orderDirection === -1);
    if (orderBy !== 'area') t = t.sortByIn('area', orderBy, true);
    return t.result();
  }, [flatsManager, bedrooms, areaFrom, areaTo, floorFrom, floorTo, orderBy, orderDirection, borders]);
  const isNotFound = flats.length < 1;

  // check are there any available flats
  const isHasAvailableFlats = useMemo(() => {
    return flatsManager.getAvailableFlats().filter(flatsManager.searchFilter).length > 0;
  }, [flatsManager]);

  // make common props
  const listProps = { flats, onGoToFlat: goToFlat };
  const tableProps = { ...listProps, orderDirection, orderBy, setOrder, isMobile };
  const filtersProps = { ...borders, bedrooms, areaFrom, areaTo, floorFrom, floorTo, setArea, setBedrooms, setFloor };

  // reset handler
  const handleReset = () => {
    setDefaults();
  };

  // hover preview
  const previewFlat = useMemo(() => {
    return flatsManager.flats.find(f => f.id === latestHoverFlat) || null;
  }, [latestHoverFlat, flatsManager]);

  return (
    <div className="search">
      <div className="search__cont">

        {isHasAvailableFlats ? (
          <div className="search__wrap">
            {!isMobile ? (
              <motion.div
                initial="hidden"
                animate="visible"
                variants={desktopAnimation.list}
                className="search__d-wrap"
              >
                {borders.isShowAny && (
                  <motion.div variants={desktopAnimation.item} className="search__d-side">
                    <Sticky top=".header">
                      <div className="search__d-side-wrap">
                        {isSetDefaults && borders.isShowAny && (
                          <div className={makeMods('search__d-filters', {hide: !!hoverFlat})}>
                            <Filters {...filtersProps} />
                          </div>
                        )}
                        <div className={makeMods('search__d-preview', {show: !!hoverFlat})}>
                          <img src={previewFlat?.urlPlanSVG} alt=""/>
                        </div>
                      </div>
                    </Sticky>
                  </motion.div>
                )}
                <motion.div variants={desktopAnimation.item} className="search__d-result">
                  {!isNotFound ? (
                    <Table onHover={setHoverFlatDebounced} {...tableProps} />
                  ) : (
                    <div className="search__empty-result">
                      <T p={ss.flats.search.nullOfFound} />
                    </div>
                  )}
                </motion.div>
              </motion.div>
            ) : (
              <motion.div
                initial="hidden"
                animate="visible"
                variants={mobileAnimation.list}
                className="search__m-wrap"
              >
                <motion.div variants={mobileAnimation.item}>
                  {borders.isShowAny && !isMobileOpenedList && (
                    <div className="search__m-filters">
                      {isSetDefaults && (
                        <Filters {...filtersProps} />
                      )}
                    </div>
                  )}
                  {(!borders.isShowAny || (borders.isShowAny && isMobileOpenedList)) && (
                    <div className="search__m-result">
                      <div className="search__m-view-toggle">
                        <div className="search__m-vt-text">
                          <T p={ss.flats.search.view} />
                        </div>
                        <button
                          onClick={() => setIsMobileCardView(false)}
                          className={makeMods('search__m-vt-button', {
                            vert: true,
                            active: !isMobileCardView,
                          })}
                        />
                        <button
                          onClick={() => setIsMobileCardView(true)}
                          className={makeMods('search__m-vt-button', {
                            hor: true,
                            active: isMobileCardView,
                          })}
                        />
                      </div>
                      <div className="search__m-reset">
                        <button onClick={handleReset} className="search__m-reset-button">
                          <T p={ss.flats.search.reset} />
                        </button>
                      </div>
                      <div className="search__m-result-cont">
                        {!isNotFound ? (
                          isMobileCardView ? <Cards {...listProps} /> : <Table {...tableProps} />
                        ) : (
                          <div className="search__empty-result">
                            <T p={ss.flats.search.nullOfFound} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </motion.div>
                {borders.isShowAny && (
                  <motion.div variants={mobileAnimation.item} className="search__m-button-cont">
                    <button onClick={() => setIsMobileOpenedList(!isMobileOpenedList)} className="search__m-button">
                      {isMobileOpenedList ? (
                        <T p={ss.flats.search.button.params} />
                      ) : (
                        <T p={ss.flats.search.button.showList} params={{ count: flats.length }} />
                      )}
                    </button>
                  </motion.div>
                )}
              </motion.div>
            )}
          </div>
        ) : (
          <motion.div className="search__empty">
            <T p={ss.flats.search.nullOfAvailable} />
          </motion.div>
        )}

      </div>
    </div>
  );
};
