import { RenderConfig } from '@libs/RRH/lib/route';
import { routesRender as posts } from '@features/posts/routesToRender';
import { routesRender as flats } from '@features/flats/routesToRender';
import { routesRender as singles } from '@features/singles/routesToRender';
import { appConf } from "@/config";

let items: RenderConfig[] = [
  // add new routes here
  ...posts,
  ...flats,
  ...singles,
];

// normalize trailing slash and add locale path param
// items.forEach(el => {
//   el.normalizeTrailingSlash(appConf.router.useTrailingSlash);
//   if (appConf.ml.enabled) el.path = `/:${appConf.ml.detector.routerLangParam}${el.path}`;
// });

export const routesRender: RenderConfig[] = items;
