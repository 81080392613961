import s1_1 from "./desktop/s1-1.jpg";
import s1_2 from "./desktop/s1-2.jpg";
import s1_3 from "./desktop/s1-3.jpg";
import s1_4 from "./desktop/s1-4.jpg";
import s2_1 from "./desktop/s2-1.jpg";
import s3_1 from "./desktop/s3-1.jpg";
import s3_2 from "./desktop/s3-2.jpg";
import s3_3 from "./desktop/s3-3.jpg";
import s3_4 from "./desktop/s3-4.jpg";
import s4_1 from "./desktop/s4-1.jpg";
import s4_2 from "./desktop/s4-2.jpg";
import s4_3 from "./desktop/s4-3.jpg";
import s4_4 from "./desktop/s4-4.jpg";
import s4_5 from "./desktop/s4-5.jpg";
import s5_1 from "./desktop/s5-1.jpg";
import s5_2 from "./desktop/s5-2.jpg";
import s5_3 from "./desktop/s5-3.jpg";
import s5_4 from "./desktop/s5-4.jpg";

import s1_1_m from "./mobile/s1-1.jpg";
import s1_2_m from "./mobile/s1-2.jpg";
import s1_3_m from "./mobile/s1-3.jpg";
import s1_4_m from "./mobile/s1-4.jpg";
import s2_1_m from "./mobile/s2-1.jpg";
import s3_1_m from "./mobile/s3-1.jpg";
import s3_2_m from "./mobile/s3-2.jpg";
import s3_3_m from "./mobile/s3-3.jpg";
import s3_4_m from "./mobile/s3-4.jpg";
import s4_1_m from "./mobile/s4-1.jpg";
import s4_2_m from "./mobile/s4-2.jpg";
import s4_3_m from "./mobile/s4-3.jpg";
import s4_4_m from "./mobile/s4-4.jpg";
import s4_5_m from "./mobile/s4-5.jpg";
import s5_1_m from "./mobile/s5-1.jpg";
import s5_2_m from "./mobile/s5-2.jpg";
import s5_3_m from "./mobile/s5-3.jpg";
import s5_4_m from "./mobile/s5-4.jpg";

export const sectionsImages = {
  section1: [
    { d: s1_1, m: s1_1_m },
    { d: s1_2, m: s1_2_m },
    { d: s1_3, m: s1_3_m },
    { d: s1_4, m: s1_4_m },
  ],
  section2: [
    { d: s2_1, m: s2_1_m },
  ],
  section3: [
    { d: s3_1, m: s3_1_m },
    { d: s3_2, m: s3_2_m },
    { d: s3_3, m: s3_3_m },
    { d: s3_4, m: s3_4_m },
  ],
  section4: [
    { d: s4_1, m: s4_1_m },
    { d: s4_2, m: s4_2_m },
    { d: s4_3, m: s4_3_m },
    { d: s4_4, m: s4_4_m },
    { d: s4_5, m: s4_5_m },
  ],
  section5: [
    { d: s5_1, m: s5_1_m },
    { d: s5_2, m: s5_2_m },
    { d: s5_3, m: s5_3_m },
    { d: s5_4, m: s5_4_m },
  ],
};
