/**
 * @description The TourPanorama component.
 */
import React, { useRef, useEffect, useMemo } from "react";
import { usePanorama } from "@features/singles/hooks/usePanorama";
import { appConf } from "@/config";
import { emitLoadingDone } from "@shared/events/loading";
import { AnimatePresence, motion } from "framer-motion";
import { PageLoader, pageLoaderAnimation } from "@shared/components/PageLoader";
import "./TourPanorama.scss";
import { useLocationQuery } from "@shared/hooks/useLocationQuery";
import { Back } from "@shared/components/Back";

type Props = {}

export const TourPanorama: React.FC<Props> = function (props) {
  const rendererRef = useRef<HTMLDivElement>(null);
  const { start: startNode } = useLocationQuery();
  const { loaded, goToScene } = usePanorama({
    element: rendererRef,
    panoramaURL: `${appConf.assetsURL}${appConf.assets.tour}`
  });

  useEffect(() => {
    emitLoadingDone();
    if (startNode !== undefined) {
      goToScene(`node${startNode}`);
    }
  }, [loaded]);

  // scene animation
  const sceneAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
      },
      show: {
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
          delay: 0.2
        }
      }
    };
  }, []);

  return (
    <div className="tour-panorama">
      <div className="tour-panorama__cont">

        <motion.div
          variants={sceneAnimation}
          initial="initial"
          animate={!loaded ? 'initial' : 'show'}
          className="tour-panorama__renderer-cont"
        >
          <div ref={rendererRef} className="tour-panorama__renderer" id="tour-panorama-renderer" />
          <Back to="/tour" />
        </motion.div>

        <AnimatePresence>
          {!loaded && (
            <motion.div
              key="loader"
              variants={pageLoaderAnimation}
              initial="initial"
              animate="show"
              exit="exit"
              className="tour-panorama__loader"
            >
              <PageLoader/>
            </motion.div>
          )}
        </AnimatePresence>

      </div>
    </div>
  );
};
