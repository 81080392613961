import { default as modsFromObject, ModsObject } from './modsFromObject';
import { default as modsFromArray, ModsArray } from './modsFromArray';

/**
 * Makes mods string
 * @param cn
 * @param mods
 */
export default function mm(cn: string, mods: ModsArray | ModsObject): string {
  if (mods instanceof Array) {
    return modsFromArray(cn, mods);
  }
  return modsFromObject(cn, mods);
}

export const makeMods = mm;
