/**
 * @description The DummyPage component.
 */
import React, { ReactElement } from "react";
import { House } from "@features/flats/components/House";
import { useIsMobile } from "@shared/hooks/useIsMobile";

type Props = {}

export const HousePage = function(props: Props): ReactElement {
  const isMobile = useIsMobile();

  return (
    <House
      key={isMobile ? 'mobile' : 'desktop'}
    />
  );
};
