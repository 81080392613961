import { useEffect, useState } from 'react';

export function useWaitFirstUserAction(enabled = true) {
  const [is, setIs] = useState(false);

  useEffect(() => {
    if (!enabled) return;
    const handler = () => setIs(true);
    window.addEventListener('mousemove', handler);
    window.addEventListener('keypress', handler);
    window.addEventListener('touchmove', handler);
    return () => {
      window.removeEventListener('mousemove', handler);
      window.removeEventListener('keypress', handler);
      window.removeEventListener('touchmove', handler);
    }
  }, [enabled]);

  return is;
}
