/**
 * @description The ContactsPage component.
 */
import React, { useEffect } from "react";
import { T } from "@libs/ML/lib/components/T";
import { CONTACT_SECTION, SALES } from "@/menuConfig";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgContact } from "@shared/images/bg/exports";
import { PageLayout } from "@shared/components/PageLayout";
import { FeedbackForm } from "@features/singles/components/FeedbackForm";
import { CallbackForm } from "@features/singles/components/CallbackForm";
import { ContactInfo } from "@features/singles/components/ContactInfo";
import { GoogleMap } from "@shared/components/GoogleMap";
import { CONTACTS_MAP_MARKER } from "@/googleMapConfig";
import "./ContactsPage.scss";
import { SomeSFInView } from "@shared/components/SomeSFInView";
import { useLocationQuery } from "@shared/hooks/useLocationQuery";
import { useBodyScrollToElement } from "@shared/hooks/useBodyScrollToElement";
import { useIsFirstRender } from "@shared/hooks/useIsFirstRender";

type Props = {}

export const ContactsPage: React.FC<Props> = function (props) {
  const plProps = {
    title: <T p={SALES.labelPath} />,
    breadcrumbs: CONTACT_SECTION,
    bg: <BackgroundSet conf={bgContact} />
  };
  const { scrollTo } = useBodyScrollToElement();
  const { section } = useLocationQuery();
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    let t = setTimeout(() => {
      scrollTo(section !== 'sales' ? `[data-section="${section}"]` : '.ws', 50, isFirstRender ? 0 : 0.5);
    }, isFirstRender ? 0 : 100);
    return () => clearTimeout(t);
  },  [section]);

  return (
    <PageLayout {...plProps}>
      <div className="contact">
        <div className="contact__cont">
          <div data-section="sales" className="contact__section">
            <div className="contact__info">
              <ContactInfo />
            </div>

            <div className="contact__map">
              <SomeSFInView full gap="5%" delay={1.05}>
                <GoogleMap center={CONTACTS_MAP_MARKER} mainMarker={CONTACTS_MAP_MARKER} zoom={17.51} />
              </SomeSFInView>
            </div>
          </div>

          <div data-section="feedback" className="contact__section">
            <FeedbackForm />
          </div>

          <div data-section="call" className="contact__section">
            <CallbackForm />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
