import { makeRoutesRenderConf, RenderMap } from '@libs/RRH/lib/route';
import { keys, routes } from './routes';
import { Page as ConstructionItemPage } from "./pages/ConstructionItemPage";
import { Page as ConstructionPage } from "./pages/ConstructionPage";
import { Page as DocumentsPage } from "./pages/DocumentsPage";
import { Page as GalleryItemPage } from "./pages/GalleryItemPage";
import { Page as GalleryPage } from "./pages/GalleryPage";
import { Page as NewsItemPage } from "./pages/NewsItemPage";
import { Page as NewsPage } from "./pages/NewsPage";
import { Page as TeamPage } from "./pages/TeamPage";

const renderMap: RenderMap = {
  [keys.newsList]: { contentComponent: NewsPage },
  [keys.newsItem]: { contentComponent: NewsItemPage },
  [keys.documentsList]: { contentComponent: DocumentsPage },
  [keys.dynamicList]: { contentComponent: ConstructionPage },
  [keys.dynamicItem]: { contentComponent: ConstructionItemPage },
  [keys.teamList]: { contentComponent: TeamPage },
  [keys.galleryList]: { contentComponent: GalleryPage },
  [keys.galleryItem]: { contentComponent: GalleryItemPage },
};

export const routesRender = makeRoutesRenderConf(renderMap, routes);
