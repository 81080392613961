/**
 * @description The GoogleMap component.
 */
import React, { useMemo, useState, Fragment, useEffect } from "react";
import { GoogleMap as GM, LoadScript, Marker } from "@react-google-maps/api";
import { GMAP_API_KEY, GMAP_STYLES } from "@/googleMapConfig";
import { useLocale } from "@libs/ML/lib/hooks/useLocale";
import { GoogleMapMarker } from "@shared/components/GoogleMap/GoogleMapMarker";
import "./GoogleMap.scss";
import { IPlacementMarker } from "@features/singles/placementMarkers";
import mmIcon from './i/mainMarkerIcon.svg';
import { useRem } from "@shared/hooks/useRem";

type Props = {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  options?: {
    [p: string]: any;
  };
  markers?: IPlacementMarker[];
  mainMarker?: {
    lat: number;
    lng: number;
  };
};

export const GoogleMap: React.FC<Props> = function (props) {
  const { center, zoom, options = {}, markers = [], mainMarker = null } = props;
  const { rem } = useRem();
  const locale = useLocale();
  const [loaded, setLoaded] = useState(false);

  const gmapProps = useMemo(() => {
    return {
      center,
      zoom,
      options: {
        styles: GMAP_STYLES as google.maps.MapTypeStyle[],
        disableDefaultUI: true,
        center,
        ...options,
      },
      onLoad: (gmapInstance: google.maps.Map) => {
        setTimeout(() => {
          gmapInstance.setCenter(center);
        }, 1000);
      },
    };
  }, [locale]);

  const mainMarkerProps = useMemo(() => {
    if (!loaded || !mainMarker) return null;
    const w = 31, h = 39;
    const width = rem(w), height = rem(h);
    return {
      icon: {
        url: mmIcon,
        anchor: new google.maps.Point(width / 2, height),
        size: new google.maps.Size(width, height),
        scaledSize: new google.maps.Size(width, height),
      },
      position: mainMarker,
    }
  }, [rem, loaded]);

  return (
    <div className="google-map">
      <div className="google-map__cont">

        <LoadScript
          language={locale as string}
          googleMapsApiKey={GMAP_API_KEY}
          onLoad={() => setLoaded(true)}
        >
          <GM
            mapContainerClassName="google-map__renderer"
            {...gmapProps}
          >
            {loaded && (
              <Fragment>
                {markers.map(el => (
                  <GoogleMapMarker
                    key={el.id}
                    label={el.label}
                    lat={el.point.lat}
                    lng={el.point.lng}
                    icon={el.icon}
                    isMain={el.isMain}
                  />
                ))}
                {mainMarkerProps && (
                  <Marker {...mainMarkerProps} />
                )}
              </Fragment>
            )}
          </GM>
        </LoadScript>

      </div>
    </div>
  );
};
