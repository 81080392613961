/**
 * @description The Close component.
 */
import React from "react";
import { Mods } from "@shared/props";
import mergeMods from "@libs/YandexBEM/lib/mergeMods";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { Link } from "@libs/ML/lib/components/Link";
import "./Close.scss";

type Props = {
  to?: string;
  onClick?: (...args: any) => any;
  mods?: Mods
};

export const Close: React.FC<Props> = function (props) {
  let { to, onClick, mods = [], children, ...rest } = props;

  let commonProps = {
    className: makeMods("close", mergeMods(mods, {
      button: !!onClick,
      link: !!to,
    })),
    to,
    onClick,
    children: (
      <div className="close__icon">{children}</div>
    ),
    ...rest,
  };

  if (to) return (
    <Link {...({...commonProps, to: to as string})}/>
  );

  return (
    <button {...commonProps} />
  );
};

