/**
 * @description The ParallaxedImage component.
 */
import React, { useMemo } from "react";
import "./ParallaxedImage.scss";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { useRem } from "@shared/hooks/useRem";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { randomIntBetween } from "@libs/Mocks/random";

export type SharedProps = {
  randomGapFrom?: number,
  randomGapTo?: number,
};

type Props = {
  src: string,
  mobileSrc?: string,
  alt?: string,
  propWidth?: number,
  propHeight?: number,
  gap?: number,
  fill?: boolean;
  stat?: boolean;
} & SharedProps;

export const ParallaxedImage: React.FC<Props> = function (props) {
  const { src, gap = 100, mobileSrc = null, alt = '', propHeight = 9, propWidth = 16, fill = false, randomGapFrom = 0, randomGapTo = 0, stat = false } = props;
  const isMobile = useIsMobile();
  const { scrollYProgress } = useViewportScroll();
  const { rem } = useRem();

  const gapToUse = useMemo(() => {
    return (randomGapFrom && randomGapTo) ? randomIntBetween(randomGapFrom, randomGapTo) : gap;
  }, [randomGapFrom, randomGapTo, gap]);
  const shift = useTransform(scrollYProgress, [0, 1], [rem(-gapToUse), rem(gapToUse)]);
  const contStyles = {
    top: -rem(gapToUse),
    bottom: -rem(gapToUse),
  };

  let srcToUse = isMobile && mobileSrc ? mobileSrc : src;

  return (
    <div className={makeMods("pxd-image", { fill })}>
      {!fill && (
        <div className="pxd-image__prop" style={{paddingTop: `${propHeight / propWidth * 100}%`}} />
      )}
      <div style={stat ? {} : contStyles} className="pxd-image__cont">
        <motion.div style={stat ? {} : {y: shift}} className="pxd-image__wrap">
          <img className="pxd-image__img" src={srcToUse} alt={alt}/>
        </motion.div>
      </div>
    </div>
  );
};
