/**
 * @description The FloorFlat component.
 */
import React, { useEffect, useState, useCallback, useMemo, Fragment } from "react";
import {FlatManager, IFlat} from "@features/flats/services/flatManager";
import { useHoverState } from "@shared/hooks/useHoverState";
import { useFloorNav } from "@features/flats/hooks/useFloorNav";
import { Back } from "@shared/components/Back";
import { makeFloorFlatLink } from "@features/flats/utils/makeFloorFlatLink";
import { HoverFlatInfo } from "./HoverFlatInfo";
import { Floor } from "./Floor";
import { Flat } from "./Flat";
import { BookingForm } from "./BookingForm";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { FloorNav } from "@features/flats/components/FloorNav";
import { useGoToWithLocale } from "@libs/ML/lib/hooks/useGoToWithLocale";
import "./FloorFlat.scss";
import { useFullHeight } from "@features/flats/ducks/useFullHeight";
import { useLocation } from "react-router";
import { motion, AnimatePresence } from "framer-motion";
import { useRem } from "@shared/hooks/useRem";
import { useBodyScrollTo } from "@shared/hooks/useBodyScrollTo";

type Props = {
  flatsManager: FlatManager;
  floorNumber: number;
  flatID: number | null;
};

export const FloorFlat: React.FC<Props> = function (props) {
  const { flatsManager, floorNumber, flatID } = props;
  const { latestHover, setHover, reset: resetHover } = useHoverState<null|number>();
  const [formOpened, setFormOpened] = useState(false);
  const floorNav = useFloorNav(floorNumber, flatsManager);
  const goTo = useGoToWithLocale();
  const height = useFullHeight();
  const location = useLocation();
  const { scrollTo } = useBodyScrollTo();

  // get flat data
  const flat = useMemo(() => {
    return flatID ? flatsManager.getFlatByID(flatID) as IFlat : null;
  }, [flatID, flatsManager]);

  // set hover for case whether started from flat view
  useEffect(() => {
    if (flatID) setHover(flatID);
  }, [flatID, setHover]);

  // reset hover on floor change
  useEffect(() => {
    resetHover(null);
  }, [floorNumber, resetHover]);

  // get hover flat data
  const hoveredFlatData = useMemo(() => {
    let flat = flatsManager.getFlatByID(flatID ? flatID : latestHover || 0);
    return flat && flat.isAvailable ? flat : null;
  }, [flatsManager, flatID, latestHover]);

  // get back button props
  const backProps = useMemo(() => {
    if (formOpened) return {
      onClick: () => {
        scrollTo(0);
        setFormOpened(false);
      },
    };
    if (floorNumber !== null && flatID !== null) {
      if ((location.state as any)?.fromSearch) {
        return  {
          to: '/apartments/search'
        }
      }
      return {
        to: makeFloorFlatLink(floorNumber),
      }
    }
    return {
      to: makeFloorFlatLink(),
    }
  }, [formOpened, floorNumber, flatID, scrollTo, location.state]);

  // flags
  const isFlatOpened = flatID !== null && !!hoveredFlatData;
  const opedFlatData = hoveredFlatData;

  // flat click handler
  const handleGoToFlat = useCallback((flatID: number) => {
    let flat = flatsManager.getFlatByID(flatID);
    return goTo(makeFloorFlatLink(flat?.floorNumber, flatID));
  }, [goTo, flatsManager]);

  // booking form animation
  const formAnim = useMemo(() => {
    return {
      content: {
        visible: {
          opacity: 1,
          transition: {
            ease: 'easeInOut',
            duration: 0.4,
          }
        },
        hidden: {
          opacity: 0,
          transition: {
            ease: 'easeInOut',
            duration: 0.4,
          }
        }
      },
      form: {
        visible: {
          opacity: 1,
          transition: {
            ease: 'easeInOut',
            duration: 0,
          }
        },
        hidden: {
          opacity: 0,
          transition: {
            ease: 'easeInOut',
            duration: 0.4,
          }
        },
      }
    }
  }, []);

  // floor initial animation
  const floorInitAnim = useMemo(() => {
    return {
      initial: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
        transition: {
          delay: flatID ? 0.82 : 0.35,
          duration: flatID ? 0.3 : 0.5,
          ease: 'easeInOut',
        }
      }
    }
  }, [flatID]);

  return (
    <Fragment>
      <div className={makeMods('floor-flat', {
        'flat-opened': isFlatOpened,
        'form-opened': formOpened,
      })}>
        <div className="floor-flat__cont" style={{ height: `${height}px` }}>

          <div className="floor-flat__main">
            <motion.div
              initial="visible"
              animate={formOpened ? 'hidden' : 'visible'}
              variants={formAnim.content}
              className="floor-flat__main-cont"
            >
              <div className="floor-flat__floor">
                <div className="floor-flat__floor-cont">
                  <motion.div
                    variants={floorInitAnim}
                    initial="initial"
                    animate="visible"
                    className="floor-flat__floor-wrap"
                  >
                    <Floor
                      interactive={!isFlatOpened}
                      floorNumber={floorNumber}
                      flatsManager={flatsManager}
                      selected={flatID}
                      hovered={latestHover}
                      onHover={setHover}
                      onClick={handleGoToFlat}
                      renderNav={() => (
                        <Fragment>
                          {floorNav.availableFloorsNumbers.length > 1 && (
                            <FloorNav
                              mode="link"
                              current={floorNav.current as number}
                              prev={floorNav.prev as number}
                              next={floorNav.next as number}
                            />
                          )}
                        </Fragment>
                      )}
                    />
                  </motion.div>
                </div>
              </div>

              <AnimatePresence>
                {isFlatOpened && (
                  <div className="floor-flat__flat">
                    <Flat
                      renderFloorNav={() => (
                        <FloorNav
                          mode="link"
                          viewOnly={true}
                          current={floorNav.current as number}
                          prev={floorNav.prev as number}
                          next={floorNav.next as number}
                        />
                      )}
                      flat={opedFlatData}
                      onOpenBook={() => setFormOpened(true)}
                    />
                  </div>
                )}
              </AnimatePresence>
            </motion.div>
          </div>

          <div className="floor-flat__side">
            <AnimatePresence>
              {hoveredFlatData && <HoverFlatInfo flat={hoveredFlatData} />}
            </AnimatePresence>
          </div>

        </div>

        <AnimatePresence>
          {formOpened && flatID && flat && (
            <motion.div
              initial="hidden"
              animate={formOpened ? 'visible' : 'hidden'}
              exit="hidden"
              variants={formAnim.form}
              className="floor-flat__main-form"
              style={{ minHeight: `${height}px` }}
            >
              <BookingForm flatID={flat.number} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <Back {...backProps} />
    </Fragment>
  );
};
