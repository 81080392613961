export interface ModsObject {
  [key: string]: boolean;
}

/**
 * Generate class names string with BEM mods from Object
 * @param cn
 * @param mods
 */
export default function modsFromObject(cn = 'cn', mods: ModsObject = {}): string {
  return `${cn} ${Object.keys(mods)
    .filter((m) => mods[m])
    .map((m) => `${cn}--${m}`)
    .join(' ')}`.trim();
}
