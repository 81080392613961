/**
 * @description The NavLink component.
 */
import React from "react";
import { NavLinkProps, NavLink as _NavLink } from "react-router-dom";

import { useLocale } from "@libs/ML/lib/hooks/useLocale";

type Props = {} & NavLinkProps;

export const NavLink: React.FC<Props> = function ({ to, ...props }) {
  let locale = useLocale();

  return (
    <_NavLink to={`/${locale}${to}`} {...props} />
  );
};
