import { Config } from '@libs/RRH/lib/route';
import { routes as posts } from '@features/posts/routes';
import { routes as flats } from '@features/flats/routes';
import { routes as singles } from '@features/singles/routes';
import { appConf } from "@/config";

let items: Config[] = [
  // add new routes here
  ...posts,
  ...flats,
  ...singles,
];

export const routesToKeyMap: { [p: string]: string } = {};
items.forEach(el => {
  routesToKeyMap[el.id] = el.path;
});

// normalize trailing slash and add locale path param
items.forEach(el => {
  el.normalizeTrailingSlash(appConf.router.useTrailingSlash);
  if (appConf.ml.enabled) el.path = `/:${appConf.ml.detector.routerLangParam}${el.path}`;
});

export const routes: Config[] = items;
