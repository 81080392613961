import { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import { easeInOutQuad } from "@libs/Utils/lib/easing/easeInOutQuad";

export function useBodyScrollTo() {
  const [animationPlaying, setAnimationPlaying] = useState(false);
  const animateTORef = useRef(0);
  const animationPlayingRef = useRef(animationPlaying);
  const setAnimationPlayingRef = useRef(setAnimationPlaying);

  useEffect(() => {
    animationPlayingRef.current = animationPlaying;
    setAnimationPlayingRef.current = setAnimationPlaying;
  }, [animationPlaying, setAnimationPlaying]);

  const html = useMemo(() => {
    return document.querySelector('html') as HTMLHtmlElement;
  }, []);

  const scrollTo = useCallback((to: number, duration: number = 0.5) => {
    if (animationPlayingRef.current) return;
    setAnimationPlayingRef.current(true);

    if (duration === 0) {
      html.scrollTop = to;
      setAnimationPlayingRef.current(false);
      return;
    }

    let start = html.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    const animateScroll = function () {
      currentTime += increment;
      html.scrollTop = easeInOutQuad(currentTime, start, change, duration * 1000);
      if (currentTime < duration * 1000) {
        animateTORef.current = window.setTimeout(animateScroll, increment);
      } else {
        setAnimationPlayingRef.current(false);
      }
    };
    animateScroll();
  }, []);

  const scrollDown = useCallback((vhDistance?: number) => {
    let to: number;

    if (!vhDistance) {
      to = html.scrollTop + (html.scrollHeight - window.innerHeight)
    } else {
      to = html.scrollTop + (window.innerHeight * (vhDistance as number) / 100);
    }

    scrollTo(to);
  }, []);

  const scrollUp = useCallback((vhDistance?: number) => {
    let to: number;

    if (!vhDistance) {
      to = 0;
    } else {
      to = html.scrollTop - (window.innerHeight * (vhDistance as number) / 100);
    }

    scrollTo(to);
  }, []);

  const stop = useCallback(() => {
    clearTimeout(animateTORef.current);
    setAnimationPlayingRef.current(false);
  }, []);

  const getScrollTop = useCallback(() => {
    return html.scrollTop;
  }, []);

  return {
    scrollTo,
    scrollUp,
    scrollDown,
    stop,
    animationPlaying,
    getScrollTop,
  };
}
