import { Config } from "@libs/RRH/lib/route";
import { featureConf } from "./config";

const NAMESPACE = featureConf.name;

export const keys = {
  house: `${NAMESPACE}/house`,
  floorFlat: `${NAMESPACE}/floorFlat`,
  floor: `${NAMESPACE}/floor`,
  flat: `${NAMESPACE}/flat`,
  search: `${NAMESPACE}/search`,
};

export const routes = [
  new Config({
    id: keys.house,
    path: "/apartments",
  }),
  new Config({
    id: keys.floorFlat,
    path: "/apartments/floor-:floor-flat-:flat",
    exact: false,
    desktopOnly: true,
  }),
  new Config({
    id: keys.floor,
    path: "/apartments/floor-:floor-flat-none",
    mobileOnly: true,
  }),
  new Config({
    id: keys.flat,
    path: "/apartments/floor-:floor-flat-:flat",
    mobileOnly: true,
  }),
  new Config({
    id: keys.search,
    path: "/apartments/search",
  }),
];
