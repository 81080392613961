/**
 * @description The Hidden component.
 */
import React, { useEffect, useRef, useState } from "react";
import "./Hidden.scss";

type Props = {
  isShow: boolean;
  speed?: number;
};

export const Hidden: React.FC<Props> = function ({ isShow = false, speed = 500, children }) {
  const rootRef = useRef<HTMLDivElement>(null),
    contRef = useRef<HTMLDivElement>(null),
    [updatesCount, setUpdatesCount] = useState(0);

  useEffect(() => {
    if (contRef.current && rootRef.current) {
      let contBCR = contRef.current.getBoundingClientRect();
      if (isShow) {
        rootRef.current.style.height = `${contBCR.height}px`;
      } else {
        rootRef.current.style.height = `${0}px`;
      }
    }
  });

  useEffect(() => {
    if (contRef.current && rootRef.current) {
      let nextCount = updatesCount + 1;
      if (nextCount < 2) {
        setUpdatesCount(nextCount);
        return;
      }

      let timeout: number;

      let contBCR = contRef.current.getBoundingClientRect();

      if (isShow) {
        rootRef.current.style.height = `${contBCR.height}px`;
        timeout = window.setTimeout(() => {
          rootRef.current && (rootRef.current.style.height = 'auto');
        }, speed);
      } else {
        rootRef.current.style.height = `${contBCR.height}px`;
        rootRef.current.style.transition = `none`;
        timeout = window.setTimeout(() => {
          if (rootRef.current) {
            rootRef.current.style.height = `${0}px`;
            rootRef.current.style.transition = `all ${speed}ms`;
          }
        }, 100);
      }

      return () => {
        clearTimeout(timeout);
      }
    }
  }, [isShow]);

  const styles = updatesCount > 0 ? {
    transition: `all ${speed}ms`
  } : {};

  return (
    <div ref={rootRef} style={styles} className="hidden">
      <div ref={contRef} className="hidden__cont">
        <div className="hidden__wrapper">{children}</div>
      </div>
    </div>
  );
};
