/**
 * @description The DummyPage component.
 */
import React, { ReactElement } from "react";
import { useRouteMatch } from "react-router";
import { IGalleryAlbum } from "@features/posts/api/GalleryAPI";
import { PageLayout } from "@shared/components/PageLayout";
import { GalleryAlbum } from "@features/posts/components/GalleryAlbum";
import { useGalleryAlbum } from "@features/posts/hooks/useGallery";

type Props = {};

export const Page = function (props: Props): ReactElement {
  const match = useRouteMatch<{ slug: string | undefined }>();
  const { isLoading, error, data } = useGalleryAlbum(match.params.slug);

  const plProps = {
    isLoading,
    error: error?.message,
    mods: ['fullpage'],
  };

  return (
    <PageLayout {...plProps}>
      {() => <GalleryAlbum {...(data || {} as IGalleryAlbum)} />}
    </PageLayout>
  );
};
