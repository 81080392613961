/**
 * @description The GalleryAlbumsList component.
 */
import React from "react";
import SwiperCore, { Navigation, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IGalleryAlbum } from "@features/posts/api/GalleryAPI";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { Link } from "@libs/ML/lib/components/Link";
import "./GalleryAlbumsList.scss";
import { useRem } from "@shared/hooks/useRem";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";
import { motion } from "framer-motion";

type Props = {
  items: IGalleryAlbum[];
};

SwiperCore.use([Navigation, Keyboard]);

export const GalleryAlbumsList: React.FC<Props> = function (props) {
  const isMobile = useIsMobile();
  const { items } = props;
  const { rem } = useRem(20);
  const variants = useListAnimationVars({
    itemYGap: 0,
  });

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants.list}
      className="gallery-albums-list">
      <div className="gallery-albums-list__cont">

        <Swiper
          keyboard
          navigation
          spaceBetween={rem(isMobile ? 28 : 75, true)}
          slidesPerView={isMobile ? 1.3 : 2.7}
          className="gallery-albums-list__slider">
          {items.map(a => (
            <SwiperSlide key={a.id}>
              <Link to={`/gallery/${a.id}`} component={motion.a} variants={variants.item} className="gallery-albums-list__el">
                <div className="gallery-albums-list__el-cont">
                  <div className="gallery-albums-list__el-bg">
                    <img src={isMobile && a.coverMobile ? a.coverMobile.src : a.cover.src}
                         alt={isMobile && a.coverMobile ? a.coverMobile.alt : a.cover.alt}
                         className="gallery-albums-list__el-i"/>
                  </div>
                  <div className="gallery-albums-list__el-fg">
                    <div className="gallery-albums-list__el-title">{a.title}</div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>

      </div>
    </motion.div>
  );
};
