import { useState, useEffect, useMemo } from 'react';
import { useQueryML } from "@shared/hooks/useQueryML";
import { getAlbums, IGalleryAlbum } from "@features/posts/api/GalleryAPI";

export function useGalleryList() {
  const { isLoading, error, data } = useQueryML<IGalleryAlbum[]>(["gallery"], getAlbums);

  // todo add images preloading

  return {
    isLoading,
    error,
    data,
  }
}

export function useGalleryAlbum(id?: string) {
  const [item, setItem] = useState<IGalleryAlbum|null>(null);
  const [isLoadingItem, setIsLoadingItem] = useState(true);
  const { isLoading, error, data } = useQueryML<IGalleryAlbum[]>(["gallery"], getAlbums);

  // todo add images preloading

  useEffect(() => {
    setIsLoadingItem(isLoading);
    if (!isLoading && data) {
      let found = data.find(a => a.id === id);
      if (found) {
        setItem(found);
      }
    }
  }, [isLoading, data, error]);

  return {
    isLoading: isLoadingItem,
    data: item,
    error,
  };
}
