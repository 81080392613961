import { makeObjectPropsPathMap } from "@libs/ML/lib/utils/pathGenerator";

export interface IStringSource {
  [p: string]: string | IStringSource;
}

export interface IStringSourcePathMap {
  [p: string]: string | IStringSourcePathMap;
}

export function makeMap(ss: IStringSource): IStringSourcePathMap {
  return makeObjectPropsPathMap(ss);
}
