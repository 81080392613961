import { uk, enUS, ru } from 'date-fns/locale';

const conf = {
  i18nMap: {
    en: enUS,
    uk: uk,
    ru: ru,
  }
};

export const dateConf: typeof conf & {
  i18nMap: {
    [p: string]: Locale
  }
} = conf;
