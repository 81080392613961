import { api } from "@/services";
import { QueryKey } from "react-query";
import { IUseQueryMLExtraParams } from "@shared/hooks/useQueryML";

export interface IHTBOption {
  title: string;
  subtitle?: string;
  content: string;
}

export interface IHTBStep {
  title: string;
  subtitle?: string;
  attachment?: string;
}

export interface IHTBResponse {
  options: [IHTBOption, IHTBOption, IHTBOption];
  steps: IHTBStep[];
}

export function getData(params: {queryKey: QueryKey}): Promise<IHTBResponse> {
  let extraQueryParams = params.queryKey[params.queryKey.length - 1] as IUseQueryMLExtraParams;
  return api.get(`/how-to-buy/`, undefined, {
    headers: {
      'Accept-Language': extraQueryParams.locale,
    },
    transformResponse: (raw) => {
      let response: {
        blocks: {
          "purchase-options": {
            block_items: {
              title: string;
              subtitle?: string;
              content: string;
            }[]
          },
          "purchase-procedure": {
            block_items: {
              title: string;
              subtitle?: string;
              attachment?: string;
            }[]
          }
        }
      } = JSON.parse(raw);

      return {
        options: response.blocks["purchase-options"].block_items.map(el => ({
          title: el.title,
          content: el.content,
          subtitle: el.subtitle,
        })),
        steps: response.blocks["purchase-procedure"].block_items.map(el => ({
          title: el.title,
          attachment: el.attachment,
          subtitle: el.subtitle,
        })),
      };
    },
  });
}
