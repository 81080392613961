import { useEffect, useRef, useState, RefObject, useCallback } from "react";

export interface IUsePanoramaOptions {
  element: RefObject<HTMLElement>;
  panoramaURL: string;
}

export function usePanorama(options: IUsePanoramaOptions) {
  const { element, panoramaURL } = options;
  const [loaded, setLoaded] = useState(false);
  const panoRef = useRef<any|null>(null);

  useEffect(() => {
    let pano = new (window as any).pano2vrPlayer(element.current?.getAttribute('id'));
    panoRef.current = pano;
    let skin = new (window as any).pano2vrSkin(pano);
    pano.readConfigUrlAsync(panoramaURL, () => {
      setLoaded(true);
    });
  }, []);

  const goToScene = useCallback((scene: string) => {
    if (panoRef.current) {
      panoRef.current.openNext(`{${scene}}`, '');
    }
  }, [])

  return {
    loaded,
    goToScene,
  };
}