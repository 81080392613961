/**
 * @description The Breadcrumbs component.
 */
import React, { useMemo } from "react";
import { IMenuItem, IMenuSection } from "@/menuConfig";
import { NavLink } from "@libs/ML/lib/components/NavLink";
import { T } from "@libs/ML/lib/components/T";
import "./Breadcrumbs.scss";

type Props = {
  section: IMenuSection;
};

export const Breadcrumbs: React.FC<Props> = function (props) {
  const { section } = props;
  const items = useMemo(() => {
    return section.items.filter(el => !el.ignoreInBreadcrumbs);
  }, [section]);

  const renderItem = (item: IMenuItem) => {
    if (item.path) {
      return <NavLink
        className="breadcrumbs__link"
        to={item.path}
        activeClassName={item.disableActiveClass ? 'active-disabled' : 'active'}
        exact={!!item.breadcrumbsExact}><T p={item.labelPath} /></NavLink>
    }
    return <span className="breadcrumbs__link"><T p={item.labelPath} /></span>
  };

  return (
    <nav className="breadcrumbs">
      <div className="breadcrumbs__cont">
        <h3 className="breadcrumbs__title">
          {section.heading && (
            <span>{renderItem(section.heading)}&nbsp;&nbsp;/</span>
          )}
        </h3>
        <div className="breadcrumbs__items">
          {items.map(item => (
            <div key={item.labelPath} className="breadcrumbs__item">
              {renderItem(item)}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};
