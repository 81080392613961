import { useQuery } from 'react-query';
import { useLocale } from "@libs/ML/lib/hooks/useLocale";
import { getFlatsData } from "@features/flats/api/FlatsAPI";
import { FlatManager } from "@features/flats/services/flatManager";
import { AxiosError } from "axios";

export function useFlatsData() {
  const locale = useLocale();
  return useQuery<FlatManager, AxiosError>(['flats', locale], async () => {
    return await getFlatsData(locale);
  });
}
