import { IConstructionListItem } from "@features/posts/api/ConstructionAPI";
import { useLocale } from "@libs/ML/lib/hooks/useLocale";
import { useEffect, useMemo, useState } from "react";
import { getAvailableYearsAndMonths } from "@libs/Utils/lib/date/getAvailableYearsAndMonths";
import { dateConf } from "@/dateConfig";
import format from "date-fns/format";
import { useLocaleString } from "@libs/ML/lib/hooks/useLocaleString";
import { ss } from '../stringSource';

export interface IConstructionSectionDate {
  year: number;
  month: {
    name: string;
    number: number;
  };
}

export interface IConstructionSection {
  date: IConstructionSectionDate;
  items: IConstructionListItem[];
}

export function useConstructionList(items: IConstructionListItem[]) {
  const locale = useLocale();

  // get all available dates
  const availableDates = useMemo(() => {
    return getAvailableYearsAndMonths(items.map(l => l.date), dateConf.i18nMap[locale || 'en']);
  }, [items, locale]);

  // filters logic
  const [yearFilter, setYearFilter] = useState<number|null>(null);
  const [monthFilter, setMonthFilter] = useState<number|null>(null);
  useEffect(() => {
    setYearFilter(null);
    setMonthFilter(null);
  }, [availableDates]);
  useEffect(() => {
    setMonthFilter(null);
  }, [yearFilter]);

  // filters options
  const allYearsLabel = useLocaleString(ss.posts.construction.filter.yearsAll);
  const allMonthsLabel = useLocaleString(ss.posts.construction.filter.monthsAll);
  const yearOptions = useMemo(() => {
    return [
      { label: allYearsLabel, value: null },
      ...availableDates.map(year => ({
        label: year.year,
        value: year.year,
      }))
    ]
  }, [allYearsLabel, availableDates]);
  const monthsOptions = useMemo(() => {
    const allOption = { label: allMonthsLabel, value: null };
    if (!yearFilter) return [allOption];
    return [
      allOption,
      ...(availableDates.find(y => y.year === yearFilter)?.months.map(m => ({
        label: m.name,
        value: m.number,
      })) || []),
    ]
  }, [allMonthsLabel, availableDates, yearFilter]);


  // filter, sort, reverse items
  const filteredItems = useMemo(() => {
    return items.filter(el => {
      let year = parseInt(format(el.date, 'yyyy')),
        month = parseInt(format(el.date, 'MM'));
      if (yearFilter || monthFilter) {
        // if filters are set - use their
        return (yearFilter ? year === yearFilter : true) && (monthFilter ? month === monthFilter : true);
      }
      // if filters aren't set - show items only for last 3 months of last year
      return year === availableDates[0].year && (
        month === availableDates[0].months[0].number ||
        month === availableDates[0].months[1].number ||
        month === availableDates[0].months[2].number
      )
    }).sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    }).reverse();
  }, [items, yearFilter, monthFilter]);

  // get filtered available dates
  const filteredAvailableDates = useMemo(() => {
    return getAvailableYearsAndMonths(filteredItems.map(l => l.date), dateConf.i18nMap[locale || 'en']);
  }, [filteredItems, locale]);

  // split items to the section by months + years
  const sections: IConstructionSection[] = useMemo(() => {
    const months = filteredAvailableDates.reduce((acc: IConstructionSectionDate[], el) => {
      return [...acc, ...el.months.map(m => ({
        year: el.year,
        month: {
          ...m,
        }
      }))]
    }, []);
    return months.map(date => {
      return {
        date,
        items: filteredItems.filter(el => {
          let year = parseInt(format(el.date, 'yyyy')),
            month = parseInt(format(el.date, 'MM'));
          return year === date.year && month === date.month.number
        }),
      };
    });
  }, [filteredAvailableDates]);

  return {
    sections,
    setYearFilter,
    setMonthFilter,
    monthFilter,
    yearFilter,
    availableDates,
    yearOptions,
    monthsOptions,
  }
}
