/**
 * @description The HomeHeroSVGContent component.
 */
import React, { useRef, useEffect, useState, useMemo } from "react";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { useRem } from "@shared/hooks/useRem";

type Props = {
  frameBgRef: React.RefObject<SVGGElement>;
  frameMiddleRef: React.RefObject<SVGGElement>;
  frameFgRef: React.RefObject<SVGGElement>;
  images: {
    d: {
      src: string;
      width: number;
      height: number;
    };
    m: {
      src: string;
      width: number;
      height: number;
    };
  }[]
};

type ImageProps = {
  width: number;
  height: number;
  src: string;
  canvasWidth: number;
  canvasHeight: number;
};

const Image: React.FC<ImageProps> = function (props) {
  const { width, height, src, canvasHeight, canvasWidth } = props;

  const imageProps = useMemo(() => {
    let sizeProp = canvasWidth / canvasHeight;
    let imageProp = width / height;
    let ratio = sizeProp < imageProp ? canvasHeight / height : canvasWidth / width;
    let w = width * ratio;
    let h = height * ratio;
    return {
      width: w,
      height: h,
      x: (canvasWidth - w) / 2,
      y: (canvasHeight - h) / 2,
      // transform: `translate(${sizeProp <= 1 && imageProp > 1 ? w / 2 - canvasWidth / 2 : '0'}, ${sizeProp <= 1 && imageProp > 1 ? '0' : h / 2 - canvasHeight / 2})`,
    }
  }, [width, height, canvasHeight, canvasWidth]);

  return (
    <g width={canvasWidth} height={canvasHeight} transform-origin="center" className="home-hero-svg__image">
      <image xlinkHref={src} {...imageProps} />
    </g>
  );
}

export const HomeHeroSVGContent: React.FC<Props> = function (props) {
  const { images, frameBgRef, frameFgRef, frameMiddleRef } = props;
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const { rem } = useRem();
  const padding = rem(!isMobile ? 124 : 60);
  const borderWidth = rem(!isMobile ? 3 : 2);

  useEffect(() => {
    const handleResize = () => {
      if (!containerRef.current) return;
      let bcr = containerRef.current.getBoundingClientRect();
      setSize({
        width: bcr.width,
        height: bcr.height,
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const middleRect = useMemo(() => {
    return {
      x: padding,
      y: padding,
      width: size.width - padding * 2,
      height: size.height - padding * 2,
    }
  }, [padding, size]);

  const frontRect = useMemo(() => {
    return {
      x: padding * 2,
      y: padding * 2,
      width: size.width - padding * 2 * 2,
      height: size.height - padding * 2 * 2,
    }
  }, [padding, size]);

  const renderImages = () => {
    return images.map((img, i) => (
      <Image
        key={i}
        width={img[isMobile ? 'm' : 'd'].width}
        height={img[isMobile ? 'm' : 'd'].height}
        src={img[isMobile ? 'm' : 'd'].src}
        canvasWidth={size.width}
        canvasHeight={size.height}
      />
    ));
  }

  return (
    <div ref={containerRef} className="home-hero-svg">
      <svg
        width={size.width}
        height={size.height}
        viewBox={`0 0 ${size.width} ${size.height}`}
        className="home-hero-svg__cont">

        <defs>
          <clipPath id="svg-home-hero-middle">
            <rect {...middleRect} />
          </clipPath>
          <clipPath id="svg-home-hero-fg">
            <rect {...frontRect} />
          </clipPath>
        </defs>

        <g width={size.width} height={size.height} ref={frameBgRef}
           transform-origin="center"
           className="home-hero-svg__frame home-hero-svg__frame--bg"
        >
          <g>
            {renderImages()}
          </g>
        </g>

        <g width={size.width} height={size.height} ref={frameMiddleRef}
           transform-origin="center"
           className="home-hero-svg__frame home-hero-svg__frame--middle"
        >
          <g clipPath="url(#svg-home-hero-middle)">
            {renderImages()}
            <rect fill="transparent" strokeWidth={borderWidth} stroke="rgba(194, 195, 197, 0.2)" {...middleRect} />
          </g>
        </g>

        <g width={size.width} height={size.height} ref={frameFgRef}
           transform-origin="center"
           className="home-hero-svg__frame home-hero-svg__frame--fg"
        >
          <g clipPath="url(#svg-home-hero-fg)">
            {renderImages()}
            <rect fill="transparent" strokeWidth={borderWidth} stroke="rgba(194, 195, 197, 0.2)" {...frontRect} />
          </g>
        </g>

      </svg>
    </div>
  );
};
