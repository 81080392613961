import { useCallback } from 'react';
import { useLocale } from "./useLocale";
import { useHistory } from "react-router";

export function useGoToWithLocale() {
  const locale = useLocale();
  const history = useHistory();
  (window as any).h = history;
  return useCallback((path: string, state?: any) => {
    // todo check wht state isn't pass to history location (flats back to search issue)
    return history.push(`/${locale}${path}`, state);
  }, [locale, history]);
}
