import { IServerItem } from "@shared/api/commons";
import * as mock from "@libs/Mocks/dataFields";
import { randomIntBetween } from "@libs/Mocks/random";
import { api } from "@/services";
import { IUseQueryMLExtraParams } from "@shared/hooks/useQueryML";
import { QueryKey } from "react-query";

export interface IDocumentItem extends IServerItem {
  title: string;
  file: {
    link: string;
    type: string;
    size: string;
  };
  pubDate: Date;
  author: string;
}

export interface IDocumentsSection {
  id: number;
  title: string;
  weight: number;
  documents: IDocumentItem[];
}

function mockItems(): IDocumentItem[] {
  return Array(10).fill("").map(() => ({
    id: mock.id(),
    title: mock.title(),
    type: randomIntBetween(0, 1) ? "document" : "declaration",
    pubDate: mock.date(),
    author: mock.title(),
    file: {
      link: mock.photo(),
      type: "png",
      size: mock.fileSize(),
    },
  }))
}

export function getItems(params: {queryKey: QueryKey}): Promise<IDocumentsSection[]> {
  let extraQueryParams = params.queryKey[params.queryKey.length - 1] as IUseQueryMLExtraParams;
  return api.get("/document-section/", undefined, {
    headers: {
      'Accept-Language': extraQueryParams.locale,
    },
    transformResponse: (raw) => {
      let data = JSON.parse(raw);

      return  data.map((s: any, i: number) => {
        return {
          title: s.title,
          weight: s.weight,
          id: i,
          documents: [
            ...s.documents.map((d: any) => ({
              id: d.id,
              title: d.title,
              pubDate: new Date(d.date_created),
              author: d.published_by,
              file: {
                link: d.document_file,
                type: d.document_file_extension,
                size: d.document_file_size,
              },
            })),
          ],
        };
      });
    },
  });
}
