/**
 * @description The ConstructionList component.
 */
import React from "react";
import Select from 'react-select';

import { IConstructionListItem } from "@features/posts/api/ConstructionAPI";
import { useConstructionList } from "@features/posts/hooks/useConstructionList";
import { ConstructionListSection } from "./ConstructionListSection";
import { ss } from '@features/posts/stringSource';
import { T } from "@/libs/ML/lib/components/T";
import "./ConstructionList.scss";
import { SomeSFAnimation } from "@shared/components/SomeSFAnimation";

type Props = {
  items: IConstructionListItem[],
}

export const ConstructionList: React.FC<Props> = function ({ items }) {
  const { sections, yearFilter, monthFilter, setMonthFilter, setYearFilter, yearOptions, monthsOptions } = useConstructionList(items);

  return (
    <div className="construction-list">
      <div className="construction-list__cont">

        <div className="construction-list__camera">
        {/* todo add camera link */}
        </div>

        <div className="construction-list__filter">
          <SomeSFAnimation delay={0.2} gap={30}>
            <div className="construction-list-filters">
              <div className="construction-list-filters__cont">
                <div className="construction-list-filters__label">
                  <T p={ss.posts.construction.filter.periodLabel} />
                </div>
                <div className="construction-list-filters__items">
                  <div className="construction-list-filters__el">
                    <Select options={yearOptions}
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            value={yearOptions.find(o => o.value === yearFilter)}
                            onChange={o => setYearFilter(o ? o.value : null)} />
                  </div>
                  <div className="construction-list-filters__el">
                    <Select options={monthsOptions}
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            value={monthsOptions.find(o => o.value === monthFilter)}
                            onChange={o => setMonthFilter(o ? o.value : null)} />
                  </div>
                </div>
              </div>
            </div>
          </SomeSFAnimation>
        </div>

        <SomeSFAnimation delay={0.2 + 0.15} gap={50}>
          <div className="construction-list__sections">
            {sections.map((el, i) => (
              <ConstructionListSection date={el.date} items={el.items} key={i} />
            ))}
          </div>
        </SomeSFAnimation>

      </div>
    </div>
  );
};
