/**
 * @description The BackgoundSetItem component.
 */
import React, { useState } from "react";
import { Mods } from "@shared/props";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { mergeMods } from '@libs/YandexBEM/lib/mergeMods';
import { useQuery } from 'react-query';
import { preloadImage } from "@libs/Preload/lib/image";

type Props = {
  src: string,
  preload?: boolean,
  mods?: Mods,
};

export const BackgroundSetItem: React.FC<Props> = function (props) {
  const { src, preload = true, mods = [] } = props;
  const { isLoading } = useQuery(['imagePreloading', src], async () => {
    return await preloadImage(src);
  }, {
    enabled: preload,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  return (
    <div className={makeMods('bg-set__item', mergeMods(mods, {
      loaded: !isLoading,
      preload,
    }))}>
      <img className="bg-set__item-img"
           src={src} alt=""/>
    </div>
  );
};
