/**
 * @description The NewsList component.
 */
import React from "react";
import { INewsListItem } from "@features/posts/api/NewsAPI";
import { NewsListEl } from "@features/posts/components/NewsList/NewsListEl";
import "./NewsList.scss";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";
import { motion } from "framer-motion";

type Props = {
  news: INewsListItem[]
}

export const NewsList: React.FC<Props> = function (props) {
  const { news } = props;
  const variants = useListAnimationVars();

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants.list}
      className="news-list">
      <div className="news-list__cont">

        {news.map(n => (
          <motion.div variants={variants.item} key={n.id} className="news-list__el">
            <NewsListEl {...n} />
          </motion.div>
        ))}

      </div>
    </motion.div>
  );
};
