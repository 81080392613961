/**
 * @description The RangeSliderInput component.
 */
import React, { useMemo } from "react";
import { Range, getTrackBackground } from 'react-range';
import { useRem } from "@shared/hooks/useRem";
import "./RangeSliderInput.scss";
import { useIsMobile } from "@shared/hooks/useIsMobile";

type Props = {
  valueFrom: number;
  valueTo: number;
  min: number;
  max: number;
  onChange: (from: number, to: number) => any;
  step?: number;
};

export const RangeSliderInput: React.FC<Props> = function (props) {
  const {
    valueFrom,
    valueTo,
    min,
    max,
    onChange,
    step = 1,
  } = props;
  const { rem } = useRem();
  const isMobile = useIsMobile();

  const values = useMemo(() => {
    return [valueFrom, valueTo];
  }, [valueFrom, valueTo]);

  return (
    <div className="rs-input">
      <div className="rs-input__cont">
        <Range
          min={min}
          max={max}
          values={values}
          onChange={([from, to]) => onChange(from, to)}
          onFinalChange={([from, to]) => onChange(from, to)}
          step={step}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              className="rs-input__track"
              style={{
                ...props.style,
                height: '1px',
                width: '100%',
                display: 'flex'
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '1px',
                  width: '100%',
                  background: getTrackBackground({
                    values,
                    colors: ['rgba(255, 255, 255, 0.5)', '#fff', 'rgba(255, 255, 255, 0.5)'],
                    min: min,
                    max: max,
                  }),
                  alignSelf: 'center'
                }}
                className="rs-input__track-wrap">
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, index }) => (
            <div
              {...props}
              className="rs-input__thumb"
              style={{
                ...props.style,
                height: rem(isMobile ? 38 : 55) + 'px',
                width: rem(isMobile ? 38 : 55) + 'px',
              }}
            >{values[index]}</div>
          )}
        />
      </div>
    </div>
  );
};
