/**
 * @module @libs/ReactRouterHelpers
 * @description Provide standard route config.
 */

/* eslint max-classes-per-file: ["error", 2] */
import { Route } from 'react-router';
import { ComponentType } from 'react';

/**
 * Component that will be used for render a ROUTE
 */
export type RouteComponent = typeof Route | ComponentType;

/**
 * Component that will be used for render a CONTENT inside the ROUTE
 */
export type ContentComponent = ComponentType;

/**
 * Route config options
 */
export interface ConfigOpts {
  id: string;
  path: string;
  exact?: boolean;
  mods?: string[];

  [key: string]: any;
}

/**
 * Route render config options
 */
export interface RenderConfigOpts extends ConfigOpts {
  routeComponent?: RouteComponent;
  contentComponent: ContentComponent;

  [key: string]: any;
}

/**
 * Maps routes keys to render options
 */
export interface RenderMap {
  [key: string]: {
    routeComponent?: RouteComponent;
    contentComponent: ContentComponent;
    [key: string]: any;
  };
}

/**
 * A common route config. Should be used in ducks, services, etc. Must'n be used in the render
 */
export class Config {
  id: string;

  path: string;

  exact: boolean;

  mods: string[];

  rest: any;

  [key: string]: any;

  constructor({ id, path, exact, mods = [], ...rest }: ConfigOpts) {
    this.id = id;
    this.path = path;
    this.exact = typeof exact !== 'boolean' ? true : exact;
    this.mods = mods;
    this.rest = rest;
    Object.keys(rest).forEach((k) => Object.defineProperty(this, k, {
      value: rest[k],
    }));
  }

  normalizeTrailingSlash(shouldUse = false) {
    let p = this.path,
      didUse = p.slice(p.length - 1) === "/";
    if (shouldUse) {
      if (!didUse) p = `${p}/`
    } else {
      if (didUse) p = p.slice(0, p.length - 1);
    }
    this.path = p;
  }
}

/**
 * Render route config. Must be used for rendering only
 */
export class RenderConfig extends Config {
  routeComponent: RouteComponent;

  contentComponent: ContentComponent;

  constructor({ id, path, exact, routeComponent, contentComponent, ...rest }: RenderConfigOpts) {
    super({
      id,
      path,
      exact,
      ...rest,
    });
    this.routeComponent = routeComponent || Route;
    this.contentComponent = contentComponent;
  }
}

/**
 * Makes routes render config
 * @param m - map of routes keys to render components
 * @param r - routes config
 */
export function makeRoutesRenderConf(m: RenderMap, r: Config[]): RenderConfig[] {
  return r.map(
    (el) =>
      new RenderConfig({
        ...el,
        ...(el.rest || {}),
        ...m[el.id],
      }),
  );
}
