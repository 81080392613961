import { IServerItem, IPhoto } from "@shared/api/commons";
import * as mock from "@libs/Mocks/dataFields";
import { api } from "@/services";
import { IUseQueryMLExtraParams } from "@shared/hooks/useQueryML";
import { QueryKey } from "react-query";

interface IGalleryItem extends IServerItem {
  title: string;
  slug: string;
}

export interface IGalleryListItem extends IGalleryItem {
  cover: IPhoto;
  coverMobile: IPhoto;
}

export interface IGallerySingleItem extends IGalleryItem {
  items: IPhoto[];
  itemsMobile: IPhoto[];
}

export interface IGalleryAlbum extends IServerItem {
  title: string;
  cover: IPhoto;
  coverMobile: IPhoto;
  items: IPhoto[];
  itemsMobile: IPhoto[];
}

function mockList(): IGalleryListItem[] {
  return Array(6).fill("").map(() => ({
    id: mock.id(),
    title: mock.title(),
    slug: mock.slug(),
    cover: {
      src: mock.photo(),
      alt: mock.alt(),
    },
    coverMobile: {
      src: mock.photo(),
      alt: mock.alt(),
    }
  }));
}

function mockItem(): IGallerySingleItem {
  return {
    id: mock.id(),
    title: mock.title(),
    slug: mock.slug(),
    items: Array(30).fill("").map(() => ({
      src: mock.photo(),
      alt: mock.alt(),
    })),
    itemsMobile: Array(30).fill("").map(() => ({
      src: mock.photo(),
      alt: mock.alt(),
    })),
  };
}

export function getAlbums(params: {queryKey: QueryKey}): Promise<IGalleryAlbum[]> {
  let extraQueryParams = params.queryKey[params.queryKey.length - 1] as IUseQueryMLExtraParams;
  return api.get("/albums/", undefined, {
    headers: {
      'Accept-Language': extraQueryParams.locale,
    },
    transformResponse: (raw) => JSON.parse(raw).map((album: any) => ({
      id: album.id,
      title: album.title,
      cover: {
        src: album.cover_photo,
        alt: album.title,
      },
      coverMobile: {
        src: album.cover_photo,
        alt: album.title,
      },
      items: album.photos.map((p: any) => ({
        src: p.photo,
        alt: p.alt,
      })),
      itemsMobile: album.photos.map((p: any) => ({
        src: p.mobile_photo,
        alt: p.alt,
      })),
    })),
  }, {
    getPreloadingImages: (data: IGalleryAlbum[]) => {
      let images: string[] = [];
      data.filter((_, i) => i <= 3).forEach(album => {
        images.push(album[extraQueryParams.isMobile ? 'coverMobile' : 'cover'].src);
        let photos = album[extraQueryParams.isMobile ? 'itemsMobile' : 'items'];
        if (photos.length) images.push(photos[0].src);
      });
      return images;
    },
  });
}

export function getItems(): Promise<IGalleryListItem[]> {
  return api.get("/gallery/", undefined, undefined, {
    mock: mockList,
  });
}

export function getItem(params: {queryKey: [string, string]}): Promise<IGallerySingleItem> {
  let slug = params.queryKey[1];
  return api.get("/gallery/", {
    slug,
  }, undefined, {
    mock: () => ({
      ...mockItem(),
      slug,
    }),
  });
}
