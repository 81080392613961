import { IStringSource, makeMap } from "@libs/ML/lib/StringSource";

const strings = {
  flats: { // WARNING! Do not change the scope name!
    search: {
      nullOfAvailable: `Sorry, there aren't any available flat now.`,
      nullOfFound: `There aren't flats by searched values`,
      button: {
        showList: `Show {{ count }} options`,
        params: `Back to filters`
      },
      view: 'View',
      reset: 'Reset all',
      filters: {
        bedrooms: 'Bedrooms',
        floor: 'Floor',
        area: 'Area',
      },
      table: {
        type: 'Type',
        floor: 'Floor',
        number: '#',
        bedrooms: 'Bedrooms',
        bedroomsShort: 'Bed.',
        area: 'Area',
        areaShort: 'S',
        price: 'Price',
      },
      values: {
        floor: '{{ floor }} floor',
        type: 'Type {{ type }}',
        rooms: '{{ rooms }} rooms',
      }
    },
    house: {
      flatsAvailable: `{{ n }} flats on sale`,
      npAvailable: `Plan of the floor`,
      mobilePanel: {
        floor: 'floor',
        flats: 'flats',
        choose: 'Choose flat'
      }
    },
    floorFlat: {

    },
    flat: {
      infoPanel: {
        type: 'Type',
        number: '# of flat',
        numberOfRoom: '# of room',
        bedrooms: 'Bedrooms',
        area: 'Area',
        price: 'Price, UAH',
        floor: 'Floor',
      },
      status: {
        available: 'Sale',
        sold: 'Sold',
      },
      buttons: {
        book: `заявка на бронь`,
        windows: `Вид из окон`,
        pdf: `буклет pdf`,
      },
      priceCurrencyExchangeNotice:
          'Стоимость 1м<sup>2</sup> указана в долларах США. <br/>Стоимость в гривнах по Договору купли-продажи дериватива определяется по курсу НБУ на день оплаты. <br/>Стоимость в гривнах по Договору купли-продажи имущественных прав определяется по среднему коммерческому курсу на день оплаты.',
    },
    floorPlan: {
      type: 'type {{ type }}',
      area: '{{ area }} m2',
      streetTop: `ул. Сичеславская Набережная`,
      streetBottom: `ул. Архитектора Дольника`,
    },
    booking: {
      title: `заявка на бронь`
    },
    compass: {
      north: 'N',
    },
    nav: {
      floor: 'Floor',
    },
    scrollMessage: {
      text: 'Для просмотра всех помещений перемещайте изображение',
    }
  }
};

export const stringSource: IStringSource & typeof strings = strings;

export const ss: typeof strings = makeMap(strings) as typeof strings;
