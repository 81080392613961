import { makeRoutesRenderConf, RenderMap } from '@libs/RRH/lib/route';
import { keys, routes } from './routes';
import { HousePage } from "./pages/HousePage";
import { FloorFlatPage } from "@features/flats/pages/FloorFlatPage";
import { MobileFlatPage } from "@features/flats/pages/MobileFlatPage";
import { MobileFloorPage } from "@features/flats/pages/MobileFloorPage";
import { SearchPage } from "@features/flats/pages/SearchPage";

const renderMap: RenderMap = {
  [keys.house]: { contentComponent: HousePage },
  [keys.floor]: { contentComponent: MobileFloorPage },
  [keys.flat]: { contentComponent: MobileFlatPage },
  [keys.search]: { contentComponent: SearchPage },
  [keys.floorFlat]: { contentComponent: FloorFlatPage },
};

export const routesRender = makeRoutesRenderConf(renderMap, routes);
