/**
 * @description The Chars component.
 */
import React, { useMemo } from "react";
import { PageLayout } from "@shared/components/PageLayout";
import { T } from "@libs/ML/lib/components/T";
import { ABOUT_SECTION, CHARS } from "@/menuConfig";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgChars } from "@shared/images/bg/exports";
import { usePreloadImages } from "@shared/hooks/usePreloadImages";
import { charsSections } from "./charsConf";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { CharsSection } from "./CharsSection";
import "./Chars.scss";

type Props = {};

export const Chars: React.FC<Props> = function (props) {
  const isMobile = useIsMobile();
  const toPreloadImages = useMemo(() => {
    return [
      charsSections[0].icon,
      ...charsSections[0].media[isMobile ? 'm' : 'd'],
      charsSections[1].icon,
      ...charsSections[1].media[isMobile ? 'm' : 'd'],
      charsSections[2].icon,
      ...charsSections[2].media[isMobile ? 'm' : 'd'],
    ]
  }, [isMobile]);
  const { isLoading } = usePreloadImages(toPreloadImages);

  const plProps = {
    title: <T p={CHARS.labelPath} />,
    isLoading: isLoading,
    breadcrumbs: ABOUT_SECTION,
    bg: <BackgroundSet mods={['chars']} conf={bgChars} />,
    mods: ['w100'],
  };

  return (
    <PageLayout {...plProps}>
      <div className="chars">
        <div className="chars__cont">
          {charsSections.map(s => (
            <CharsSection {...s} key={s.id} isMobile={isMobile} />
          ))}
        </div>
      </div>
    </PageLayout>
  );
};
