/**
 * @description The AppContainer component.
 */
import React, { useEffect, useMemo } from "react";
import debounce from 'debounce';
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import { useLocale } from "@libs/ML/lib/hooks/useLocale";
import * as SettingsDuck from "@shared/ducks/settings.duck";
import { appConf } from "@/config";
import { routesRender } from "@/routesToRender";
import * as flatsRoutes from "@features/flats/routes";
import { Menu } from "@shared/components/Menu";
import { useCurrentPage } from "@shared/hooks/useCurrentPage";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import mergeMods from "@libs/YandexBEM/lib/mergeMods";
import { Header } from "@shared/components/Header";
import { Sidebar } from "@shared/components/Sidebar";
import { useSettings } from "@shared/hooks/useSettings";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { Page404 } from "@shared/components/Page404";
import { PageLoader } from "@shared/components/PageLoader";

type Props = {}

export const AppContainer: React.FC<Props> = function () {
  const dispatch = useDispatch();
  const locale = useLocale();
  const { page } = useCurrentPage();
  const history = useHistory();
  const settings = useSettings();
  const isMobile = useIsMobile();

  useEffect(() => {
    let handleResize = () => {
      const w = window.innerWidth,
        h = window.innerHeight,
        prop = w / h;
      dispatch(SettingsDuck.set({
        windowWidth: w,
        windowHeight: h,
        windowProp: prop,
        isMobile: w <= appConf.responsive.mobile,
      }))
    };
    handleResize();
    handleResize = debounce(handleResize, 700);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let handleScroll = () => {
      dispatch(SettingsDuck.set({
        isScrolled: window.scrollY > 0
      }))
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (locale === null) {
      history.push(`/${appConf.ml.defaultLocale}`);
    }
  }, [locale]);

  const routesRenderPrepared = useMemo(() => {
    return routesRender.filter(r => {
      if (r.desktopOnly || r.mobileOnly) {
        if (r.desktopOnly) return !isMobile;
        if (r.mobileOnly) return isMobile;
      }
      return true;
    });
  }, [isMobile]);

  let mods = mergeMods([], page?.mods || []);

  return (
    <div className={makeMods("ws", mods)}>
      {settings.isLoading ?
        (
          <div className="ws__loader">
            <PageLoader full />
          </div>
        ) : (
          <div className="ws__cont">
            <Switch>
              {routesRenderPrepared.map((c) => (
                <c.routeComponent key={c.id} path={c.path} exact={c.exact} component={c.contentComponent} />
              ))}
              <Route path="*" exact component={Page404} />
            </Switch>

            <div className="ws__sidebar">
              <Sidebar />
            </div>

            <div className="ws__menu">
              <Menu />
            </div>

            <div className="ws__header">
              <Header />
            </div>

          </div>
        )}
    </div>
  );
};
