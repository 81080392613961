/**
 * @description The MobileScrollMessage component.
 */
import React, { useMemo } from "react";
import { useOnceByCookie } from "@shared/hooks/useOnceByCookie";
import { appConf } from "@/config";
import { motion, AnimatePresence } from "framer-motion";
import { T } from "@/libs/ML/lib/components/T";
import { ss as sharedSS } from '@/shared/stringSource';
import { ss } from '@/features/flats/stringSource';
import { ReactComponent as IconScroll } from "./i/scroll.svg";
import { ReactComponent as IconArrL } from "./i/arr-left.svg";
import { ReactComponent as IconArrR } from "./i/arr-right.svg";
import "./MobileScrollMessage.scss";

type Props = {
  id: string;
};

export const MobileScrollMessage: React.FC<Props> = function (props) {
  const { used, use } = useOnceByCookie(`${appConf.name}_${props.id}`);

  const handleClose = () => {
    use();
  };

  const animation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
      },
      show: {
        opacity: 1,
        transition: {
          duration: 0.3,
          ease: 'easeInOut',
        }
      },
      exit: {
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: 'easeInOut',
        }
      }
    }
  }, []);

  return (
    <AnimatePresence>
      {!used && (
        <motion.div
          variants={animation}
          initial="initial"
          animate="show"
          exit="exit"
          className="mobile-scroll-message"
        >
          <div className="mobile-scroll-message__cont">

            <div className="mobile-scroll-message__content">
              <div className="mobile-scroll-message__i-scroll">
                <IconScroll />
              </div>
              <div className="mobile-scroll-message__text">
                <T p={ss.flats.scrollMessage.text} />
                <div className="mobile-scroll-message__i-left">
                  <IconArrL />
                </div>
                <div className="mobile-scroll-message__i-right">
                  <IconArrR />
                </div>
              </div>
            </div>

            <div className="mobile-scroll-message__footer">
              <button onClick={handleClose} className="mobile-scroll-message__close">
                <T p={sharedSS.shared.ok} />
              </button>
            </div>

          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
