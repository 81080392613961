/**
 * @description The MobileFloorPage component.
 */
import React from "react";
import { useWrongFloorRedirect } from "@features/flats/hooks/useWrongFloorRedirect";
import { useFlatsData } from "@features/flats/hooks/useFlatsData";
import { useFloorFlatParams } from "@features/flats/hooks/useFloorFlatParams";
import { FLATS_SECTION } from "@/menuConfig";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgSearch } from "@shared/images/bg/exports";
import { PageLayout } from "@shared/components/PageLayout";
import { MobileFloor } from "@features/flats/components/MobileFloor";
import { FlatManager } from "@features/flats/services/flatManager";

type Props = {}

export const MobileFloorPage: React.FC<Props> = function (props) {
  const canRenderFloor = useWrongFloorRedirect();
  const { isLoading, error, data } = useFlatsData();
  let params = useFloorFlatParams();

  const plProps = {
    isLoading: !!(isLoading || !canRenderFloor),
    mods: ['flats', 'no-pb', 'overflow-hidden', 'fullpage'],
    error: error?.message,
    breadcrumbs: FLATS_SECTION,
    bg: <BackgroundSet conf={bgSearch} />
  };

  return (
    <PageLayout {...plProps}>
      <MobileFloor
        floorNumber={params.floorNumber as number}
        flatsManager={data as FlatManager}
      />
    </PageLayout>
  );
};
