/**
 * @description The PageLoader component.
 */
import React from "react";
import "./PageLoader.scss";

type Props = {
  full?: boolean;
};

export const pageLoaderAnimation = {
  initial: {
    scale: 0.9,
    opacity: 0,
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    }
  },
  exit: {
    scale: 1,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    }
  }
};

export const PageLoader: React.FC<Props> = function (props) {
  const w = 200;
  const h = 519;
  const padding = 50;
  const targetW = w + padding * 2;
  const targetH = h + padding * 2;

  return (
    <div className={`page-loader ${props.full ? 'page-loader--full' : ''}`}>
      <svg width={targetW} height={targetH} viewBox={`0 0 ${targetW} ${targetH}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="pl-white-glow-base" x="-5000%" y="-5000%" width="10000%" height="10000%">
            <feFlood result="flood" floodColor="#ffffff" floodOpacity="1"></feFlood>
            <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"></feComposite>
            <feMorphology in="mask" result="dilated" operator="dilate" radius="1"></feMorphology>
            <feGaussianBlur in="dilated" result="blurred" stdDeviation="10"></feGaussianBlur>
            <feMerge>
              <feMergeNode in="blurred"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <filter id="pl-white-glow" x="-5000%" y="-5000%" width="10000%" height="10000%">
            <feFlood result="flood" floodColor="#ffffff" floodOpacity="1"></feFlood>
            <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"></feComposite>
            <feMorphology d="pl-white-glow-morph" in="mask" result="dilated" operator="dilate" radius="3"></feMorphology>
            <feGaussianBlur id="pl-white-glow-blur" in="dilated" result="blurred" stdDeviation="10"></feGaussianBlur>
            <feMerge>
              <feMergeNode in="blurred"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <animate
            xlinkHref="#pl-white-glow-blur"
            attributeName="stdDeviation"
            values="20;5;20"
            dur="2s"
            fill="freeze"
            repeatCount="indefinite"
          />
          <animate
            xlinkHref="#pl-white-glow-morph"
            attributeName="radius"
            values="8;3;8"
            dur="2s"
            fill="freeze"
            repeatCount="indefinite"
          />
        </defs>
        <g transform={`translate(${padding}, ${padding})`}>
          <path
            strokeWidth="1"
            id="pl-main-path"
            d="M16 518.5H21M20.5 518L20 143.5M20 144L150.684 37.6126M150.5 38L198.597 518.94M199 518.5H133M133.5 518.991L139.5 177.991L122 1M122 1.5L0.119385 31.4856M0.499719 30.9837L16.5325 518.983" stroke="white"/>
          <circle
            style={{
              filter: 'url(#pl-white-glow)'
            }}
            cx="0" cy="0" r="3" fill="#fff">
            <animateMotion dur="4.8s" repeatCount="indefinite">
              <mpath xlinkHref="#pl-main-path" />
            </animateMotion>
          </circle>
          <circle
            style={{
              filter: 'url(#pl-white-glow)'
            }}
            cx="0" cy="0" r="3" fill="#fff">
            <animateMotion dur="5.5s" repeatCount="indefinite">
              <mpath xlinkHref="#pl-main-path" />
            </animateMotion>
          </circle>
          <circle
            style={{
              filter: 'url(#pl-white-glow)'
            }}
            cx="0" cy="0" r="3" fill="#fff">
            <animateMotion dur="6.5s" repeatCount="indefinite">
              <mpath xlinkHref="#pl-main-path" />
            </animateMotion>
          </circle>
        </g>
      </svg>

    </div>
  );
};
