/**
 * @description The BackgroundSet component.
 */
import React from "react";
import { Mods } from "@shared/props";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import { BackgroundSetItem } from "@shared/components/BackgroundSet/BackgroundSetItem";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { mergeMods } from '@libs/YandexBEM/lib/mergeMods';
import "./BackgroundSet.scss";

type Props = {
  conf: {
    desktop: string[],
    mobile: string[],
  },
  preload?: boolean,
  mods?: Mods,
};

export const BackgroundSet: React.FC<Props> = function (props) {
  let { conf, preload = true, mods = [] } = props;
  let isMobile = useIsMobile();

  let items = isMobile ? conf.mobile : conf.desktop;

  return (
    <div className={makeMods('bg-set', mergeMods(mods, {
      preload,
    }))}>
      <div className="bg-set__cont">
        {items.map(src => (
          <BackgroundSetItem key={src} src={src} preload={preload} mods={mods} />
        ))}
      </div>
    </div>
  );
};
