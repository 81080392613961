/**
 * @description The TourPanoPage component.
 */
import React from "react";
import { TourPanorama } from "@features/singles/components/TourPanorama";

type Props = {}

export const TourPanoPage: React.FC<Props> = function (props) {
  return (
    <TourPanorama />
  );
};
