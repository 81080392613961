/**
 * Collapse multi-level object to flat object with keys by paths
 * @param obj
 * @param res
 * @param parent
 */
export function flattenObj(obj: {[path: string]: any}, res: {[path: string]: any} = {}, parent?: string){
  for(let key in obj){
    let propName = parent ? parent + '.' + key : key;
    if(typeof obj[key] == 'object'){
      flattenObj(obj[key], res, propName);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
}
