/**
 * @description The T component.
 */
import React from "react";

import { useString } from "@libs/ML/lib/hooks/useString";
import { useLocaleString } from "@libs/ML/lib/hooks/useLocaleString";
import { appConf } from "@/config";

const useTranslate = appConf.ml.enabled ? useLocaleString : useString;

type Props = {
  p: string,
  params?: { [p: string]: string | number | null },
  html?: boolean,
  tag?: "div" | "span",
  [p: string]: any,
}

export const T: React.FC<Props> = function ({ p, params = {}, html = false, tag = "span", ...rest }) {
  let str = useTranslate(p, { params });

  let elProps = {
    ...rest,
    children: html ? undefined : str,
    dangerouslySetInnerHTML: html ? {
      __html: str,
    } : undefined,
  };

  if (tag === "div") {
    return (
      <div {...elProps} />
    );
  }

  return (
    <span {...elProps} />
  );
};
