export type ModsArray = string[];

/**
 * Generate class names string with BEM mods from Array
 * @param cn
 * @param mods
 */
export default function modsFromArray(cn = 'cn', mods: ModsArray = []): string {
  return `${cn} ${mods.map((mod) => `${cn}--${mod}`).join(' ')}`.trim();
}
