import { useEffect, useMemo, useRef, useState } from 'react';
import { useBodyScrollTo } from "@shared/hooks/useBodyScrollTo";

const EVENT_RECHECK_SCROLL_STATE = 'useBodyScroll/EVENT_RECHECK_SCROLL_STATE';

export function emitRecheckScrollState() {
  window.dispatchEvent(new CustomEvent(EVENT_RECHECK_SCROLL_STATE));
}

export function useBodyScroll(cache: any = 'some') {
  const [hasScroll, setHasScroll] = useState(false);
  const [scrollFinished, setScrollFinished] = useState(false);
  const [scrollPassOneScreen, setScrollPassOneScreen] = useState(false);
  const [innerCache, setInnerCache] = useState(cache);
 const { scrollDown, scrollUp, stop } = useBodyScrollTo();

  const generated = useMemo(() => {
    const body = document.querySelector('body') as HTMLBodyElement;
    const html = document.querySelector('html') as HTMLHtmlElement;

    const checkHasScroll = () => {
      return body ? body.clientHeight > window.innerHeight : false;
    };

    const checkScrollFinished = () => {
      return html ? window.innerHeight + html.scrollTop >= html.scrollHeight : false;
    };

    const checkScrollPassOneScreen = () => {
      return html ? html.scrollTop >=  window.innerHeight : false;
    };

    return {
      checkHasScroll,
      checkScrollFinished,
      checkScrollPassOneScreen,
    }
  }, []);

  useEffect(() => {
    setInnerCache(cache);
  }, [cache]);

  useEffect(() => {
    const h = () => {
      setInnerCache((new Date()).getTime());
    };

    window.addEventListener(EVENT_RECHECK_SCROLL_STATE, h);
    return () => window.removeEventListener(EVENT_RECHECK_SCROLL_STATE, h);
  }, []);

  useEffect(() => {
    stop();
    setHasScroll(generated.checkHasScroll());
    setScrollFinished(generated.checkScrollFinished());
    setScrollPassOneScreen(generated.checkScrollPassOneScreen());

    const scrollHandler = () => {
      setHasScroll(generated.checkHasScroll());
      setScrollFinished(generated.checkScrollFinished());
      setScrollPassOneScreen(generated.checkScrollPassOneScreen());
    };

    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('resize', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', scrollHandler);
    };
  }, [generated, innerCache]);

  return {
    hasScroll,
    scrollFinished,
    scrollDown,
    scrollUp,
    scrollPassOneScreen,
  }
}
