/**
 * @description The Tour component.
 */
import React, { useEffect, useMemo, useState } from "react";
import { useLoadThreeJSModel } from "@shared/hooks/useLoadThreeJSModel";
import { appConf } from "@/config";
import { emitLoadingDone } from "@shared/events/loading";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as IconRotate } from "@features/flats/components/House/i/rotate.svg";
import { PageLoader, pageLoaderAnimation } from "@shared/components/PageLoader";
import { TourScene } from "@features/singles/components/TourScene";
import { useIsMobile } from "@shared/hooks/useIsMobile";
import "./Tour.scss";
import { useWaitFirstUserAction } from "@shared/hooks/useWaitFirstUserAction";
import { useGoToWithLocale } from "@libs/ML/lib/hooks/useGoToWithLocale";
import { ImagePopup } from "@shared/components/ImagePopup";

type Props = {};

export const Tour: React.FC<Props> = function (props) {
  const { isLoaded: isModelLoaded, progress, model, isError: isModelError } = useLoadThreeJSModel(appConf.assetsURL + appConf.assets.houseSceneURL);
  const [isModelInit, setIsModelInit] = useState(false);
  const isMobile = useIsMobile();
  const isUserDidAction = useWaitFirstUserAction(!!(model && isModelInit));
  const goTo = useGoToWithLocale();
  const [openedImage, setOpenedImage] = useState<number|null>(null);

  // flags
  const isError = isModelError;
  const isLoading = !isModelLoaded || !isModelInit;

  // scene animation
  const sceneAnimation = useMemo(() => {
    return {
      initial: {
        opacity: 0,
      },
      show: {
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut'
        }
      }
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      emitLoadingDone();
    }
  }, [isLoading]);

  const handleClick360 = (id: number) => {
    goTo(`/tour-view/?start=${id}`);
  };

  const handleClickImage = (id: number) => {
    setOpenedImage(id);
  };

  return (
    <div className="tour">
      <div className="tour__cont">

        {model && (
          <motion.div
            variants={sceneAnimation}
            initial="initial"
            animate={isLoading ? 'initial' : 'show'}
            className="tour__scene"
          >
            <TourScene
              isInit={isModelInit}
              onInit={() => setIsModelInit(true)}
              model={model}
              animate={!isUserDidAction}
              handleClick360={handleClick360}
              handleClickImage={handleClickImage}
            />
          </motion.div>
        )}

        <div className="tour__rotate">
          <IconRotate/>
        </div>

        <ImagePopup
          isOpened={openedImage !== null}
          onClose={() => setOpenedImage(null)}
          src={openedImage ? `${appConf.assetsURL}${appConf.assets.tourImagesDir}/${openedImage}.jpg` : null}
        />

        <AnimatePresence>
          {isLoading && (
            <motion.div
              key="loader"
              variants={pageLoaderAnimation}
              initial="initial"
              animate="show"
              exit="exit"
              className="tour__loader"
            >
              <PageLoader/>
            </motion.div>
          )}
        </AnimatePresence>

      </div>
    </div>
  );
};
