import { useLocation } from "react-router";
import { useMemo } from "react";

export function useLocationQuery() {
  const { search } = useLocation();
  return useMemo(() => {
    return search.slice(1).split('&').map(el => {
      let pair = el.split("=");
      return {
        key: decodeURI(pair[0]),
        value: decodeURI(pair[1]),
      }
    }).reduce((acc: {[k: string]: string}, el) => {
      return {
        ...acc,
        [el.key]: el.value,
      }
    }, {});
  }, [search]);
}
