/**
 * @description The ContactInfo component.
 */
import React from "react";
import "./ContactInfo.scss";
import phoneIcon from './i/phone.svg';
import addressIcon from './i/address.svg';
import mailIcon from './i/mail.svg';
import scheduleIcon from './i/schedule.svg';
import { useSettings } from "@shared/hooks/useSettings";
import { useListAnimationVars } from "@shared/hooks/useListAnimationVars";
import { motion } from "framer-motion";
import { AnimateInView } from "@shared/components/AnimateInView";

type Props = {};

export const ContactInfo: React.FC<Props> = function (props) {
  const { phoneList = [], email, salesOfficeAddress, workingTime, googleMap } = useSettings();
  const animateVars = useListAnimationVars({
    itemDelay: 0.4,
  });

  return (
    <AnimateInView>
      {({ ref, controls }) => (
        <motion.div ref={ref} animate={controls} initial="hidden" variants={animateVars.list} className="contact-info">
          <div className="contact-info__cont">

            <motion.div variants={animateVars.item} className="contact-info__el">
              <div className="contact-info__el-i">
                <img src={addressIcon} alt=""/>
              </div>
              <div className="contact-info__el-text">
                <a href={googleMap} target="_blank" dangerouslySetInnerHTML={{__html: salesOfficeAddress || ''}} />
              </div>
            </motion.div>

            <motion.div variants={animateVars.item} className="contact-info__el">
              <div className="contact-info__el-i">
                <img src={phoneIcon} alt=""/>
              </div>
              <div className="contact-info__el-text">
                {phoneList.map((p, i) => (
                  <div key={i}>{p}</div>
                ))}
              </div>
            </motion.div>

            <motion.div variants={animateVars.item} className="contact-info__el">
              <div className="contact-info__el-i">
                <img src={scheduleIcon} alt=""/>
              </div>
              <div className="contact-info__el-text" dangerouslySetInnerHTML={{__html: workingTime || ''}} />
            </motion.div>

            <motion.div variants={animateVars.item} className="contact-info__el">
              <div className="contact-info__el-i">
                <img src={mailIcon} alt=""/>
              </div>
              <div className="contact-info__el-text">
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            </motion.div>

          </div>
        </motion.div>
      )}
    </AnimateInView>
  );
};
