import { makeRoutesRenderConf, RenderMap } from '@libs/RRH/lib/route';
import { HomePage } from "./pages/HomePage";
import { keys, routes } from './routes';
import { DummyPage } from "./pages/DummyPage";
import { CharsPage } from "@features/singles/pages/CharsPage";
import { PlacementPage } from "@features/singles/pages/PlacementPage";
import { InfoPage } from "@features/singles/pages/InfoPage";
import { HowPage } from "@features/singles/pages/HowPage";
import { ContactsPage } from "@features/singles/pages/ContactsPage";
import { TourPage } from "@features/singles/pages/TourPage";
import { TourPanoPage } from "@features/singles/pages/TourPanoPage";

const renderMap: RenderMap = {
  [keys.home]: { contentComponent: HomePage },
  [keys.chars]: { contentComponent: CharsPage },
  [keys.contact]: { contentComponent: ContactsPage },
  [keys.placement]: { contentComponent: PlacementPage },
  [keys.tour]: { contentComponent: TourPage },
  [keys.tourView]: { contentComponent: TourPanoPage },
  [keys.how]: { contentComponent: HowPage },
  [keys.info]: { contentComponent: InfoPage },
};

export const routesRender = makeRoutesRenderConf(renderMap, routes);
