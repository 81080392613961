import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  selectAll,
  setArea,
  setBedrooms,
  setFloor,
  setOrder,
  setIsMobileCardView,
  setIsMobileOpenedList,
  setIsSetDefaults,
} from "@features/flats/ducks/search.duck";

export function useSearchState() {
  const dispatch = useDispatch();
  const state = useSelector(selectAll);

  const _setArea = useCallback((...p: Parameters<typeof setArea>) => dispatch(setArea(...p)), [dispatch]);
  const _setBedrooms = useCallback((...p: Parameters<typeof setBedrooms>) => dispatch(setBedrooms(...p)), [dispatch]);
  const _setFloor = useCallback((...p: Parameters<typeof setFloor>) => dispatch(setFloor(...p)), [dispatch]);
  const _setOrder = useCallback((...p: Parameters<typeof setOrder>) => dispatch(setOrder(...p)), [dispatch]);
  const _setIsMobileCardView = useCallback((...p: Parameters<typeof setIsMobileCardView>) => dispatch(setIsMobileCardView(...p)), [dispatch]);
  const _setIsMobileOpenedList = useCallback((...p: Parameters<typeof setIsMobileOpenedList>) => dispatch(setIsMobileOpenedList(...p)), [dispatch]);
  const _setIsSetDefaults = useCallback((...p: Parameters<typeof setIsSetDefaults>) => dispatch(setIsSetDefaults(...p)), [dispatch]);
  const toggleOrder = useCallback((...p: Parameters<typeof _setOrder>) => {
    let [by, dir] = p;
    if (by === state.orderBy && dir === undefined) {
      dir = (state.orderDirection * -1) as 1 | -1;
    }
    return _setOrder(by, dir);
  }, [_setOrder, state]);

  return {
    ...state,
    setArea: _setArea,
    setBedrooms: _setBedrooms,
    setFloor: _setFloor,
    setOrder: toggleOrder,
    setIsMobileCardView: _setIsMobileCardView,
    setIsMobileOpenedList: _setIsMobileOpenedList,
    setIsSetDefaults: _setIsSetDefaults,
  };
}

export type SearchState = ReturnType<typeof useSearchState>;
