/**
 * @description The HomeImage component.
 */
import React from "react";
import { T } from "@/libs/ML/lib/components/T";
import { ParallaxedImage } from "@shared/components/ParallaxedImage";

type Props = {
  titleSrc: string;
  desktop: string;
  mobile: string;
  gap?: number;
};

export const HomeImage: React.FC<Props> = function (props) {
  let { titleSrc, desktop, mobile, gap = 100 } = props;

  return (
    <div className="home-image">
      <div className="home-image__cont">

        <div className="home-image__bg">
          <ParallaxedImage
            src={desktop}
            mobileSrc={mobile}
            fill
            randomGapFrom={gap - 20}
            randomGapTo={gap + 20}
          />
        </div>

        <div className="home-image__fg">
          <h3 className="home-image__title">
            <div className="home-image__title-bg" />
            <div className="home-image__title-text">
              <T p={titleSrc} />
            </div>
          </h3>
        </div>

      </div>
    </div>
  );
};
