/**
 * @description The SearchPage component.
 */
import React from "react";
import { useFlatsData } from "@features/flats/hooks/useFlatsData";
import { T } from "@libs/ML/lib/components/T";
import { SEARCH, FLATS_SECTION } from "@/menuConfig";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgSearch } from "@shared/images/bg/exports";
import { PageLayout } from "@shared/components/PageLayout";
import { Search } from "@features/flats/components/Search";
import { FlatManager } from "@features/flats/services/flatManager";

type Props = {};

export const SearchPage: React.FC<Props> = function (props) {
  const { data, isLoading, error } = useFlatsData();

  const plProps = {
    title: <T p={SEARCH.labelPath} />,
    isLoading,
    mods: ['flats'],
    error: error?.message,
    breadcrumbs: FLATS_SECTION,
    bg: <BackgroundSet conf={bgSearch} />
  };

  return (
    <PageLayout {...plProps}>
      <Search flatsManager={data as FlatManager} />
    </PageLayout>
  );
};
