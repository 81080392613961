import { api } from "@/services";
import { appConf } from "@/config";
import { id } from '@/libs/Mocks/dataFields'

export interface IFeedbackParams {
  name?: string;
  email?: string;
  phone?: string;
  message?: string;
  flatID?: number | string;
}

function post(params: IFeedbackParams & {
  form: 'feedback' | 'callback' | 'bookingOrder';
}) {
  if (localStorage.getItem('mayak_contact_anonymous_user_uuid') === null) {
    localStorage.setItem('mayak_contact_anonymous_user_uuid', id());
  }
  let { form, email, message, name, phone, flatID } = params;
  let body = {
    form_title: form,
    field_email: email,
    label_field_email: email && `Email`,
    field_message: message,
    label_field_message: message && `Message`,
    field_name: name,
    label_field_name: name && `Name`,
    field_phone: phone,
    label_field_phone: phone && `Phone`,
    field_flat: flatID,
    label_field_flat: flatID && `Flat ID`,
  };
  return api.post('/send_form/', body, {}, {
    baseURL: appConf.api.siteURL,
    headers: {
      'X-UUID': localStorage.getItem('mayak_contact_anonymous_user_uuid'),
    }
  });
}

export function postFeedback(params: IFeedbackParams) {
  return post({
    ...params,
    form: 'feedback',
  })
}

export function postCallback(params: IFeedbackParams) {
  return post({
    ...params,
    form: 'callback',
  })
}


export function postBookingOrder(params: IFeedbackParams) {
  return post({
    ...params,
    form: 'bookingOrder',
  })
}

