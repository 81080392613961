import { useCallback } from 'react';
import { IFlat } from "@features/flats/services/flatManager";
import { useGoToWithLocale } from "@libs/ML/lib/hooks/useGoToWithLocale";

export function useGoToFlat(isFromSearch: boolean = false) {
  const goTo = useGoToWithLocale();
  return useCallback((flat: IFlat) => {
    return goTo(`/apartments/floor-${flat.floorNumber}-flat-${flat.id}/`, isFromSearch ? {
      fromSearch: true,
    } : undefined);
  }, [goTo, isFromSearch]);
}
