/**
 * @description The FloorFlatPage component.
 */
import React from "react";
import { useFlatsData } from "@features/flats/hooks/useFlatsData";
import { useFloorFlatParams } from "@features/flats/hooks/useFloorFlatParams";
import { useWrongFlatRedirect } from "@features/flats/hooks/useWrongFlatRedirect";
import { useWrongFloorRedirect } from "@features/flats/hooks/useWrongFloorRedirect";
import { PageLayout } from "@shared/components/PageLayout";
import { T } from "@libs/ML/lib/components/T";
import { FLATS_SECTION } from "@/menuConfig";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgSearch } from "@shared/images/bg/exports";
import { FloorFlat } from "@features/flats/components/FloorFlat";
import { FlatManager } from "@features/flats/services/flatManager";

type Props = {};

export const FloorFlatPage: React.FC<Props> = function (props) {
  const canRenderFloor = useWrongFloorRedirect();
  const canRenderFlat = useWrongFlatRedirect({ checkOnlyIfNotNull: true });
  const { isLoading, error, data } = useFlatsData();
  let params = useFloorFlatParams();

  const plProps = {
    isLoading: !!(isLoading || !canRenderFloor || (params.flatID && !canRenderFlat)),
    mods: ['flats', 'no-pb', 'overflow-hidden'],
    error: error?.message,
    breadcrumbs: FLATS_SECTION,
    bg: <BackgroundSet conf={bgSearch} />
  };
  return (
    <PageLayout {...plProps}>
      <FloorFlat
        floorNumber={params.floorNumber as number}
        flatID={params.flatID}
        flatsManager={data as FlatManager}
      />
    </PageLayout>
  );
};
