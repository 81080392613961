import { useDispatch, useSelector } from "react-redux";
import { useCallback } from 'react';

import { selectIsMenuOpen, set } from "@shared/ducks/settings.duck";

export function useMenuState() {
  const dispatch = useDispatch();
  const currentState = useSelector(selectIsMenuOpen);

  const show = useCallback(() => {
    dispatch(set({
      isMenuOpen: true,
    }))
  }, [dispatch]);

  const hide = useCallback(() => {
    dispatch(set({
      isMenuOpen: false,
    }))
  }, [dispatch]);

  const toggle = useCallback(() => {
    dispatch(set({
      isMenuOpen: !currentState,
    }))
  }, [dispatch, currentState]);

  return {
    isOpen: currentState,
    show,
    hide,
    toggle,
  };
}
