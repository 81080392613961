/**
 * @description The TourScene component.
 */
import React, { useEffect, useRef, useState } from "react";
import {
  PerspectiveCamera,
  WebGLRenderer,
  GammaEncoding,
  Scene,
  Color,
  Fog,
  PCFSoftShadowMap,
  LinearToneMapping,
  Object3D,
  Mesh, Raycaster, Vector2, Material, MeshPhongMaterial, Intersection, BoxGeometry, MeshBasicMaterial
} from 'three';
import { CSS2DObject, CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import "./TourScene.scss";

type TourMarker = {
  threeUUID: string;
  id: number;
  type: 'image' | '360';
  coords: {
    x: number;
    y: number;
    z: number;
  };
  mesh: Mesh;
};

type ThreeInterface = {
  camera: PerspectiveCamera,
  renderer: WebGLRenderer,
  labelsRenderer: CSS2DRenderer,
  scene: Scene,
  controls: OrbitControls,
  canvas: HTMLCanvasElement,
  raycaster: Raycaster,
  mouseVector: Vector2,
  materials: {
    imageBase: Material,
    imageHover: Material,
    tourBase: Material,
    tourHover: Material,
  },
  markers: TourMarker[],
}

type Props = {
  isInit: boolean;
  onInit: () => any;
  model: Object3D;
  animate?: boolean;
  handleClick360: (id: number) => any;
  handleClickImage: (id: number) => any;
}

export const TourScene: React.FC<Props> = function (props) {
  const [isModeEnv, setIsEnv] = useState(true);
  const { onInit, model, isInit, animate = false, handleClick360, handleClickImage } = props;
  const rendererRef = useRef<HTMLDivElement>(null);
  const labelsRendererRef = useRef<HTMLDivElement>(null);
  const threeRef = useRef<ThreeInterface>();
  const animationFrame = useRef(0);
  const propsRef = useRef(props);
  const [hover, setHover] = useState<string|null>(null);
  const stateRef = useRef({
    isModeEnv,
    setIsEnv,
    hover,
    setHover,
  });

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  useEffect(() => {
    stateRef.current = {
      isModeEnv,
      setIsEnv,
      hover,
      setHover,
    };
  }, [isModeEnv, setIsEnv, hover, setHover]);

  useEffect(() => {
    // calc ratio
    const ratio = window.innerWidth / 1920;

    // camera conf
    const camera = new PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 2000);

    // renderer conf
    const renderer = new WebGLRenderer({
      antialias: true,
    });
    const labelsRenderer = new CSS2DRenderer();
    renderer.outputEncoding = GammaEncoding;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;
    renderer.physicallyCorrectLights = true;
    renderer.toneMapping = LinearToneMapping;
    renderer.toneMappingExposure = 1.5;

    // scene conf
    const scene = new Scene();
    scene.background = new Color('#464646');
    scene.fog = new Fog('#515151', 0.10, 70);
    scene.add(camera);
    scene.add(...model.children);

    // init renderer
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    labelsRenderer.setSize(window.innerWidth, window.innerHeight);
    (labelsRendererRef.current as HTMLElement).appendChild(labelsRenderer.domElement);
    const canvas = (rendererRef.current as HTMLElement).appendChild(renderer.domElement);

    // extract markers
    let markersSrc360 = scene.children.find(el => el.name === "Model_Metki_360" && el.type === "Group")?.children as Mesh[];
    let markers360: TourMarker[] = markersSrc360.map(mesh => {
      let uuid = mesh.uuid;
      let id = parseInt(`${mesh.name}`.replace('360_', ''));
      let coords = {
        x: mesh.geometry.boundingSphere?.center.x as number,
        y: mesh.geometry.boundingSphere?.center.y as number,
        z: mesh.geometry.boundingSphere?.center.z as number,
      };
      mesh.visible = false;
      let marker = {
        threeUUID: uuid,
        id,
        coords,
        type: '360',
        mesh,
      };
      mesh.userData.mayakMarker = marker;
      return marker as TourMarker;
    });
    let markersSrcImages = scene.children.find(el => el.name === "Model_Metki" && el.type === "Group")?.children as Mesh[];
    let markersImages: TourMarker[] = markersSrcImages.map(mesh => {
      let uuid = mesh.uuid;
      let id = parseInt(`${mesh.name}`.replace('Box', ''));
      let coords = {
        x: mesh.geometry.boundingSphere?.center.x as number,
        y: mesh.geometry.boundingSphere?.center.y as number,
        z: mesh.geometry.boundingSphere?.center.z as number,
      };
      mesh.visible = false;
      let marker = {
        threeUUID: uuid,
        id,
        coords,
        type: 'image',
        mesh,
      };
      mesh.userData.mayakMarker = marker;
      return marker as TourMarker;
    });

    // add markers html
    [...markers360, ...markersImages].forEach(m => {
      let divMarker = document.createElement('div');
      divMarker.setAttribute('data-marker-id', m.id + '');
      divMarker.setAttribute('data-marker-type', m.type);
      divMarker.className = `renderer-3d-scene-marker renderer-3d-scene-marker--${m.type}`;
      divMarker.addEventListener('click', m.type === 'image' ?
        () => handleClickImage(m.id) :
        () => handleClick360(m.id)
      );
      divMarker.innerHTML = `<div class="renderer-3d-scene-marker__cont"></div>`
      let marker = new CSS2DObject(divMarker);
      marker.position.set(m.coords.x, m.coords.y, m.coords.z);
      m.mesh.add(marker);
    });

    // extract materials
    let markerImageBaseMaterial = (markersImages[0].mesh.material as MeshPhongMaterial).clone();
    let markerImageHoverMaterial = (markersImages[0].mesh.material as MeshPhongMaterial).clone();
    markerImageHoverMaterial.color.setHex(0x1D754B);
    let markerTourBaseMaterial = (markers360[0].mesh.material as MeshPhongMaterial).clone();
    let markerTourHoverMaterial = (markers360[0].mesh.material as MeshPhongMaterial).clone();
    markerTourHoverMaterial.color.setHex(0x1D754B);
    markerTourHoverMaterial.emissive.setHex(0x1D754B);

    // mouse events
    const mouseVector = new Vector2(window.innerWidth / 2, window.innerHeight / 2);
    const raycaster = new Raycaster();
    const hoverObjects = [...markersImages, ...markers360].map(m => m.mesh);

    const handleHover = (intersects: Intersection[]) => {
      if (!intersects.length) {
        stateRef.current.setHover(null);
        return;
      }
      let marker = (intersects[0].object as any).userData.mayakMarker as TourMarker;
      if (marker) {
        stateRef.current.setHover(marker.threeUUID);
      } else {
        stateRef.current.setHover(null);
      }
    };

    const handleClick = () => {
      raycaster.setFromCamera(mouseVector, camera);
      const intersects = raycaster.intersectObjects(hoverObjects);
      if (!intersects.length) {
        return
      }
      let marker = (intersects[0].object as any).userData.mayakMarker as TourMarker;
      if (marker) {
        marker.type === 'image' ? handleClickImage(marker.id) : handleClick360(marker.id);
      }
    };
    window.addEventListener('click', handleClick, false);

    // controls conf
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.maxPolarAngle = Math.PI * 0.4;
    controls.minDistance = 12;
    controls.maxDistance = 40;
    controls.enablePan = false;

    // set camera pos
    // camera.position.set(14, 5.46 + 10, -14);
    // camera.position.set(2.1962518089634178, 13.180339887498949, -21.108107148230257);
    camera.position.set(16.317592974770676, 13.2243354876804, -15.04442943993347);
    camera.rotation.set(-180, 45, -180);
    camera.frustumCulled = true;
    controls.target.set(2.9 - 0.5, 7, -2.1);
    controls.update();

    // bind refs
    threeRef.current = {
      camera,
      controls,
      scene,
      renderer,
      canvas,
      materials: {
        imageBase: markerImageBaseMaterial,
        imageHover: markerImageHoverMaterial,
        tourBase: markerTourBaseMaterial,
        tourHover: markerTourHoverMaterial,
      },
      mouseVector,
      raycaster,
      markers: [
        ...markersImages,
        ...markers360,
      ],
      labelsRenderer,
    };

    // run animation cycle
    const animate = () => {
      animationFrame.current = requestAnimationFrame(animate);
      raycaster.setFromCamera(mouseVector, camera);
      const intersects = raycaster.intersectObjects(hoverObjects);
      handleHover(intersects);
      if (propsRef.current.animate) {
        if (propsRef.current.isInit) {
          controls.autoRotate = true;
          controls.autoRotateSpeed = 1;
        }
      } else {
        controls.autoRotate = false;
      }
      controls.autoRotate = false;
      controls.update();
      renderer.render(scene, camera);
      labelsRenderer.render(scene, camera);

      if (false) {
        (stateRef.current.isModeEnv ? markersImages : markers360).filter(m => {
          if (m.type === 'image') {
            return m.id !== 6 && m.id !== 4;
          }
          return true;
        }).forEach(m => {
          if (m.type === 'image') m.mesh.visible = stateRef.current.isModeEnv;
          if (m.type === '360') m.mesh.visible = !stateRef.current.isModeEnv;
        });
      } else {
        threeRef.current?.markers.filter(m => {
          if (m.type === 'image') {
            return m.id !== 6 && m.id !== 4;
          }
          return true;
        }).forEach(m => m.mesh.visible = true);
      }
    };
    animate();

    // add resize handler
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      labelsRenderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    // add mousemove handler
    const handleMouseMove = (e: MouseEvent) => {
      mouseVector.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouseVector.y = - (e.clientY / window.innerHeight) * 2 + 1;
    };
    window.addEventListener("mousemove", handleMouseMove, false);

    // set is init
    onInit();

    // reset
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('click', handleClick);
      cancelAnimationFrame(animationFrame.current);
    };
  }, []);

  useEffect(() => {
    if (!threeRef.current) return;
    const { materials, markers } = threeRef.current as ThreeInterface;

    markers.forEach(m => {
      if (m.threeUUID === hover) {
        m.mesh.material = m.type === 'image' ? materials.imageHover : materials.tourHover;
      } else {
        m.mesh.material = m.type === 'image' ? materials.imageBase : materials.tourBase;
      }
    });

    (document.body.style.cursor as any) = hover !== null ? 'pointer' : null;
    return () => {
      (document.body.style.cursor as any) = null;
    };
  }, [hover]);

  return (
    <div className="tour-scene">
      <div className="tour-scene__cont">

        <div ref={rendererRef} className="tour-scene__renderer" />
        <div ref={labelsRendererRef} className="tour-scene__renderer tour-scene__renderer--labels" />

      </div>
    </div>
  );
};
