import _iconAll1Desktop from "./all/1.svg";
import _iconAll2Desktop from "./all/2.svg";
import _iconAll3Desktop from "./all/3.svg";
import _iconAll4Desktop from "./all/4.svg";
import _iconAll5Desktop from "./all/5.svg";
import _iconAll6Desktop from "./all/6.svg";
import _iconAll7Desktop from "./all/7.svg";
import _iconAll8Desktop from "./all/8.svg";
import _iconAll9Desktop from "./all/9.svg";
import _iconAll10Desktop from "./all/10.svg";
import _iconAll11Desktop from "./all/11.svg";
import _iconAll12Desktop from "./all/12.svg";


export const iconAll1Desktop = _iconAll1Desktop;
export const iconAll2Desktop = _iconAll2Desktop;
export const iconAll3Desktop = _iconAll3Desktop;
export const iconAll4Desktop = _iconAll4Desktop;
export const iconAll5Desktop = _iconAll5Desktop;
export const iconAll6Desktop = _iconAll6Desktop;
export const iconAll7Desktop = _iconAll7Desktop;
export const iconAll8Desktop = _iconAll8Desktop;
export const iconAll9Desktop = _iconAll9Desktop;
export const iconAll10Desktop = _iconAll10Desktop;
export const iconAll11Desktop = _iconAll11Desktop;
export const iconAll12Desktop = _iconAll12Desktop;
export const iconAll = { desktop: [_iconAll1Desktop, _iconAll2Desktop, _iconAll3Desktop, _iconAll4Desktop, _iconAll5Desktop, _iconAll6Desktop, _iconAll7Desktop, _iconAll8Desktop, _iconAll9Desktop, _iconAll10Desktop, _iconAll11Desktop, _iconAll12Desktop], mobile: [], };


