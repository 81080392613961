import faker from 'faker';

import { randomIntBetween } from './random'

export function id() {
  return faker.datatype.uuid();
}

export function slug() {
  return faker.lorem.slug();
}

export function title(words = 3) {
  return faker.lorem.sentence(words);
}

export function photo(w = 1600, h = 900) {
  return faker.image.abstract(w, h) + '?v=' + id();
}

export function date() {
  return faker.date.past();
}

export function summary(sentences = 2) {
  return faker.lorem.sentences(sentences);
}

export function alt() {
  return faker.lorem.words();
}

export function fileSize() {
  return faker.datatype.float(2) + " Kb";
}

export function content() {
  return `
  <h2>H2 - ${title()}</h2>
  <p>${summary(randomIntBetween(1, 3))} <b>${summary(1)}</b> ${summary(randomIntBetween(1, 3))} <em>${summary(1)}</em> ${summary(randomIntBetween(1, 3))} <a href="#">${alt()}</a></p>
  <p>${summary(randomIntBetween(1, 3))} <b>${summary(1)}</b> ${summary(randomIntBetween(1, 3))} <em>${summary(1)}</em> ${summary(randomIntBetween(1, 3))} <a href="#">${alt()}</a></p>
  <p>${summary(randomIntBetween(1, 3))} <b>${summary(1)}</b> ${summary(randomIntBetween(1, 3))} <em>${summary(1)}</em> ${summary(randomIntBetween(1, 3))} <a href="#">${alt()}</a></p>
  <h3>H3 - ${title()}</h3>
  <p>${summary(randomIntBetween(6, 10))}</p>
    <img src="${photo()}" alt="${alt()}">
     <p>${summary(randomIntBetween(1, 3))} <b>${summary(1)}</b> ${summary(randomIntBetween(1, 3))} <em>${summary(1)}</em> ${summary(randomIntBetween(1, 3))} <a href="#">${alt()}</a></p>
  <p>${summary(randomIntBetween(1, 3))} <b>${summary(1)}</b> ${summary(randomIntBetween(1, 3))} <em>${summary(1)}</em> ${summary(randomIntBetween(1, 3))} <a href="#">${alt()}</a></p>
  <p>${summary(randomIntBetween(1, 3))} <b>${summary(1)}</b> ${summary(randomIntBetween(1, 3))} <em>${summary(1)}</em> ${summary(randomIntBetween(1, 3))} <a href="#">${alt()}</a></p>
    <img class="wide" src="${photo(1920, 580)}" alt="${alt()}">
 <p>${summary(randomIntBetween(1, 3))} <b>${summary(1)}</b> ${summary(randomIntBetween(1, 3))} <em>${summary(1)}</em> ${summary(randomIntBetween(1, 3))} <a href="#">${alt()}</a></p>
  <p>${summary(randomIntBetween(1, 3))} <b>${summary(1)}</b> ${summary(randomIntBetween(1, 3))} <em>${summary(1)}</em> ${summary(randomIntBetween(1, 3))} <a href="#">${alt()}</a></p>
  <p>${summary(randomIntBetween(1, 3))} <b>${summary(1)}</b> ${summary(randomIntBetween(1, 3))} <em>${summary(1)}</em> ${summary(randomIntBetween(1, 3))} <a href="#">${alt()}</a></p>

    <h4>H4 - ${title()}</h4>
  <p>${summary(randomIntBetween(6, 10))}</p>
    <h5>H5 - ${title()}</h5>
  <p>${summary(randomIntBetween(6, 10))}</p>
    <h6>H6 - ${title()}</h6>
  <p>${summary(randomIntBetween(6, 10))}</p>
  <blockquote>
  <p>${summary(randomIntBetween(3, 6))}</p>
  <p>${summary(randomIntBetween(2, 7))}</p>
</blockquote>
   <h6>H6 - ${title()}</h6>
   <ul>
     <li>${summary(randomIntBetween(1, 2))}</li>
     <li>${summary(randomIntBetween(1, 2))}</li>
     <li>${summary(randomIntBetween(1, 2))}</li>
     <li>${summary(randomIntBetween(1, 2))}</li>
  </ul>
   <p>${summary(randomIntBetween(6, 10))}</p>
     <h6>H6 - ${title()}</h6>
   <ol>
     <li>${summary(randomIntBetween(1, 2))}</li>
     <li>${summary(randomIntBetween(1, 2))}</li>
     <li>${summary(randomIntBetween(1, 2))}</li>
     <li>${summary(randomIntBetween(1, 2))}</li>
  </ol>
  `;
}
