import { useMemo } from "react";
import { useStringStorage } from "./useStringStorage";

export function useString(path: string, settings: {[p: string]: any} = {}): string {
  const storage = useStringStorage();
  return useMemo(() => {
    return storage.get(path, {
      ...settings,
    })
  }, [path, settings, storage]);
}
