/**
 * @description The HowPage component.
 */
import React from "react";
import { useQueryML } from "@shared/hooks/useQueryML";
import { getData, IHTBResponse} from "@features/singles/api/HowToBuyAPI";
import { T } from "@libs/ML/lib/components/T";
import { FLATS_SECTION, HOW } from "@/menuConfig";
import { BackgroundSet } from "@shared/components/BackgroundSet";
import { bgHow } from "@shared/images/bg/exports";
import { PageLayout } from "@shared/components/PageLayout";
import { How } from "@features/posts/components/How";

type Props = {};

export const HowPage: React.FC<Props> = function (props) {
  const { isLoading, error, data } = useQueryML<IHTBResponse>("how", getData);

  const plProps = {
    title: <T p={HOW.labelPath} />,
    isLoading,
    error: error?.message,
    breadcrumbs: FLATS_SECTION,
    bg: <BackgroundSet conf={bgHow} />
  };

  return (
    <PageLayout {...plProps}>
      <How data={data as IHTBResponse} />
    </PageLayout>
  );
};
