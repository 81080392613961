/**
 * @description The FloorPlanFlat component.
 */
import React from "react";
import { SharedProps } from "./shared";
import { IFlatConf, ILabelBox } from "@features/flats/plans/conf";
import { IFlat } from "@features/flats/services/flatManager";
import { makeMods } from "@libs/YandexBEM/lib/mm";
import { useString } from "@libs/ML/lib/hooks/useString";
import { ss } from '@/features/flats/stringSource';

type Props = SharedProps & {
  conf: IFlatConf;
  flat: IFlat;
  selected: boolean;
  hovered: boolean;
};

export const FloorPlanFlat: React.FC<Props> = function (props) {
  let {
    conf: {
      type,
      path,
      polygon,
      fill,
      label1Center,
      label2Center,
      clickable = true,
    },
    flat,
    selected,
    hovered,
    onHover = () => {},
    onClick = () => {},
    interactive = true,
  } = props;

  const typeString = useString(ss.flats.floorPlan.type, { params: { type } });
  const areaString = useString(ss.flats.floorPlan.area, { params: { area: flat.area } });

  const renderPath = (props: any = {}) => {
    if (path) return (
      <path d={path} fill={fill} {...props} />
    );
    if (polygon) return (
      <polygon points={polygon} fill={fill} {...props} />
    );
    return null;
  };

  const renderLabel = (conf: ILabelBox, text: string) => {
    return (
      <text dominantBaseline="middle" textAnchor="middle" fontSize={23} x={conf.cx as number} y={conf.cy as number}>
        <tspan alignmentBaseline="central">{text}</tspan>
      </text>
    );
  };

  return (
    <g className={makeMods('floor-plan-flat', {
      static: !interactive,
      disabled: !flat.isAvailable,
      selected,
      hovered,
    })}>
      {renderPath({ className: 'floor-plan-flat__bg', fill: `rgba(29, 117, 75, 0.3)` })}
      <g className="floor-plan-flat__text">
        {renderLabel(label1Center, typeString)}
        {renderLabel({...label2Center, cy: (label2Center.cy as number) + 1 || 12}, areaString)}
      </g>
      {renderPath({
        className: 'floor-plan-flat__fg',
        opacity: 0,
        style: clickable ? {} : {
          cursor: 'default',
        },
        onMouseOver: () => onHover(flat.id),
        onMouseOut: () => onHover(null),
        onClick: () => clickable ? onClick(flat.id) : () => {},
      })}
    </g>
  );
};
