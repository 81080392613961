import { ss } from '@features/singles/stringSource';
import { iconAll } from "@shared/images/chars/icons/exports";
import { imgS1, imgS2, imgS3, imgS4, imgS5, imgS6, imgS7, imgS8, imgS9, imgS10, imgS11, imgS12 } from "@shared/images/chars/images/exports";

export interface CharsSection {
  id: number;
  title: string;
  textRows: string[];
  icon: string;
  media: {
    d: string[];
    m: string[];
  };
  type: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10;
}

export const charsSections: CharsSection[] = [
  {
    id: 1,
    title: ss.singles.chars.section1.title,
    textRows: [
      ss.singles.chars.section1.row1,
      ss.singles.chars.section1.row2,
    ],
    icon: iconAll.desktop[0],
    media: {
      d: imgS1.desktop,
      m: imgS1.mobile,
    },
    type: 1,
  },
  {
    id: 2,
    title: ss.singles.chars.section2.title,
    textRows: [
      ss.singles.chars.section2.row1,
    ],
    icon: iconAll.desktop[1],
    media: {
      d: imgS2.desktop,
      m: imgS2.mobile,
    },
    type: 2,
  },
  {
    id: 3,
    title: ss.singles.chars.section3.title,
    textRows: [
      ss.singles.chars.section3.row1,
    ],
    icon: iconAll.desktop[2],
    media: {
      d: imgS3.desktop,
      m: imgS3.mobile,
    },
    type: 3,
  },
  {
    id: 4,
    title: ss.singles.chars.section4.title,
    textRows: [
      ss.singles.chars.section4.row1,
    ],
    icon: iconAll.desktop[3],
    media: {
      d: imgS4.desktop,
      m: imgS4.mobile,
    },
    type: 4,
  },
  {
    id: 5,
    title: ss.singles.chars.section5.title,
    textRows: [
      ss.singles.chars.section5.row1,
    ],
    icon: iconAll.desktop[4],
    media: {
      d: imgS5.desktop,
      m: imgS5.mobile,
    },
    type: 5,
  },
  {
    id: 6,
    title: ss.singles.chars.section6.title,
    textRows: [
      ss.singles.chars.section6.row1,
    ],
    icon: iconAll.desktop[5],
    media: {
      d: imgS6.desktop,
      m: imgS6.mobile,
    },
    type: 6,
  },
  {
    id: 7,
    title: ss.singles.chars.section7.title,
    textRows: [
      ss.singles.chars.section7.row1,
    ],
    icon: iconAll.desktop[6],
    media: {
      d: imgS7.desktop,
      m: imgS7.mobile,
    },
    type: 7,
  },
  {
    id: 8,
    title: ss.singles.chars.section8.title,
    textRows: [
      ss.singles.chars.section8.row1,
    ],
    icon: iconAll.desktop[7],
    media: {
      d: imgS8.desktop,
      m: imgS8.mobile,
    },
    type: 8,
  },
  {
    id: 9,
    title: ss.singles.chars.section9.title,
    textRows: [
      ss.singles.chars.section9.row1,
    ],
    icon: iconAll.desktop[8],
    media: {
      d: imgS9.desktop,
      m: imgS9.mobile,
    },
    type: 2,
  },
  {
    id: 10,
    title: ss.singles.chars.section10.title,
    textRows: [
      ss.singles.chars.section10.row1,
    ],
    icon: iconAll.desktop[9],
    media: {
      d: imgS10.desktop,
      m: imgS10.mobile,
    },
    type: 10,
  },
  {
    id: 11,
    title: ss.singles.chars.section11.title,
    textRows: [
      ss.singles.chars.section11.row1,
    ],
    icon: iconAll.desktop[10],
    media: {
      d: imgS11.desktop,
      m: imgS11.mobile,
    },
    type: 7,
  },
  {
    id: 12,
    title: ss.singles.chars.section12.title,
    textRows: [
      ss.singles.chars.section12.row1,
    ],
    icon: iconAll.desktop[11],
    media: {
      d: imgS12.desktop,
      m: imgS12.mobile,
    },
    type: 6,
  },
];

export const mediaSizeConf = {
  1: [
    { w: 802, h: 552 },
    { w: 1059, h: 690 },
  ],
  2: [
    { w: 681, h: 588 },
    { w: 822, h: 588 },
    { w: 305, h: 588 },
  ],
  3: [
    { w: 1238, h: 888 },
    { w: 626, h: 1262 },
  ],
  4: [
    { w: 310, h: 754 },
    { w: 822, h: 358 },
    { w: 657, h: 662 },
  ],
  5: [
    { w: 1010, h: 686 },
    { w: 853, h: 589 },
  ],
  6: [
    { w: 1854, h: 683 },
  ],
  7: [
    { w: 306, h: 687 },
    { w: 822, h: 415 },
    { w: 677, h: 549 },
  ],
  8: [
    { w: 1008, h: 495 },
    { w: 855, h: 708 },
  ],
  10: [
    { w: 1218, h: 571 },
    { w: 642, h: 810 },
  ],
};

export const parallaxConf = {
  1: {
    gap: 0,
  },
  2: {
    gap: 0,
  },
  3: {
    gap: 0,
  },
  4: {
    gap: 0,
  },
  5: {
    gap: 0,
  },
  6: {
    gap: 0,
  },
  7: {
    gap: 0,
  },
  8: {
    gap: 0,
  },
  10: {
    gap: 0,
  },
};
