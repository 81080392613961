/**
 * Describe django translates global functions
 * @see https://docs.djangoproject.com/en/3.1/topics/i18n/translation/
 */
interface Window {
  gettext(src: string): string;

  pgettext(context: string, src: string): string;
}

/**
 * Django gettext
 * @param src - string for translation
 */
export function gettext(src: string): string {
  return !((window as unknown) as Window).gettext ? src : ((window as unknown) as Window).gettext(src);
}

/**
 * Django pgettext
 * @param context - string context
 * @param src - string for translation
 */
export function pgettext(context: string, src: string): string {
  return !((window as unknown) as Window).pgettext ? src : ((window as unknown) as Window).pgettext(context, src);
}
